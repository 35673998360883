/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';
import moment from 'moment';

/*Services*/
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';
import devconfig from '../../config/config.js';

import UploadFileAWS from './awsHelper';

class ImageUploader extends Component {
  constructor(props){
    super(props);
    this.state = {
      file: "",
    }
  }

  async prepareUpload(file){
    var tempFileName = file.type
    var fileType = tempFileName.split("/").pop();
    var videoURL = await this.getVideoURL(file);
    await this.uploadFile(file, fileType, tempFileName, videoURL)
  }

  async uploadFile(file, fileType, tempFileName, path){
    this.setState({video_uploading: true});
    UploadFileAWS(tempFileName, path, file, fileType).then(function(url){
      this.setState({video_url: url, video_uploading: false, saved: false, uploadToggle: false});
      // callback to parent 
      // update video url
      // this.props.addVideo({
      //   id: false, 
      //   video_url: url, 
      //   duration_sec: this.state.duration, 
      //   duration_string: this.state.duration_string, 
      //   free: this.state.selectedVideoValue.value==2,
      //   active: true,
      //   name: this.state.name
      // });
    }.bind(this))
  }


  async uploadDocument(e) {
    this.props.uploadStatus(true);
    var file = e.target.files[0];
    var tempFileName = file.type
    var fileType = tempFileName.split("/").pop();
    var videoURL = await this.getVideoURL(file);
    // if(e.target.files.length){
      // var tempFileName = e.target.files[0].name;
      // var fileReader = new FileReader();

      // var tempKey = this.props.tempKey
      await this.uploadFile(file, fileType, tempFileName, videoURL)
      // if (e.target.files != null) {
      //   if(this.props.imageSelected){
      //     this.props.imageSelected();
      //   }
      //   fileReader.readAsDataURL(e.target.files[0]);
      //   fileReader.onloadend = async function (event) {
      //     var file = event.target.result;
      //     var url = devconfig.adminV1 + "/utils/upload-image";
      //     var data = JSON.stringify({
      //       'file': file,
      //       'fileName': tempFileName,
      //       'key': tempKey
      //     })
      //     APIPost(url, data).then(async function(response){
      //       if(response.status){

      //         await this.setState({ file: response.cdn_url});
      //         this.props.uploadSucess(response.cdn_url, JSON.parse(data));
      //       }
      //       else{
      //         await this.setState({ apiLoading: false});
      //       }
      //       await this.props.uploadStatus(false)
      //     }.bind(this))
      //   }.bind(this)
      // }
    // }
  }
  async uploadFile(file, fileType, tempFileName, path){
    this.setState({video_uploading: true});
    UploadFileAWS(tempFileName, path, file, fileType).then(function(url){
      this.props.uploadSucess(url);
     this.props.uploadStatus(false);
      // this.setState({video_url: url, video_uploading: false, saved: false, uploadToggle: false});
      // callback to parent 
      // update video url
      // this.props.addVideo({
      //   id: false, 
      //   video_url: url, 
      //   duration_sec: this.state.duration, 
      //   duration_string: this.state.duration_string, 
      //   free: this.state.selectedVideoValue.value==2,
      //   active: true,
      //   name: this.state.name
      // });
    }.bind(this))
  }

  async getVideoURL(tempFile){
    var tempFileNameSplit = tempFile.name.split(".")
    var tempCurrentDate = moment().format();
    tempCurrentDate = tempCurrentDate.slice(0,-1);
    var tempFilName = tempFileNameSplit[0];
    tempFilName = tempFilName.replace(" ", "_");
    tempFilName = "tradestore/"+ tempFilName
    return tempFilName;
  }

  render() {
    return (
      <input 
        id={this.props.id} 
        className={this.props.className} 
        type="file" 
        accept={this.props.accept} 
        onChange={this.uploadDocument.bind(this)}
        {...this.props}
      />
    );
  }
}

export default ImageUploader;
