/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';
// import services
import APIPost from '../../components/apiCall/apiPost';
import APIGet from '../../components/apiCall/apiGet';
import devconfig from '../../config/config.js';
import GetURLVar from '../../services/apiCall/getParams/get-url.js';
/*Assets and Components*/
import InputBox from '../../components/common/inputBox';
// import NewRequestPending from './newRequest';
// import DeletePending from './deleteRequest';
import ApiModal from '../../components/modal/apiModal';
import EditBank from '../../components/popups/editBank';
import EditProfile from '../../components/popups/editVendorProfile';
import Toast from '../../components/popups/Toast';
import UploadModal from '../../components/modal/uploadApiModal';
import EnableWebhook from '../../components/modal/enableWebhook';
import UpdateCommission from '../../components/popups/updateCommission';
import UpdateTds from '../../components/popups/updateTds';
import bank from '../../assets/images/bank.svg';
import copy from '../../assets/images/icons/icon.svg';
import linkarrow from '../../assets/images/linkarrow.svg';

import Links from '../../components/navigations/Links';

// Assets
import EditIcon from '../../assets/images/icons/edit.svg';

class vendorProfile extends Component {
  constructor(props){
    super(props);
    this.state = {
      activeMenu: 1,
      detailID: this.props.id,
    }
  }

  componentWillMount() {
    GetURLVar().then(function(query){
      // this.setState({vendorId: query.id});

    }.bind(this));
    this.getProfile();
    this.getPaymentHist();
  }

 getProfile(e){
  this.setState({dataLoading: true})
  var url = devconfig.adminV3+"/vendor/profile";
    var data = JSON.stringify({
      "vendor_id" : parseInt(this.state.detailID),
    });
    APIPost(url, data).then(async function(response){
      if(response.status === "ok"){
        await this.setState({profile: response.data, dataLoading: false})
        await this.setState({webhook_url: response.data.webhook_url});

      }
      else{
        this.setState({ dataLoading: false});
      }
    }.bind(this))

 }
 enableAPI=()=>{
  var url = devconfig.adminV3+"/vendor/subscription-access";
    var data = JSON.stringify({
      "vendor_id" : parseInt(this.state.detailID),
    });
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        this.setState({token: response.data.API_ACCESS_TOKEN});
        this.triggerToast('API Enabled successfully');
        $('#apidetails').modal('toggle');
      }
      else{
        this.setState({ dataLoading: false});
      }
    }.bind(this))
 }
  enableUpload=()=>{
  var url = devconfig.adminV2+"/vendor/enable-upload";
    var data = JSON.stringify({
      "vendor_id" : parseInt(this.state.detailID),
    });
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        this.setState({uploadDtls: response.data});
         this.triggerToast('Upload API Enabled successfully');
         $('#uploadapi').modal('toggle');

      }
      else{
      }
    }.bind(this))
 }
 getPaymentHist(){
  this.setState({dataLoading: true})
  var url = devconfig.adminV3+"/vendor/payout-history";
    var data = JSON.stringify({
      "vendor_id" : parseInt(this.state.detailID),
    });
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        this.setState({history: response.data, dataLoading: false})

      }
      else{
        this.setState({ dataLoading: false});
      }
    }.bind(this))
 }

 enableWebhook=(temp)=>{
  var url = devconfig.adminV3+"/vendor/update-webhook-url";
    var data = JSON.stringify({
      "vendor_id" : parseInt(this.state.detailID),
      "webhook_url" : temp
    });
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        this.setState({toast: "Successfully Enabled Webhook" , toastType:"success"})
          setTimeout( function() { this.setState({ toast: null}); }.bind(this), 4000);
          this.getProfile();
      }
      else{
        this.setState({toast: "Failed to Enabled Webhook" , toastType:"fail"})
          setTimeout( function() { this.setState({ toast: null}); }.bind(this), 4000);

      }
    }.bind(this))
 }

 edit(){
    var overlaylogin = '<div class="e-login-overlay" id="e-log-overlay"></div>';
    $("body").append(overlaylogin);
    $('.e-edit-bank').addClass('e-login-fly');

  }
  editProfile(){
    var overlaylogin = '<div class="e-login-overlay" id="e-log-overlay"></div>';
    $("body").append(overlaylogin);
    $('.e-edit-profile').addClass('e-login-fly');
  }
  triggerToast=(e)=>{
    this.setState({toast: e , toastType:"success"})
    setTimeout( function() { this.setState({ toast: null}); }.bind(this), 4000);
    this.getProfile();
  }
  reloadProfile=(e)=>{
    if(e == 200){
    this.setState({toast: "Commission Updated" , toastType:"success"})
    setTimeout( function() { this.setState({ toast: null}); }.bind(this), 4000);
  }else{
    this.setState({toast: "Failed To Update Commission" , toastType:"fail"})
    setTimeout( function() { this.setState({ toast: null}); }.bind(this), 4000);
      }
      this.getProfile();

  }

  render() {
    return (
      <React.Fragment>
      {
      this.state.profile ?
     <React.Fragment>
     <div className="col-lg-10">
     <div className="row">
     <div className="col-lg-6">
        <Links className="e-marketDetails-link"  activeClassName="" name="Partnered Vendors" url="/vendors"/>
        <img src={linkarrow} alt="arrow" className="mx-2"/>
        <Links className="e-marketDetails-link-color"  activeClassName="" name={this.state.profile.name} url="smallcase"/>
     </div>
      <div className="col-lg-6 text-right">
      {
        this.state.profile && this.state.profile.subscription_access ?
          <p>Subscriptions list api is enabled for {this.state.profile.name}, <button className="e-enable-btn ml-0 pl-0" data-toggle="modal" data-target="#apidetails">Click to view access token.</button></p>
        :
          <button className="e-enable-btn" onClick={this.enableAPI}>Enable Subscription API Now</button>
      }
      </div>
     </div>
      <div className="row">
       <div className="col-lg-6">
          <div className="card border-0 e-profile-card pt-4">
              <div className="row no-gutters">
                <div className="col-lg-2 mt-3 text-center">
                  <span className=" e-header-shortname">{this.state.profile.short_name}</span>
                </div>
                <div className="col-lg-8">
                  <h6>{this.state.profile.name}</h6>
                  <p className="mt-3 e-profile-phone">Email: {this.state.profile.email}</p>
                  <p className="">phone: {this.state.profile.mobile}</p>
                </div>
                <div className="col-lg-2 mt-5 pt-2 text-right">
                  {/*<button className="e-edit-btn" onClick={this.editProfile}> Edit</button>*/}
                </div>
              </div>
        </div>
        </div>
        <div className="col-lg-6">
              <div className="card border-0 e-profile-card pt-4">
                <div className="row">
                  <div className="col-lg-2 mt-2 text-center">
                    <img src={bank}/>
                  </div>
                  {
                    this.state.profile.bank_info.account_added ?
                    <React.Fragment>
                      <div className="col-lg-8">
                        <h6>{this.state.profile.bank_info.bank}</h6>
                        <p className="mt-3 e-profile-phone">Account no: {this.state.profile.bank_info.account_no}</p>
                          <p className="">IFSC: {this.state.profile.bank_info.ifsc_code}</p>
                          
                      </div>
                      <div className="col-lg-2 text-right mt-5 pt-2">
                      <button className="e-edit-btn" onClick={this.edit}> Edit</button>
                      </div>
                      </React.Fragment>
                    :
                      <div className="col-l6-10">
                        <h6>You have not added bank details yet. </h6>
                        <button className="e-add-product-btn e-p-btn mt-3 e-add-bank-details-btn" onClick={this.edit}>Click to add bank details</button>
                      </div>
                  }

                </div>
              </div>
            </div>
        </div>
        <div className="row mt-4">
         <div className="col-lg-3 mb-4">
            <div className="card border-0 e-profile-card p-4">
              <h5 className="e-paid-card">Total Purchase Amount</h5>
              <div className="mt-3 text-right e-card-amount">₹ {this.state.profile.total_amount_purchased}</div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="card border-0 e-profile-card px-4 py-4">
              <div className="row">
                <div className="col-lg-9">
                  <h5 className="e-paid-card">Aliceblue Commision</h5>
                </div>
                <div className="col-lg-2">
                  <button className="e-enable-btn" data-toggle="modal" data-target="#updateCommission"><img src={EditIcon} className="e-edit-icon"/></button>
                </div>
              </div>
              <div className="mt-3 text-right e-card-amount">% { this.state.profile.ab_commission}</div>
            </div>
          </div>
           <div className="col-lg-3">
            <div className="card border-0 e-profile-card p-4">
              <h5 className="e-paid-card">Commision amount</h5>
              <div className="mt-5 text-right e-card-amount">₹ {this.state.profile.total_ab_commission}</div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="card border-0 e-profile-card p-4">
              <h5 className="e-paid-card">Total Pending</h5>
              <div className="mt-5 text-right e-card-amount">₹ {this.state.profile.vendor_amount}</div>
            </div>
          </div>
          
        </div>
        <div className="row">
          <div className="col-lg-3">
            <div className="card border-0 e-profile-card p-4">
              <h5 className="e-paid-card">Total Received</h5>
              <div className="mt-5 text-right e-card-amount">₹ {this.state.profile.total_paid}</div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="card border-0 e-profile-card p-4">
              <h5 className="e-paid-card">Pending amount</h5>
              <div className="mt-5 text-right e-card-amount">₹ {this.state.profile.payout_balance}</div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="card border-0 e-profile-card px-4 py-4">
              <div className="row">
                <div className="col-lg-9">
                  <h5 className="e-paid-card">TDS Percentage</h5>
                </div>
                <div className="col-lg-2">
                  <button className="e-enable-btn" data-toggle="modal" data-target="#updateTds"><img src={EditIcon} className="e-edit-icon"/></button>
                </div>
              </div>
              <div className="mt-3 text-right e-card-amount">% { this.state.profile.tds_percentage}</div>
            </div>
          </div>
          <div className="col-lg-6 mt-4">
          {
            this.state.profile.upload_enabled ?

            <p>Subscriptions upload api is enabled for {this.state.profile.name}, <button className="e-enable-btn ml-0 pl-0" data-toggle="modal" data-target="#uploadapi">Click to view the credentials.</button>
            <button className="e-enable-btn" onClick={this.enableUpload}>Resend Api Request</button>
            </p>
            
            :
            <button className="e-enable-btn" onClick={this.enableUpload}>Enable Upload API Now</button>
          }
          {
            this.state.profile.webhook_url ?
            <button className="e-enable-btn ml-0 pl-0" data-toggle="modal" data-target="#enbleWebhook">Update Webhook URL.</button>
            :
            <button className="e-enable-btn ml-0 pl-0" data-toggle="modal" data-target="#enbleWebhook">Enable webhook URL.</button>
          }
          
          </div>
        </div>
        <div className="row mt-4">
           
          <div className="col-lg-3 mt-2">
            
          </div>
        </div>
         <div className="pt-5 pb-5">
        <table className="table table-borderless table-striped e-vendor-table">
          <thead>
            <tr>
              <th>Transaction Id</th>
              <th>Paid Amount</th>
              <th>Paid On</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
          {
            this.state.history?
            this.state.history.map((item, index) =>{
              return(
                <tr key={index} className="py-5">
              <td>{item.transaction_id == null ? "--": item.transaction_id }</td>
              <td>{item.amount}</td>
              <td>{item.date}</td>

              <td className={item.payment_status ? "e-green" : "e-red"}>{item.payment_status ? "Success" : "failed"}</td>
            </tr>
              )
            })
            :
            null
          }
          </tbody>
        </table>
        </div>
        </div>





      <EditBank data={this.state.profile.bank_info} id={this.state.detailID} confirmSuccess={(temp)=>{this.triggerToast(temp)}}/>
      <EnableWebhook data={this.state.profile.webhook_url} save={(temp)=>{this.enableWebhook(temp)}}/>
      <EditProfile data={this.state.profile} id={this.state.detailID} confirmSuccess={(temp)=>{this.triggerToast(temp)}}/>
        {
          this.state.toast ?
            <Toast data={this.state.toast} type={this.state.toastType}/>
         :
            null
        }
       
      

      <ApiModal>
        <div className="mb-4">
          Access Token: <p className="e-accesstoken">{this.state.profile.latest_token}</p>
          <a className="mr-3 e-ftricon-link" onClick={() => {
            this.setState({ linkcopy: true});
            navigator.clipboard.writeText(this.state.profile.latest_token);
            setTimeout(
              function() {
                this.setState({ linkcopy: false });
              }
              .bind(this),
              1500
            );
          }
        }><img src={copy} alt="icon" className="e-footer-icon"/></a>

        {
          this.state.linkcopy ?
            <span className="e-link-copied">Token copied</span>
          :
            null
        }
        </div>
        <div>
        Api URL: <a className="d-block" href="https://i6a3gt6ndd.execute-api.ap-south-1.amazonaws.com/live3/api/v3/data/subscriptions/vendor-subscriptions" target="_blank">https://i6a3gt6ndd.execute-api.ap-south-1.amazonaws.com/live3/api/v3/data/subscriptions/vendor-subscriptions</a>
        </div>
        <div className="mt-3">
          <p>Api Type: <b>POST</b></p>
        </div>
        <div className="mt-3">
          Api Data:
          <code className>
            {" data: {start_date : null, end_date : null, product_id : null, sort_by : null}"}
          </code>
        </div>


      </ApiModal>
      <UploadModal>
        <div className="mb-4">
          <React.Fragment>
          <p>API ACCESS KEY : <p className="e-accesstoken">{this.state.profile.api_access_key}</p>
            <a className="mr-3 e-ftricon-link" onClick={() => {
            this.setState({ linkcopy: true});
            navigator.clipboard.writeText(this.state.profile.api_access_key);
            setTimeout(
              function() {
                this.setState({ linkcopy: false });
              }
              .bind(this),
              1500
            );
          }
        }><img src={copy} alt="icon" className="e-footer-icon"/></a>
          </p>
          <p>API SECRET ACCESS KEY : <p className="e-accesstoken">{this.state.profile.api_secret_access_key}</p>
              <a className="mr-3 e-ftricon-link" onClick={() => {
            this.setState({ linkcopy: true});
            navigator.clipboard.writeText(this.state.profile.api_secret_access_key);
            setTimeout(
              function() {
                this.setState({ linkcopy: false });
              }
              .bind(this),
              1500
            );
          }
        }><img src={copy} alt="icon" className="e-footer-icon"/></a>
           </p>
           {
          this.state.linkcopy ?
            <span className="e-link-copied">Token copied</span>
          :
            null
        }
        <div>
        <p>Api URL: <a className="d-block" href="https://i6a3gt6ndd.execute-api.ap-south-1.amazonaws.com/live3/api/v2/vendor/subscription/upload" target="_blank">
        https://i6a3gt6ndd.execute-api.ap-south-1.amazonaws.com/live3/api/v2/vendor/subscription/upload</a>
        <a className="mr-3 e-ftricon-link" onClick={() => {
            this.setState({ linkcopy: true});
            navigator.clipboard.writeText("https://i6a3gt6ndd.execute-api.ap-south-1.amazonaws.com/live3/api/v2/vendor/subscription/upload");
            setTimeout(
              function() {
                this.setState({ linkcopy: false });
              }
              .bind(this),
              1500
            );
          }
        }><img src={copy} alt="icon" className="e-footer-icon"/></a>
        </p>
        </div>
        <div className="mt-3">
          <p>Api Type: <b>POST</b></p>
        </div>
        <div className="mt-3">
          Api Data:
          <code className>
            {" data: {start_date : null, end_date : null, product_id : null, sort_by : null}"}
          </code>
        </div>
           </React.Fragment>

        </div>
      </UploadModal>

     </React.Fragment>
       :
      null
    }
    {
      this.state.profile ?
      <>
      <UpdateCommission  id={this.state.detailID} data={this.state.profile.ab_commission} confirmSuccess={(temp)=>{this.reloadProfile(temp)}}/>
      <UpdateTds id={this.state.detailID} data={this.state.profile.tds_percentage} confirmSuccess={(temp)=>{this.reloadProfile(temp)}}/>
      </>
      :
      null
    }
    

    </React.Fragment>
    );
  }
}

export default vendorProfile;
