/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Components*/
import InputBox from '../../components/common/inputBox';
import EmailValidation from '../../components/validation/email';
import PhoneValidation from '../../components/validation/phone';
import APIPost from '../../components/apiCall/apiPost';
import APIGet from '../../components/apiCall/apiGet';
import devconfig from '../../config/config.js';
import PayNowModal from '../../components/popups/payNowModal';
import Toast from '../../components/popups/Toast';
import NoData from '../../components/common/noData';
import GetVrs from '../../services/apiCall/getParams/get-url';
import LazyLoaderGif from '../../components/common/lazyLoaderGif';
// assets
import Download from '../../assets/images/download-icon.svg';

class Ledger extends Component {
  constructor(props){
    super(props);
    this.state = {
      vName: "",
      vEmail: "",
      vPhone: "",
      errorMessage: "",
      btnClicked: false,
      resetInput: true,
      dataList: []
    }
  }

  componentDidMount() {
    GetVrs().then((query)=>{
      this.setState({ id: query.id});
      this.listLedger(query.id);
    })    
  }

  listLedger=(id)=>{
    this.setState({ ledgerLoad: true})
    var url = devconfig.adminV3+"/vendor/payout/ledger-list";
    var data = JSON.stringify({
      "payout_id" : parseInt(id)
    })
    APIPost(url, data).then((response)=>{
      if(response.status){
        this.setState({ledger: response.data, ledgerLoad: false});
      }
      else{
        this.setState({ledger: null, ledgerLoad: false});
      }
    })
  }

  downloadLedger=()=>{
    var url=devconfig.adminV3+"/vendor/payout/ledger-download";
    var data = JSON.stringify({
      'payout_id' : parseInt(this.state.id)
    })
    APIPost(url,data).then(function(response) {
      if(response.status){
        window.open(response.cdn_url)
      }
    }.bind(this))
  }
  render() {
    return (
      <div className="container">
        {
          this.state.ledgerLoad ?
            <div className="col-lg-12 text-center mt-5 pt-5">
              <LazyLoaderGif/>
            </div>
            : !this.state.ledgerLoad && !this.state.ledger ?
              <NoData/>
            :
            <>
              <div className="row mt-5">
                <div className="col-lg-6">
                <h2 className="">Historical Ledger</h2>
                </div>

                <div className="col-lg-6 text-right">
                  <a className="e-download-link " onClick={this.downloadLedger}  target="_blank">Download CSV</a>
                </div>
              </div>
              
              <div className="row">
                <div className="col-lg-12">
                  <table id="example" className="table e-subscription-table-wrap table-striped e-ledger-table">
                    <thead>
                      <tr>
                        <th>Description</th>
                        <th>Debit</th>
                        <th>Credit</th>
                        <th>Closing Amount</th>
                        <th>Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        this.state.ledger.map(function(item, index){
                          return(
                            <tr key={index}>
                              <td>{item.description ? item.description : '--'}</td>
                              <td>{item.debit ? '₹ '+ item.debit : '--'}</td>
                              <td> {item.credit ? '₹ '+ item.credit :'--'}</td>
                              <td>{item.closing ? '₹ ' + item.closing : '--' }</td>
                              <td> {item.date ? item.date : '--' }</td>
                              
                            </tr>

                          )
                        }.bind(this))

                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          }
      </div>
    );
  }
}

export default Ledger;
