import React from 'react';
import ReactPlayer from 'react-player/lazy';
import $ from 'jquery';

// import services
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';
import devconfig from '../../config/config.js';
import GetURLVar from '../../services/apiCall/getParams/get-url.js'; 

// import Components
import Links from '../../components/navigations/Links';
import EditVideo from '../../components/popups/EditVideos';
import DeleteModal from '../../components/popups/confirmationModal';

// import assets
import linkarrow from '../../assets/images/selectArrow/linkarrow.svg';
import Edit from '../../assets/images/resource/edit.svg';
import Delete from '../../assets/images/resource/delete.svg';
// import facebook from '../../assets/images/footerIcons/facebook.svg';
// import twitter from '../../assets/images/footerIcons/twitter.svg';
// import linkedin from '../../assets/images/footerIcons/linkdin.svg';
// import whatsapp from '../../assets/images/footerIcons/whatapp.svg';
// import copy from '../../assets/images/footerIcons/icon.svg';
import playbtn from '../../assets/images/slider/carbon_play-outline.svg';
import closebtn from '../../assets/images/header/close.png';

export default class ResourceVideoDetails extends React.Component{
	constructor(props){
		super(props);
		this.state={
			play: true,
			isModalOpen: false,
			id: null,
			data: null,
			dataLoading: true,
			videoPlay: false,
		}
	}
	

	componentDidMount(){
		window.scrollTo({top: 0, behavior: 'smooth'});
		GetURLVar().then(function(query){ 
			this.getData(query.id);
			this.setState({id: query.id});
		}.bind(this));
	}


	getData(tempId){
		var url = devconfig.adminV3+"/resources/video-detail";
    var data = JSON.stringify({
    	"id": parseInt(tempId)
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        this.setState({data:response.data, dataLoading: false});
			}
      else{
        this.setState({ dataLoading: false});
      }
    }.bind(this))
	}
	editVideo=()=>{
		var overlaylogin = '<div class="e-login-overlay" id="e-log-overlay"></div>';
    $("body").append(overlaylogin);
    $('.e-edit-video').addClass('e-login-fly');
	}
	 confirm(){
  	$('#e-confirmModal').modal('toggle');
  }
  DeleteVideo=()=>{
   var url = devconfig.adminV3+"/resources/delete-video";
    var data = JSON.stringify({
      "video_id" : parseInt(this.state.id),
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
         window.location.href = '/resources?tab=1';

      }
    }.bind(this))
    
  }
	 pauseVideo=()=>{
		this.setState({play: false});
	}

	render(){
		if(this.state.dataLoading){
			return(
				<div className="e-min-height-loader pt-5">
				</div>
			);
		}
		return(
			<React.Fragment>
			{
				this.state.data ?
			<React.Fragment>
				<div className="container e-videdtls">
					<div className="col-lg-12 mt-5">
						<Links className="e-marketDetails-link"  activeClassName="" name="Home" url="home"/>
						<img src={linkarrow} alt="arrow" className="mx-2"/>
						<Links className="e-marketDetails-link"  activeClassName="" name="Resources" url="resources?tab=1"/>
						<img src={linkarrow} alt="arrow" className="mx-2"/>
						<Links className="e-marketDetails-link"  activeClassName="" name="Videos" url="resources?tab=1"/>
						<img src={linkarrow} alt="arrow" className="mx-2"/>
						<span className="e-marketDetails-link-color">{this.state.data.title}</span>
							<div className="text-center offset-lg-1 mt-4 col-lg-10 col-md-12 col-sm-12 col-12">
							{
								this.state.videoPlay ?	
								<ReactPlayer className= "e-resource-video"  id="player" url={this.state.data.youtube_url} controls={true} width="100%" />
								:
								<div className="text-center e-video-thump position-relative">
									<img src={this.state.data.thumbnail} alt="videocover" className= "e-resource-video-cover" />
									<button className="e-vidoelist-play-btn"   onClick={()=> this.setState({videoPlay: true})}>
            					<img className="e-vidoelist-play" src={playbtn} alt="playbtn"/>
      	 					</button>
      	 					<button className="e-video-edit" onClick={this.editVideo}><img src={Edit}/></button>
									<button className="e-video-delete" onClick={this.confirm}><img src={Delete}/></button>
      	 					<span className="e-resouce-video-durtn">{this.state.data.duration}</span>
      	 				</div>
							}
							</div>
					</div>
					<section className="e-video-details-main-sec">
						<div className="col-lg-12 col-md-12 col-sm-12 col-12 ">
							<div className="card border-0 p-5 e-videodetails-card">
								<h2 className="e-playstorebanner-head">{this.state.data.title}</h2>
								<div class="row no-gutters mt-4">
									<div className="col-lg-6 text-left">
									{
										this.state.data && this.state.data.products.map((item ,key)=>{
											return(
												<span className="mt-3 e-vidoe-list-span mr-3">{item.name}</span>
											)
										})
									}
									</div>
									<div className="col-lg-6 text-right">
									<h6 className="e-video-desc">Added on</h6>
										<p className="e-videodate">{this.state.data.added_on}</p>  
										</div> 
								</div>
							</div>
						</div>
					</section>
				</div>
				<EditVideo reloadedit={() =>this.getData(this.state.id)} toBeEdit={this.state.data}/>
				<DeleteModal modalTitle="Delete " modalContent="Are sure want to delete" confirmSuccess={() => this.DeleteVideo()}/>
			</React.Fragment>
			:
			null
		}
			</React.Fragment>
		)
	}

}