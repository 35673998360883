import React from 'react';
import $ from 'jquery';
// import component
import InputBox from '../../components/common/inputBox';

import APIPost from '../../components/apiCall/apiPost';
import APIGet from '../../components/apiCall/apiGet';
import devconfig from '../../config/config.js';

export default class UpdatePending extends React.Component{
	constructor(props){
		super(props);
		this.state={
      
		}
	}
  componentDidMount(){
   $('#updatePending').on('show.bs.modal',  (event)=>{
      console.log(this.props.data)
      if(this.props.data){
        this.setState({
          name : this.props.data.name,
          phone: this.props.data.number,
          vendor_id: this.props.data.id,
        })
      }
    })
  }
  update =() =>{
    var url = devconfig.adminV2+"/vendor/edit-profile";
    var data = JSON.stringify({
      "vendor_id" : parseInt(this.state.vendor_id),
      "name" : this.state.name,
      "mobile" : this.state.phone
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        this.props.confirm();
        $('#updatePending').trigger('click');
      }
      else{
        this.setState({ dataLoading: false});
      }
    }.bind(this))
  }
  // if(this.props.type ==2){
  //    var url = devconfig.adminV3+"/partner/payout";
  //    var data = JSON.stringify({
  //     "payout_id" : parseInt(this.props.data),
  //     "amount" : parseInt(this.state.amount),
  //   })
  //   }
	
	render(){
		return(
      <div class="modal fade" id="updatePending" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Update Pending Request</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div className="col-lg-12 pl-1 pr-2">
                <label>name*</label>
                <InputBox placeholder="Enter name" value={this.state.name} onChange={(e)=>{this.setState({name: e.target.value})}} />
              </div>
              <div className="col-lg-12 pl-1 pr-2">
                <label>Mobile*</label>
                <InputBox placeholder="Enter number" value={this.state.phone} onChange={(e)=>{this.setState({phone: e.target.value})}} />
              </div>
            </div>
            <div class="text-right col-lg-12 pr-4">
            {
              this.state.name &&
              this.state.phone  ?
              <button type="button" class="e-payout-btn e-p-btn border-0" onClick={this.update}>Update</button>
              :
              <button type="button" class="e-payout-btn e-p-btn border-0 e-in-active" onClick={this.update}>Update</button>
            }
              
              
            </div>
          </div>
        </div>
      </div>
		);
	}
}  