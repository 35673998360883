import React from 'react';
import $ from 'jquery';
// import component
import InputBox from '../../components/common/inputBox';

import APIPost from '../../components/apiCall/apiPost';
import APIGet from '../../components/apiCall/apiGet';
import devconfig from '../../config/config.js';
import DatePicker from '../../components/common/datePicker';
import moment from 'moment';

export default class EditSubscription extends React.Component{
	constructor(props){
		super(props);
		this.state={
      amount : '',
		}
	}


    componentDidMount(){
      $('#updateSubs').on('shown.bs.modal', async function () {
        await this.setState({ start_date: moment(this.props.startDate, 'DD-MM-YYYY'), end_date: moment(this.props.endDate, 'DD-MM-YYYY'), id: this.props.id});
        await console.log(this.state.start_date);
        await console.log(this.state.start_date)
      }.bind(this));

    }
    update = () =>{
      let sdate = '';
      let endDate = '';
      if(this.state.start_date && this.state.start_date._i){
        sdate = this.state.start_date.format("DD-MM-YYYY");
      }
      else{
        sdate = this.state.start_date
      }
      if(this.state.end_date && this.state.end_date._i){
         endDate = this.state.end_date.format("DD-MM-YYYY");
      }
      else{
        endDate = this.state.end_date
      }
      let url = devconfig.adminV3+'/subscription/update';
      let data = JSON.stringify({
        "purchased_id" : parseInt(this.state.id),
        "payment_status" : true,
        "start_date" : sdate, 
        "end_date" : endDate  
       })
        APIPost(url, data).then((response)=>{
          if(response.status_code === 200){
            this.props.confirmSuccess();
            $('#updateSubs').trigger('click');
          }
          else{
            this.setState({ toast: response.message, toastType: 'fail'})
          }
        })
      setTimeout(()=>{ this.setState({ toast: null }) }, 4000)
    }

	render(){
		return(

			<div class="modal fade" id="updateSubs" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Update Subscription</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body e-update-sub">
        <div className="row">
        <div className="col-lg-12">
          <label>Subscription Start </label>
          {
            this.state.start_date ?
              <DatePicker value={this.state.start_date} dateChange={(e) => this.setState({start_date: e})} outsideRange={true}/>
            :
              null
          }
          
        </div>
        <div className="col-lg-12 mt-4">
          <label>Subscription End </label>
          {
            this.state.end_date ?
              <DatePicker value={this.state.end_date} dateChange={(e) => this.setState({end_date: e})} outsideRange={true}/>
            :
              null
          }
          
        </div>
        </div>
      </div>
      <div class="text-right">
        <button type="button" class="e-payout-btn e-p-btn border-0" onClick={this.update}>Update</button>
      </div>
    </div>
  </div>
</div>
		);
	}
}  