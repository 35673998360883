import React from 'react';
import $ from 'jquery';
// import component
import InputBox from '../../components/common/inputBox';

import APIPost from '../../components/apiCall/apiPost';
import APIGet from '../../components/apiCall/apiGet';
import devconfig from '../../config/config.js';

export default class UpdateCommission extends React.Component{
	constructor(props){
		super(props);
		this.state={
      amount : ''
		}
	}
  componentDidMount(){
    this.setState({amount: this.props.data ? this.props.data : null})
  }
  payNow=()=>{
   
   var url = devconfig.adminV3+"/vendor/update-tds";
    var data = JSON.stringify({
      "vendor_id" : parseInt(this.props.id),
      "tds_percentage" : parseInt(this.state.amount),
    })

    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        this.props.confirmSuccess(response.status_code);
        
        $('#updateTds').modal('toggle');
        this.setState({amount: ""})
      }
    }.bind(this))
    
  }
  // if(this.props.type ==2){
  //    var url = devconfig.adminV3+"/partner/payout";
  //    var data = JSON.stringify({
  //     "payout_id" : parseInt(this.props.data),
  //     "amount" : parseInt(this.state.amount),
  //   })
  //   }
	
	render(){
		return(

			<div class="modal fade" id="updateTds" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">TDS %</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <InputBox placeholder="Enter the commission amount" value={this.state.amount} onChange={(e)=>{this.setState({amount: e.target.value})}} />
      
     
      </div>
      <div class="text-right">
      {
        this.state.amount.length ?
        <button type="button" class="e-payout-btn e-p-btn border-0" onClick={this.payNow}>Update</button>
        :
        <button type="button" class="e-payout-btn e-p-btn border-0 e-in-active" onClick={this.payNow}>Update</button>
      }
        
        
      </div>
    </div>
  </div>
</div>
		);
	}
}  