/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';


// import service
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';
import GetURLVar from '../../services/apiCall/getParams/get-url.js';
/*Assets and Components*/
import devconfig from '../../config/config.js';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LazyLoaderGif from '../../components/common/lazyLoaderGif';
import SelectBox from '../../components/common/selectBox';
import InputBox from '../../components/common/inputBox';
import EditData from '../../components/popups/editLeadData';
import CSVReader from 'react-csv-reader';
import NoDataFound from '../../components/common/noData';
import {DateRangePicker, isInclusivelyBeforeDay} from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import Toast from '../../components/popups/Toast';
import EditIcon from '../../assets/images/icons/edit.svg';
import DeleteIcon from '../../assets/images/icons/delete-group.svg';
import UpdateSubs from '../../components/popups/updateSubs';
import Confirm from '../../components/popups/confirmationModal';

import noResultImg from '../../assets/images/no-result.png';
import PlaceholderStyleOneLarge from '../../components/placeholders/placeholderCol1Large.js';

class tradeStoreTab extends Component {
  notify = (e) => toast(e);
  constructor(props){
    super(props);
    this.state = {
      loading: true,
      selectedSort: {value: 6, label: "Created desc"},
      selectedProduct: {value: undefined, label: "All"},
      selectedPartner: {value: undefined, label: "All"},
      selectedLeadStatus: {value: undefined, label: "All"},
      productFilterDefault: {value: undefined, label: "All"},
      partnerFilterDefault: {value: undefined, label: "All"},
      sortDefault: {value: 6, label: "Created desc"},
      leadDefault: {value: 0, label: "New"},
      leadStatusDefault: {label: "All", value: undefined},
      pageNum: 1,
      productFilters: [],
      partnerFilters: [],
      data: [],
      listData: [],
      errorMessage: [],
      itemToEdit: {},
      initalDate: null,
      startDate: moment(),
      endDate: moment(),
      dateFilter: false,
      filterChange: false
    }
  }

  componentDidMount(){
    // this.getFilters();
    this.getData();
    this.getProduct();
    // this.downloadCSV();
    $(window).scroll(
        async () => {
          if (
            $(window).scrollTop() + $(window).height() >
            $(document).height() - 400
          ) {
            await this.setState({
              pageNum: this.state.pageNum + 1
            });
            await this.getData()
          }
        }
      );


  }

  getData =()=>{
    this.setState({ loading: true})
  	var start_date_temp = this.state.startDate;
      var end_date_temp = this.state.endDate;
      if(this.state.startDate && this.state.endDate){
      var start_date = start_date_temp.format("DD-MM-YYYY");
      var end_date = end_date_temp.format("DD-MM-YYYY");
      start_date_temp.format("dd-mm-yyyy");
      end_date_temp.format("dd-mm-yyyy");
    }
		var url = devconfig.adminV3+'/subscription/list';
    var data = JSON.stringify({
      sort_by: this.state.selectedSort.value,
      page_num: this.state.pageNum,
      product_id: this.state.selectedProduct.value,
      start_date: start_date,
      end_date: end_date,
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        // this.setState({listData:response.data, loading: false});
        var temdta = [];

        if(this.state.listData.length && !this.state.filterChange ){
          temdta = this.state.listData;
          temdta = $.merge(temdta, response.data);
        }
        else{
          temdta = response.data;
        }
         this.setState({listData:temdta, loading: false, filterChange: false});
			}
      else{
        this.setState({ loading: false, filterChange: false});
      }
    }.bind(this))
	}

  searchData = async () => {
    // await this.setState({loading: true})
    $.ajax({
        type: "POST",
        url: devconfig.adminV3 +'/subscription/search-list',
        dataType: 'json',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Token': localStorage.getItem("access_token"),
        },
        data: JSON.stringify({
          text: this.state.searchQuery
        })
    }).then(function (response) {
        if (response.status) {
          
          // var newData = [...this.state.data, ...response.data];
         
          this.setState({
            listData: response.data,
            loading: false
          })
        }else{
          this.notify(response.data);
        }
    }.bind(this))
  }
    getProduct=()=>{
    var url = devconfig.adminV3+"/products/product-names";
    APIGet(url).then(function(response){
      console.log(response);
      if(response.status === "ok"){
         this.setState({products: response.data});
      }
    }.bind(this))
  }


  productChange = async (product) =>{
  	await this.setState({selectedProduct: product, loading: true, pageNum: 1, data: [], filterChange: true});
    await this.getData(this.state.selectedSort.value);
    // await this.downloadCSV(this.state.selectedSort.value);
  }
  sortChange = async (selectedSort) => {
    await this.setState({selectedSort: selectedSort, loading: true, pageNum: 1, data: [], filterChange: true});
    await this.getData();
    // await this.downloadCSV();
  }
downloadCSV=()=>{
  var start_date_temp = this.state.startDate;
      var end_date_temp = this.state.endDate;
      if(this.state.startDate && this.state.endDate){
      var start_date = start_date_temp.format("DD-MM-YYYY");
      var end_date = end_date_temp.format("DD-MM-YYYY");
      start_date_temp.format("dd-mm-yyyy");
      end_date_temp.format("dd-mm-yyyy");
    }
    var url = devconfig.adminV3+'/subscription/download-list';
    var data = JSON.stringify({

          page_num: this.state.pageNum,
          product_id: this.state.selectedProduct.value,
          start_date: start_date,
          end_date: end_date,
          sort_by: this.state.selectedSort.value
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        this.setState({downloadList:response.file_url});
        window.open(response.file_url);
      }
      else{
         this.setState({toast: response.message,  toastType:"fail"})
        setTimeout( function() { this.setState({ toast: null}); }.bind(this), 4000);
      }
    }.bind(this))
}

  onDatesChange = async ({ startDate, endDate }) => {

    if(startDate && endDate){
      var fromDate = startDate.format("YYYY-MM-DD");
      var toDate = endDate.format("YYYY-MM-DD");

      await this.setState({
        startDate,
        endDate,
        fromDate: fromDate,
        toDate: toDate,
        data: [],
        pageNum: 1,
        loading: true,
        dateFilter: true,
        filterChange: true
      });

      await this.getData();
      // await this.downloadCSV();
    }
  }

  async searchChange(e, kopp){
    var query = e.target.value;
    await this.setState({searchQuery: query});

    if(query.length >= 3){
      await this.setState({data: [], loading: true, filterChange: true})
      this.searchData();
    }
    if(query.length===0){
      await this.setState({loading: true, pageNum: 1, data: [], filterChange: true});
      await this.getData();
      // await this.downloadCSV();
    }
  }

  remove = () =>{
    let url = devconfig.adminV3+'/subscription/update';
    let data = JSON.stringify({
      "purchased_id" : parseInt(this.state.id),
      "payment_status" : false,
      "start_date" : this.state.date, 
      "end_date" : this.state.end  
     })
      APIPost(url, data).then(async  (response)=>{
        if(response.status_code === 200){
          this.setState({ toast: 'Successfully removed!', toastType: 'success', listData: [] });
          await this.getData();
        }
        else{
          this.setState({ toast: response.message, toastType: 'fail'})
        }
      })
    setTimeout(()=>{ this.setState({ toast: null }) }, 4000)
  }



  setData = (id, start_date, end_date) => {
    var sdate = new Date(start_date);
    var edate = new Date(end_date);
    this.setState({
      id: id,
      date: moment(sdate).format('DD-MM-YYYY'),
      end: moment(edate).format('DD-MM-YYYY')
    })
  }
  render() {
    // const papaparseOptions = {
    //   header: true,
    //   dynamicTyping: true,
    //   skipEmptyLines: true,
    //   transformHeader: header =>
    //     header
    //       .toLowerCase()
    //       .replace(/\W/g, '_')
    // }
    // const { mainCatOption } = this.state;
    const sortOptions = [
      {value: 1, label: "Expiring soon"},
      {value: 2, label: "Subscription date asc"},
      {value: 3, label: "Subscription date desc"},
      {value: 4, label: "New first"},
      {value: 5, label: "Old first"},
    ];
   	const productOptions = this.state.products ?

        this.state.products.map((item, index) => {
        return(
         { value: item.id, label: item.name }
        );
      })
    :
      null

    // var productFilters = this.state.productFilters;
    // var partnerFilters = this.state.partnerFilters;
    return (
      <div className="e-main-content e-subscription-wrap">
        <div className="container">

          <div className="row ">
            <div className="col-lg-12 text-right">
            <a className="e-download-link e-hover mr-5" onClick={this.downloadCSV}  target="_blank">Download CSV</a>
            </div>
          </div>

          <div className="row mt-4 pt-2">
            <div className="col-lg-2">
              <div className="e-filter-wrap pt-2">
                <label>Date</label>
              </div>
              <DateRangePicker
                startDate={this.state.startDate}
                endDateId="e-endDate"
                startDateId="e-startDate"
                endDate={this.state.endDate}
                onDatesChange={this.onDatesChange}
                focusedInput={this.state.focusedInput}
                onFocusChange={focusedInput => this.setState({ focusedInput })}
                startDatePlaceholderText={"Start date"}
                endDatePlaceholderText={"End date"}
                disableScroll={false}
                hideKeyboardShortcutsPanel={true}
                regular={false}
                small
                numberOfMonths={1}
                isOutsideRange={day => !isInclusivelyBeforeDay(day, moment())}
              />
            </div>
            <div className="col-lg-2">
              <div className="e-filter-wrap pt-2">
                <label>All Products</label>
                <SelectBox options={productOptions} selectchange={this.productChange} placeholder="All Products"/>
              </div>
            </div>
            <div className="col-lg-4"></div>
            <div className="col-lg-2">
              <div className="e-filter-wrap pt-2">
                <label>Sort by:</label>
                <SelectBox defaultValue={this.state.sortDefault} options={sortOptions} selectchange={this.sortChange} placeholder="Sort"/>
              </div>
            </div>


            <div className="col-lg-2 pt-4 mt-2">
              <div className="e-filter-wrap pt-2">
                <input className="e-dash-search e-search-subscription m-0" placeholder="Search for users" value={this.state.searchQuery} type="text" onChange={this.searchChange.bind(this)} required/>
              </div>
            </div>
          </div>
          </div>








          <ToastContainer position="bottom-left" autoClose={3000} hideProgressBar newestOnTop closeOnClick={false} rtl={false} pauseOnVisibilityChange={false} draggable={false} pauseOnHover/>


        <div className="container-fluid e-tradestore-table">
          {
            this.state.loading?
              <div className="row">
                <div className="col-lg-12 text-center pt-5">
                  <LazyLoaderGif/>
                </div>
              </div>
            :
              <div className="row mt-4">
              {
                this.state.listData && this.state.listData.length ?
                  <div className="col-md-12">
                    <table className="table  table-striped e-tdstr-table">
                      <thead>
                        <tr>
                          <th>Client ID</th>
                          <th>Client Name</th>
                          <th>Product</th>
                          <th>Email</th>
                          <th>Phone</th>
                          <th>Subscr. Start</th>
                          <th>Subscr. End</th>
                          <th>Amount</th>
                          <th>Transaction ID</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          this.state.listData.map((item, index)=>{
                            return(
                              <tr key={index}>
                                <td className="e-s-text">{item.alice_blue_id}</td>
                                <td className="text-capitalize">{ item.name ? item.name.toLowerCase() : "--"}</td>
                                <td className="e-subscription-product">{item.product_name}</td>

                                <td>
                                  <a href={"mailto:" + item.email} target="_blank">
                                    {item.email}
                                  </a>
                                </td>
                                <td>{item.mobile}</td>

                                <td className="text-capitalize e-s-text">{item.purchased_on && item.purchased_on != "N/A" ? item.purchased_on : "--"}</td>
                                <td className="text-capitalize e-s-text">{item.expired_on && item.expired_on != "N/A" ? item.expired_on : "--"}</td>
                                 <td>{item.total}</td>
                                  <td>{item.transaction_id}</td>
                                  <td className="d-flex">
                                    <button className="e-enable-btn" data-toggle="modal" data-target="#updateSubs" onClick={() => this.setData(item.prchased_id, item.purchased_on, item.expired_on)}><img src={EditIcon} className="e-edit-icon"/></button>
                                    <button className="e-enable-btn" data-toggle="modal" data-target="#e-confirmModal" onClick={() => this.setData(item.prchased_id, item.purchased_on, item.expired_on)}><img src={DeleteIcon} className="e-edit-icon"/></button>
                                  </td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                  </div>
                :
                  <NoDataFound data="No subscriptions found."/>
              }
              </div>
          }
          {
            this.state.toast ?
            <Toast data={this.state.toast} type={this.state.toastType}/>
            :
            null
          }
          <UpdateSubs confirmSuccess={()=>{ this.setState({ toast: 'Subscription updated!', toastType: 'success', listData: [] }); this.getData(); setTimeout(()=>{ this.setState({ toast: null })  },4000); }} startDate={this.state.date} endDate={this.state.end} id={this.state.id} />
          <Confirm modalTitle="Confirmation" modalContent="Are you sure want remove this?" confirmSuccess={()=>{this.remove()}}/>
        </div>
      </div>
    );
  }
}

export default tradeStoreTab;
