import React from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import { BsStarFill } from 'react-icons/bs';
// import components
import Textarea from '../../components/common/textarea';
import PrimaryButton from '../../components/buttons/primaryBlueButton';
// import assets
import closebtn from '../../assets/images/header/close.png';

class ReviewModal extends React.Component {
  constructor(props){
    super(props);
    this.state={
     message: "",
     rating: '',
    }
  }
 closeModal =() =>{
  $('.e-review-modal').removeClass('e-modal-show');

  $('#e-modal-overlay').remove();
  this.setState({rating: null})
  this.setState({message: null})
 }
  render() {

    return (
      <div className="backdrop" >
        <div className="modal e-review-modal p-4">
          <div className="row">
          <div className="col-lg-10">
            <h5 className="e-reviewmodal-head">Rate & Review</h5>
          </div>
          <div className="col-lg-2">
            <button className="e-review-modal-closebtn" onClick={this.closeModal}><img src={closebtn} alt="close button" className="e-toggle"/></button>
          </div>
          </div>
          <div className="body">
            <p className="mt-3 e-reviewmodal-p">Describe your experience with the product</p>
            <div className="e-rating-star">
              {[...Array(5)].map((star , i,)=>{
              const ratingValue = i + 1;
              return(
                <label>
                  <input type ="radio" name="rating" value={ratingValue} onClick={()=>this.setState({rating: ratingValue})} />
                  <BsStarFill className="e-star" color={ratingValue <=  this.state.rating ? "#ffc107" : "#e4e5e9" }/>
                </label>
              )
              })}
            </div>
            <Textarea className="e-text mt-4" valueChange={(tempVal) => this.setState({message: tempVal})} placeholder="Write your review..."/>
            <PrimaryButton name="Submit your review" disabled={!this.state.message } />
          </div>

        </div>
      </div>
    );
  }
}



export default ReviewModal