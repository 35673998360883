/*Package importing*/
import React, { Component } from 'react';

/*Assets and Components*/
import InputBox from '../../components/common/inputBox';
import AddVendor from './addVendor';
import VendorRequest from './vendorRequest';
import PartneredVendors from './partneredVendors';
import Payouts from './payouts';
import VendorProfile from './vendorProfile';


class VendorsMain extends Component {
  constructor(props){
    super(props);
    this.state = {
      activeMenu: 1,
      detailID: null,
      getProfile : false,
    }
  }

  componentWillMount() {
    var query = this.getUrlVars();

    if(query.id){
      this.setState({activeMenu: 4});
    }
  }

  getUrlVars(){
    var vars = [], hash;
    var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
    for(var i = 0; i < hashes.length; i++){
      hash = hashes[i].split('=');
      vars.push(hash[0]);
      vars[hash[0]] = hash[1];
    }
    return vars;
  }

  sideMenuClick(tempItem){
    this.setState({activeMenu: tempItem});
  }

  vendorDetailClick(tempId){
    // window.location.href = '/vendor-profile?id='+tempId;
    this.setState({getProfile: true, detailID: tempId})
  }

  render() {
    return (
      <div className="container e-main-content ">
        <div className="row">
          <div className="col-lg-2 e-vendors-sideMenu-wrap">
            <div className="e-vendors-sideMenu">
              {
                this.state.activeMenu === 1 ?
                  <div className="e-vendors-sideMenu-item e-active" onClick={()=>{this.setState({getProfile: !this.state.getProfile}); this.sideMenuClick.bind(this, 1)}}>Partnered Vendors</div>
                :
                  <div className="e-vendors-sideMenu-item" onClick={this.sideMenuClick.bind(this, 1)}>Partnered Vendors</div>
              }
            </div>
            <div className="e-vendors-sideMenu">
              {
                this.state.activeMenu === 3 ?
                  <div className="e-vendors-sideMenu-item e-active" onClick={this.sideMenuClick.bind(this, 3)}>Approve Requests</div>
                :
                  <div className="e-vendors-sideMenu-item" onClick={this.sideMenuClick.bind(this, 3)}>Approve Requests</div>
              }
            </div>
            <div className="e-vendors-sideMenu">
              {
                this.state.activeMenu === 4 ?
                  <div className="e-vendors-sideMenu-item e-active" onClick={this.sideMenuClick.bind(this, 4)}>Payouts</div>
                :
                  <div className="e-vendors-sideMenu-item" onClick={this.sideMenuClick.bind(this, 4)}>Payouts</div>
              }
            </div>
             <div className="e-vendors-sideMenu">
              {
                this.state.activeMenu === 2 ?
                  <div className="e-vendors-sideMenu-item e-active" onClick={this.sideMenuClick.bind(this, 2)}>+ Add New Vendor</div>
                :
                  <div className="e-vendors-sideMenu-item" onClick={this.sideMenuClick.bind(this, 2)}>+ Add New Vendor</div>
              }
            </div>

          </div>
          {
            this.state.activeMenu === 1 && !this.state.getProfile?
              <PartneredVendors detailClick={this.vendorDetailClick.bind(this)}/>
            :
              null
          }


          {
            this.state.activeMenu === 2 ?
              <AddVendor/>
            :
              null
          }

          {
            this.state.activeMenu === 3 ?
              <VendorRequest/>
            :
              null
          }

          {
            this.state.activeMenu === 4 ?
              <Payouts/>
            :
              null
          }
          {
           this.state.activeMenu === 1 &&  this.state.getProfile?
            <VendorProfile id={this.state.detailID} reload={()=>{this.vendorDetailClick.bind(this)}}/>
            :
            null

          }

        </div>
      </div>
    );
  }
}

export default VendorsMain;
