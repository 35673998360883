import $ from 'jquery';

export default async function APIGet(url) {
  return await $.ajax({
    type: "GET",
    url: url,
    dataType: 'json',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Access-Token': localStorage.getItem("access_token")
    }
  })
  .then(function(response){ return response }.bind(this))
  .catch(function(response){ return response.responseJSON }.bind(this))
}