/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Import custom components and assets*/
import SelectBox from '../../components/common/selectBox';
import InputBox from '../../components/common/inputBox';
import Textarea from '../../components/common/textarea';
import APIPost from '../../components/apiCall/apiPost';
import APIGet from '../../components/apiCall/apiGet';
import  ProductDetail from '../../container/detail/main';
import devconfig from '../../config/config.js';
import ImageUploader from '../../components/common/imageUploader';
import UploadLoader from '../../components/common/uploadLoader';



/*Assets*/
// import AddLogo from '../../assets/images/add-logo.svg';
import AddIllustration from '../../assets/images/add-illustartion.svg';
import AddVideo from '../../assets/images/video.svg';
import Close from '../../assets/images/close-btn.svg';
import Img from '../../assets/images/image.svg';
import Delete from '../../assets/images/delete-black.svg';



class UpdatePopUp extends Component {
  constructor(props){
    super(props);
    this.state = {
      error: '',
    }
  }

  componentDidMount(){
    if(this.props.data){
    this.setState({logo: this.props.data.image, url: this.props.data.redirection_url, id: this.props.data.id})
  }

  }
  updateBanner=()=>{
    this.setState({updating: true});
    var url = devconfig.adminV3+"/notification/update-banner";
    var data = JSON.stringify({
      "image" : this.state.logo,
      "redirection_url" : this.state.url,
    });
    APIPost(url ,data).then(function(response){
      if(response.status){
        this.setState({updating: false});
        this.hideLogin();
        this.props.reload();
      }
      else{
        this.setState({updating: false})
      }
    }.bind(this))
  }
  async uploadStatus(status){
    this.setState({uploading: status})
  }

  logoUploaded(tempData){
    // alert(tempData)
    this.setState({logo: tempData})
  }

  async logoUploadTrigger(tempType){
    await this.setState({imgLoader: tempType});
    $("#e-prod-logo-upload").click()
  }

  hideLogin=()=> {
    $('#e-log-overlay1').remove();
  this.props.modalClose();
  }


  render() {
     const options = this.state.products ?


        this.state.products.map((item, index) => {

        return(
         { value: item.id, label: item.name }
        );
      })

    :
      null

    return(

      <div className={"container text-left e-login e-add-product-modal "+this.props.modalClass}>
        <div className="row">
          <div className="col-lg-12 pl-5 pr-5">
            <div className="row mb-3 e-modal-border">
              <div>
                <button className="e-login-closebtn">
                  <img src={Close} alt="close button" className="e-toggle" onClick={this.hideLogin.bind(this)}/>
                </button>
              </div>
              <div className="col-lg-6 pt-5 mt-3">
                <h5 className="">Edit & Update Home Promotion Banner</h5>
              </div>
              <div className="col-lg-5 pt-5 mt-3 ml-5">
              {
                this.state.logo  ?
                <span className="e-save e-p-btn float-right" onClick={this.updateBanner} data-toggle="modal" data-target="#e-addProduct">Update promotion banner</span>
                :
                <span className="e-save e-p-btn float-right e-in-active" onClick={this.updateBanner} data-toggle="modal" data-target="#e-addProduct">Update promotion banner</span>
              }
              
           

              </div>

            </div>

            {
              this.state.error ?

              <div className="row pt-4">
                <div className="col-lg-6">
                  <div className="e-error mb-3">{this.state.error}</div>
                </div>
              </div>

              :
                null

            }
            <div className="row pt-3">
              <div className="col-lg-6 e-select-product">
                <label>Banner Image</label>
                <div className="row">
                <div className="col-lg-6 position-relative">
                  {
                    this.state.imgLoader == 1 && this.state.uploading ?
                        <span className="e-img-upload-loader"></span>
                    :
                      <React.Fragment>
                        {
                          this.state.logo ?
                            <span className="e-img-preview">
                              <img src={this.state.logo}/>
                              <span className="e-change" onClick={()=>{ this.setState({logo: null}) }}></span>
                            </span>
                          :
                            <React.Fragment>
                              <span className="e-upload-btn e-store text-center mb-3" onClick={this.logoUploadTrigger.bind(this, 1)}><span className="mt-2">Add Logo</span></span>
                           </React.Fragment>
                        }
                      </React.Fragment>
                  }
                  <ImageUploader
                    accept="application/pdf, image/*"
                    className="hidden"
                    tempKey="/prod/illustration"
                    id="e-prod-logo-upload"
                    uploadStatus={this.uploadStatus.bind(this)}
                    uploadSucess={this.logoUploaded.bind(this)}
                  />
                  </div>
                  </div>
              </div>
            </div>
            <div className="row pt-3">
              <div className="col-lg-12">
                <label>Redirect URL</label>
              </div>
               <div className="col-lg-6" >
                <InputBox placeholder="Redirect Url" value={this.state.url}
                      onChange={(e) => {
                          this.setState({url: e.target.value})
                        }} />
                    </div>


            </div>
          </div>

      </div>
         </div>

    )
  }
}

export default UpdatePopUp;
