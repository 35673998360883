
import React from 'react';

import NoDataFound from '../../components/common/noData';
import ApiLoader from '../../components/Loaders/ApiLoader';

// import services
import APIPost from '../../components/apiCall/apiPost';
import APIGet from '../../components/apiCall/apiGet';
import devconfig from '../../config/config.js';

export default class extends React.Component{
    constructor(props){
        super(props);
        this.state={

        }
    }

    componentDidMount(){
        this.getPartners();
    }

    getPartners=()=>{
        this.setState({ loader: true});
        let url = devconfig.adminV3+'/partner/list';
        let data = JSON.stringify({
            'sort_by' : 1
        })
        APIPost(url, data).then((response)=>{
            if(response.status){
                this.setState({ loader: false, data: response.data})
            }
            else{
                this.setState({loader: false, data: null});
            }
        })
    }


    render(){
        return(
            <div className="container py-5">
                <div className="row ">
                    <div className="col-lg-12">
                        <h5 className="e-partners-title mb-4">Partners</h5>
                    </div>
                    {
                        this.state.loader ? 
                        <div className="col-lg-12 text-center mt-5 pt-5">
                            <ApiLoader/>
                        </div>
                        : !this.state.loader && !this.state.data ?
                            <NoDataFound/>
                        :
                        this.state.data && this.state.data.map((item, key)=>{
                            return(
                                <div className="col-lg-4" key>
                                    <div className="e-request-item e-card-transition cursor-pointer" onClick={()=>window.location.href="/partner-detail?id="+item.id}>
                                        <div className="media">
                                            <span className="e-request-placeholder"></span>
                                            <div className="media-body ml-3">
                                            <h5 className="mb-2">{item.name}</h5>
                                            <p className="mb-2">Email: {item.email}</p>
                                            <p className="mb-2">Mobile: {item.mobile ? item.mobile : '--' }</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                        
                    }
                </div>
            </div>
        )
    }
}