/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';
/*Assets and Components*/
import APIPost from '../../components/apiCall/apiPost';
import devconfig from '../../config/config.js';
import VendorCard from '../../components/card/vendorCard';
import NoDataFound from '../../components/common/noData';
import PlaceholderStyleOneSmall from '../../components/placeholders/placeholderCol1Small';
import SelectBox from '../../components/common/selectBox';
import ApiLoader from '../../components/Loaders/ApiLoader';

class PartneredVendors extends Component {
  constructor(props){
    super(props);
    this.state = {
      data: [],
      dataLoading: true,
      blockedUsers: [],
      typeFilterDefault:{value: 1, label: 'Active'},
      blockedActive: false,
      pageNum: 1,
    }
  }

  componentWillMount() {
    this.listData(this.state.pageNum);
  }

  listData(tempPageNum){
    this.setState({dataLoading: true});
    var url = devconfig.adminV2+"/vendor/list";
    var data = JSON.stringify({
      "page_num" : tempPageNum
    })

    APIPost(url, data).then(function(response){
      var temdta = [];
      if(response.status){
        if(this.state.data.length  ){
          temdta = this.state.data;
          temdta = $.merge(temdta, response.data);
        
      }else{
        temdta = response.data
      }
      this.setState({data: temdta, blockedUsers: response.blocked, dataLoading: false});
      }
      else{
        this.setState({errorMessage: response.message, dataLoading: false});
      }
    }.bind(this))
  }

  loadMoreClick(){
    this.setState({ pageNum: this.state.pageNum + 1});
    this.listData(this.state.pageNum + 1);
  }

  cardClick(tempID){
    this.props.detailClick(tempID);
  }

  selectTypeChange(tempItem){
    if(tempItem.value === 2){
      this.setState({blockedActive: true});
    }
    else{
      this.setState({blockedActive: false});
    }
  }

  render() {
    const options = [
      { value: 1, label: 'Active' },
      { value: 2, label: 'Blocked' }
    ]
    return (
      <div className="col-lg-10">
        {
          this.state.dataLoading ?
            <div className="row">
              <div className="col-lg-6">
                <PlaceholderStyleOneSmall/>
              </div>
              <div className="col-lg-6">
                <PlaceholderStyleOneSmall/>
              </div>
            </div>
          :
            <div className="e-partnered-wrap mb-5">
              <h2 className="mb-4">Partnered Vendors</h2>
              {/*<div className="row mb-4">
                              <div className="col-lg-2 e-vendor-filter-wrap ">
                                <SelectBox options={options} defaultValue={this.state.typeFilterDefault} placeholder="Type" selectchange={this.selectTypeChange.bind(this)}/>
                              </div>
                            </div>*/}

                {
                  this.state.blockedActive ?
                    <div>
                      {
                        this.state.blockedUsers.length ?
                          <div className="row">
                            {
                              this.state.blockedUsers.map(function(item, index){
                                return(
                                  <VendorCard data={item} key={index} cardClickSuccess={this.cardClick.bind(this)}/>
                                )
                              }.bind(this))
                            }
                          </div>
                        :
                          <div className="row">
                            <NoDataFound data="No vendors data found."/>
                          </div>
                      }
                    </div>
                  :
                    <div>

                      {
                        this.state.dataLoading ?
                        <ApiLoader/>
                        :
                        null
                      }
                      {
                        this.state.data.length ?
                          <div className="row">
                            {
                              this.state.data.map(function(item, index){
                                return(
                                  <VendorCard data={item} key={index} cardClickSuccess={this.cardClick.bind(this)}/>
                                )
                              }.bind(this))
                            }
                            <div className="col-lg-12 e-load-more-wrap pb-5">
                            <span className="e-s-btn" onClick={this.loadMoreClick.bind(this)}>Load More</span>
                        </div>
                          </div>
                        :
                          <div className="row">
                            <NoDataFound data="No vendors data found."/>
                          </div>
                      }
                    </div>
                 
                }
            </div>
        }
      </div>
    );
  }
}

export default PartneredVendors;
