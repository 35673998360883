/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Import custom components and assets*/
import SelectBox from '../../components/common/selectBox';
import InputBox from '../../components/common/inputBox';
import Textarea from '../../components/common/textarea';
import APIPost from '../../components/apiCall/apiPost';
import  ProductDetail from '../../container/detail/main';
import devconfig from '../../config/config.js';
import 'react-dates/initialize';
import { SingleDatePicker} from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import GetURLVar from '../../services/apiCall/getParams/get-url.js'; 

/*Assets*/
// import AddLogo from '../../assets/images/add-logo.svg';

import Close from '../../assets/images/close-btn.svg';



class EditFaq extends Component {
  constructor(props){
    super(props);
    this.state = {
      question: this.props.question,
      content: this.props.content,
    }
  }



  hideLogin() {
  $('.e-edit-faq').removeClass('e-login-fly');
  $('#e-log-overlay').remove();
  }
   
  EditFAQ=()=>{
    console.log(this.state.faq);
    var url = devconfig.adminV2+"/faq/edit";
    var data = JSON.stringify({
      "id": parseInt(this.props.itemid),
      "question": this.state.question,
      "answer" : this.state.content
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        this.props.confirm(response.message);
        this.hideLogin();
      }
      else{
        this.setState({error: response.message});
      }
    }.bind(this))
    
  }

  render() {

    return(

      <div className="container text-left e-edit-faq e-add-product-modal" id={this.props.id}>
        <div className="row">
          <div className="col-lg-12 pl-5 pr-5">
            <div className="row mb-3 e-modal-border">
              <div>
                <button className="e-login-closebtn">
                  <img src={Close} alt="close button" className="e-toggle" onClick={this.hideLogin.bind(this)}/>
                </button>
              </div>
              <div className="col-lg-4 pt-5 mt-3">
                <h5 className="">Edit FAQs</h5>
              </div>
              <div className="col-lg-7 pt-5 mt-3 ml-5">
               <span className="e-save e-p-btn float-right" data-toggle="modal" onClick={this.EditFAQ} data-target="#e-addProduct">Upadte FAQs</span>
              </div>
            </div>
            {
              this.state.error ?

              <div className="row pt-4">
                <div className="col-lg-6"> 
                  <div className="e-error mb-3">{this.state.error}</div>
                </div>
              </div>

              :
                null

            }
            <div className="row pt-3">
              
              <div className="col-lg-12">
                <label>EDIT FAQs</label>
              </div>
              
                      <div className="col-lg-7">
                        <InputBox 
                          value={this.state.question}
                          onChange={(e) => {
                          this.setState({question: e.target.value}) 
                        }} />
                      </div>
                      <div className="col-lg-7">
                        <Textarea 
                        value={this.state.content}
                        onChange={(e) => {
                          this.setState({content: e.target.value}) 
                        }} />
                      </div>
               
 
            </div>
          </div>

      </div>
    </div>

    )
  }
}

export default EditFaq;                 