import React from 'react';
import $ from 'jquery';
import ReactHtmlParser from 'react-html-parser';


// import services
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';
import devconfig from '../../config/config.js';


import GetURLVar from '../../services/apiCall/getParams/get-url.js';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, createFromBlockArray, convertFromHTML, ContentState } from 'draft-js';

// import components
import Links from '../../components/navigations/Links';
import EditArticle from '../../components/popups/EditArticle';
import Toast from '../../components/popups/Toast';
import DeleteModal from '../../components/popups/confirmationModal';


// import assets
import linkarrow from '../../assets/images/breadcrum.svg';
import Edit from '../../assets/images/resource/edit.svg';
import Delete from '../../assets/images/resource/delete.svg';
// import Facebook from '../../assets/images/footerIcons/facebook.svg';
// import Twitter from '../../assets/images/footerIcons/twitter.svg';
// import Linkedin from '../../assets/images/footerIcons/linkedin.svg';
// import Watsapp from '../../assets/images/footerIcons/watsapp.svg';
// import Copy from '../../assets/images/footerIcons/copy.svg';
// import Arrow from '../../assets/images/resource/breadcrum.svg';

 	$(document).ready(function() {
    $(window).scroll( function(){
      var scroll = $(window).scrollTop();
      // console.log('scrolltop =' + scroll);
      if (scroll > 680){
        $('.e-blog-content').addClass("e-blog-content-fixed");
    		$('.e-blog-about').addClass("e-blog-fixed-auth");
      }
      else{
        $('.e-blog-content').removeClass("e-blog-content-fixed");
    		$('.e-blog-about').removeClass("e-blog-fixed-auth");
      }
    });
  });

export default class BlogDetails extends React.Component{
	constructor(props){
		super(props);
		this.state={
			data: null,
			blogId: null,
		}
	}

	componentDidMount(){
		window.scrollTo({top: 0, behavior: 'smooth'});
		GetURLVar().then(function(query){
			this.getData(query.id);
			this.setState({blogId: query.id});
		}.bind(this));
	}

	Edit=()=> {
    var overlaylogin = '<div class="e-login-overlay" id="e-log-overlay"></div>';
    $("body").append(overlaylogin);
    $('.e-edit-blog').addClass('e-login-fly');

  }


  confirm(){
  	$('#e-confirmModal').modal('toggle');
  }
  DeleteBlog=()=>{
   var url = devconfig.adminV3+"/resources/delete-blog";
    var data = JSON.stringify({
      "blog_id" : parseInt(this.state.blogId),
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
         window.location.href = '/resources?tab=3';
      }
    }.bind(this))

  }
	getData(tempId){
		var url =  devconfig.adminV3+"/resources/blog-detail";
    var data = JSON.stringify({
      "id": parseInt(tempId)
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        this.setState({data:response.data, dataLoading: false});
        const blocksFromHtml = htmlToDraft(response.data.introduction);
		const { contentBlocks, entityMap } = blocksFromHtml;
		const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
		const editorState = EditorState.createWithContent(contentState);
		this.setState({introduction: editorState});
		console.log(editorState);
			}
      else{
        this.setState({ dataLoading: false});
      }
    }.bind(this))
	}
	 openToast=(e)=>{
   if (e === "Success"){

      this.setState({toast: e , toastType:"success"})
      setTimeout( function() { this.setState({ toast: null}); }.bind(this), 4000);
    this.getData(this.state.blogId);
    }


  }

	render(){
// 		const fixed = $("#e-scroll")
// 				fixed.on("scroll", function(e) {
//   		if (window.scrollTop >300) {

//     $('.e-blog-content').addClass("e-blog-content-fixed");
//     $('.e-blog-about').addClass("e-blog-fixed-auth");
//   } else {
//     $('.e-blog-content').removeClass("e-blog-content-fixed");
//     $('.e-blog-about').removeClass("e-blog-fixed-auth");
//   }
// });
		return(

			<React.Fragment>
			{
				this.state.data ?
				<React.Fragment>
				<div className="container-fluid e-blog-details-header no-gutters">
					<div className="container">
						<Links className="e-marketDetails-link e-blog-detail-link"  activeClassName="" name="Home" url="home"/>
						<img src={linkarrow} alt="arrow" className="mx-2"/>
						<Links className="e-marketDetails-link e-blog-detail-link"  activeClassName="" name="Resources" url="resources?tab=1"/>
						<img src={linkarrow} alt="arrow" className="mx-2"/>
						<Links className="e-marketDetails-link e-blog-detail-link"  activeClassName="" name="Blogs" url="resources?tab=3"/>
						<img src={linkarrow} alt="arrow" className="mx-2"/>
						<span className=" e-resource-dtls-head">{this.state.data.title}</span>
					</div>
					<div className="container">
						<div className="col-lg-8 col-md-12 col-sm-12 col-12 offset-lg-2 no-gutters mt-5 ">
							<h1 className="e-blogcover-h1">{this.state.data.title}</h1>
							<div className="row ">
							<div className="col-lg-7 col-md-6 col-sm-6 col-12 text-left  ">
								{
											this.state.data.products && this.state.data.products.map((item, index) =>{
												return(
													<span className="e-blogdtls-span">{item.name}</span>
												);
											})
										}
							</div>
							<div className="col-lg-5 col-md-5 col-sm-5 col-12 text-lg-right text-md-right text-sm-right">
								<p className="e-blogdtls-time">{this.state.data.published_on}</p>
							</div>
						</div>
						</div>

						<div className="col-lg-8 position-relative e-blog-thump offset-lg-2 no-gutters ">
							<img src={this.state.data.image} alt="cover image" className="e-blogcover-img"/>
							<button className="e-blog-edit" onClick={this.Edit}><img src={Edit}/></button>
							<button className="e-blog-delete" onClick={this.confirm}><img src={Delete}/></button>
						</div>
					</div>
				</div>
				<div className="container " id="e-scroll" >
					<div className="row mt-5 ">
						<div className="col-lg-2 e-blog-content ">
							<h6 className="pb-3">Contents</h6>
							{
								this.state.data && this.state.data.sections ?
								this.state.data.sections.map((item, index) =>{
									return(
										<p><a href={"#"+ item.id} className="e-blogdtls-p">{item.content}</a></p>
									);
								})
								:
								null
							}



						</div>

						<div className="col-lg-8 e-blog-scroll">
							<p className="e-blogdtls-decs">{ReactHtmlParser(this.state.data.introduction)}

							</p>
							{
								this.state.data && this.state.data.sections ?
								this.state.data.sections.map((item, index) =>{
									return(
										<React.Fragment>
										<h5 className="e-blog-author" id={item.id}>{item.content}</h5>
										<p className="e-blogdtls-des" >{ReactHtmlParser(item.description)}</p>
										<hr/>
										</React.Fragment>
									);
								})
								:
								null
							}
						</div>
						<div className="col-lg-2 e-blog-about ">
						<img src={this.state.data.author_image} alt="author img" className="e-blog-author-img"/>
						<h5 className="e-blog-author">{this.state.data.author_name}</h5>
						<p className="e-blogdtls-p pt-3">{this.state.data.author_bio}</p>
						<h5 className="e-blog-author mt-5">Completed</h5>
						<progress className="e-blog-complete" id="rating"  value={this.state.data.completed}  max="100"></progress>
						<p className="e-blogdtls-percentage">{this.state.data.completed} %</p>
						{/*<h5 className="e-blog-author mt-5">Share Via</h5>
						<p className="">
							<a className="mr-3 e-ftricon-link" href="#" target="blank"><img src={Facebook} alt="icon" className="e-footer-icon"/></a>
							<a className="mr-3 e-ftricon-link" href="#" target="blank"><img src={Twitter} alt="icon" className="e-footer-icon"/></a>
							<a className="mr-3 e-ftricon-link" href="#" target="blank"><img src={Linkedin} alt="icon" className="e-footer-icon"/></a>
							<a className="mr-3 e-ftricon-link" href="#" target="blank"><img src={Watsapp} alt="icon" className="e-footer-icon"/></a>

						</p>*/}

					</div>
					{
		      this.state.toast ?
		        <Toast data={this.state.toast} type={this.state.toastType}/>
		     :
		        null
        }
				</div>
				<EditArticle exData={this.state.data} triggerToast={(tempVal)=>{this.openToast(tempVal)}}/>
				<DeleteModal modalTitle="Delete " modalContent="Do you want to remove the blog?" confirmSuccess={() => this.DeleteBlog()}/>
			</div>
			</React.Fragment>
			:
			null
		}

			</React.Fragment>
		)
	}
}