/*Package importing*/
import React, { Component } from 'react';

/*Assets and Components*/
import APIGet from '../../components/apiCall/apiGet';
import devconfig from '../../config/config.js';
import NoDataFound from '../../components/common/noData';
import RequestCard from '../../components/card/requestCard';
import PlaceholderStyleOneLarge from '../../components/placeholders/placeholderCol1Large';

class VendorRequest extends Component {
  constructor(props){
    super(props);
    this.state = {
      dataList: [],
      dataLoading: true
    }
  }

  componentWillMount() {
    this.listRequest();
  }

  listRequest(){
    this.setState({dataLoading: true});
    var url = devconfig.adminV2+"/vendor/requests";

    APIGet(url).then(function(response){
      if(response.status){
        this.setState({dataList: response.data, dataLoading: false});
      }
    }.bind(this))
  }

  actionSuccess(){
    this.listRequest();
  }

  render() {
    if(!this.state.dataLoading){
      return (
        <div className="col-lg-10">

            {
              this.state.dataList.length ?
                <div className=" e-request-item-wrap e-partnered-wrap">
                  <h2 className="mb-4">Pending Requests({this.state.dataList.length})</h2>
                  <div className="row">
                    {
                      this.state.dataList.map(function(item, index){

                        return(
                          <RequestCard data={item} key={index} actionSuccess={this.actionSuccess.bind(this)}/>
                        )
                      }.bind(this))
                    }
                  </div>
                </div>
              :
                <div className="row">
                  <div className="col-lg-12 text-center pb-5">
                    <NoDataFound data="Didn't receive any new request."/>
                  </div>
                </div>
            }
        </div>
      );
    }
    else{
      return (
        null
      );
    }

  }
}

export default VendorRequest;
