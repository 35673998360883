

import React from 'react';
import $ from 'jquery';

// import service
import APIPost from '../../components/apiCall/apiPost';
import APIGet from '../../components/apiCall/apiGet';
import devconfig from '../../config/config.js';
// import components
import InputBox from '../../components/common/inputBox';
import Textarea from '../../components/common/textarea';
import PrimaryBtn from '../../components/buttons/primaryBlueButton';

// import assets
import Close from '../../assets/images/close-btn.svg';


export default class bankForm extends React.Component{
	constructor(props){
		super(props);
		this.state={
			ac_number: this.props.data.account_no,
			branch: this.props.data.branch,
			code: this.props.data.ifsc_code,
			b_name: this.props.data.bank,
			vendor: this.props.id,
		}
	}

	 hideLogin=()=> {
  $('.e-edit-bank').removeClass('e-login-fly');
  $('#e-log-overlay').remove();
  }
  updateBank =() =>{
  	var url = devconfig.adminV3+"/vendor/update-bank-info";
    var data = JSON.stringify({
      "vendor_id" : this.props.id,
      "account_no": this.state.ac_number,
      "ifsc_code" : this.state.code,
      "branch" : this.state.branch,
      "bank": this.state.b_name
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        this.props.confirmSuccess(response.message)

			}
      else{
        this.setState({ dataLoading: false});
      }
    }.bind(this))
    this.hideLogin();
	}

	render(){
		return(
			<div className="e-edit-bank container">
				<div className="col-lg-12">
				<form >
					<div className="row p-3">
						<button className="e-bank-closebtn mt-5">
              <img src={Close} alt="close button" className="e-toggle" onClick={this.hideLogin.bind(this)}/>
           	</button>
           </div>
           <div className="col-lg-12 p-3">
					<h2 className=" mt-5 e-bank-form-h">Enter your Bank Details</h2>
					<p className="e-bank-p">Ensure you are entering the correct bank details</p>
					<InputBox placeholder="Bank Name" value={this.state.b_name} 
					onChange={(e)=>{this.setState({b_name: e.target.value})}}
					/>
					<InputBox placeholder="Account Number" value={this.state.ac_number} 
					onChange={(e)=>{this.setState({ac_number: e.target.value})}}
					/>
					<InputBox placeholder="Re-enter Account Number" value={this.state.ac_number1} 
					onChange={(e)=>{this.setState({ac_number1: e.target.value})}}
					/>
					<InputBox placeholder="IFSC Code" value={this.state.code} 
					onChange={(e)=>{this.setState({code: e.target.value})}}
					/>
					{
						this.state.b_name &&
						this.state.code &&
						this.state.ac_number && 
						this.state.ac_number1 ?
						<PrimaryBtn name="Save Bank Details" handleClick={this.updateBank}/>
						:
						<PrimaryBtn name="Save Bank Details" className="e-block" disabled={true} handleClick={this.updateBank}/>
					}
					
					</div>
				</form>
				</div>
			</div>
			)
	}
}

