/*Package importing*/
import React, { Component } from 'react';

class PlaceholderStyleTwo extends Component {
  
  render() {
    return (
      <div className="row">
        <div className="col-lg-6 e-card1-timeline-wrapper">
          <div className="e-card1-timeline-item">
              <div className="e-card1-animated-background">
                  <div className="background-masker header-top"></div>
                  <div className="background-masker header-left"></div>
                  <div className="background-masker header-right"></div>
                  <div className="background-masker header-bottom"></div>
                  <div className="background-masker subheader-left"></div>
                  <div className="background-masker subheader-right"></div>
                  <div className="background-masker subheader-bottom"></div>
                  <div className="background-masker content-top"></div>
                  <div className="background-masker content-first-end"></div>
                  <div className="background-masker content-second-line"></div>
                  <div className="background-masker content-second-end"></div>
                  <div className="background-masker content-third-line"></div>
                  <div className="background-masker content-third-end"></div>
              </div>
          </div>
      </div>
      <div className="col-lg-6 e-card1-timeline-wrapper">
          <div className="e-card1-timeline-item">
              <div className="e-card1-animated-background">
                  <div className="background-masker header-top"></div>
                  <div className="background-masker header-left"></div>
                  <div className="background-masker header-right"></div>
                  <div className="background-masker header-bottom"></div>
                  <div className="background-masker subheader-left"></div>
                  <div className="background-masker subheader-right"></div>
                  <div className="background-masker subheader-bottom"></div>
                  <div className="background-masker content-top"></div>
                  <div className="background-masker content-first-end"></div>
                  <div className="background-masker content-second-line"></div>
                  <div className="background-masker content-second-end"></div>
                  <div className="background-masker content-third-line"></div>
                  <div className="background-masker content-third-end"></div>
              </div>
          </div>
      </div>
    </div>
    ); 
  } 
}

export default PlaceholderStyleTwo;
