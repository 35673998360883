/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Assets and Components*/
import InputBox from '../../components/common/inputBox';
import EmailValidation from '../../components/validation/email';
import PhoneValidation from '../../components/validation/phone';
import APIPost from '../../components/apiCall/apiPost';
import APIGet from '../../components/apiCall/apiGet';
import devconfig from '../../config/config.js';
import Toast from '../../components/popups/Toast';
import ConfirmationModal from '../../components/popups/confirmationModal';
import UpdatePending from '../../components/popups/updatePending';

class AddVendor extends Component {
  constructor(props){
    super(props);
    this.state = {
      vName: "",
      vEmail: "",
      vPhone: "",
      errorMessage: "",
      btnClicked: false,
      resetInput: true,
      dataList: []
    }
  }

  componentWillMount() {
    this.listInvitation();
  }

  listInvitation(){
    var url = devconfig.adminV2+"/vendor/signup-pending";

    APIGet(url).then(function(response){
      if(response.status){
        this.setState({dataList: response.data});
      }
    }.bind(this))
  }

  async inviteVendor(){
    var validationSuccess = true;

    await EmailValidation(this.state.vEmail).then(function(response){
      if(!response){
        this.setState({errorMessage: "Invalid email address, please check and retry."});
        validationSuccess = false;
      }
    }.bind(this))

    await PhoneValidation(this.state.vPhone).then(function(response){
      if(!response){
        this.setState({errorMessage: "Invalid phone number, please check and retry."});
        validationSuccess = false;
      }
    }.bind(this))

    if(validationSuccess){
      this.setState({btnClicked: true});
      var url = devconfig.adminV2+"/vendor/invite";
      var data = JSON.stringify({
        "name": this.state.vName,
        "email": this.state.vEmail,
        "mobile": parseInt(this.state.vPhone)
      })

      APIPost(url, data).then(function(response){
        if(response.status){
          this.setState({resetInput: false});
          this.clearStates();
          this.listInvitation();

        }
        else{
          this.setState({errorMessage: "response.message", btnClicked: false});

        }
        this.triggerToast(response.message, response.status)
      }.bind(this))
    }
  }
  triggerToast = (temp, type) =>{
    if(type == "ok"){
      this.setState({ toastType:"success"})
    }else{
      this.setState({ toastType:"fail"})
    }
    this.setState({toast: temp })
      setTimeout( function() { this.setState({ toast: null}); }.bind(this), 4000);
  }
  clearStates(){
    this.setState({
      vName: "",
      vEmail: "",
      vPhone: "",
      errorMessage: "",
      btnClicked: false,
      resetInput: true
    });
  }

  resendClick(tempId,tempSelector, e){

    $(tempSelector).html("Sending")
    $(tempSelector).addClass("e-sending")

    var url = devconfig.adminV2+"/vendor/resent-invite";
    var data = JSON.stringify({
      "vendor_id" : tempId
    })

    APIPost(url, data).then(function(response){
      if(response.status){
        $(tempSelector).html("Success")
        $(tempSelector).addClass("e-success")
      }
      else{
        $(tempSelector).html("Failed")
        $(tempSelector).addClass("e-failed")
      }
    }.bind(this))
  }
  deletModal(e){
    this.setState({deleteId: e})
   $('#e-confirmModal').modal('toggle');
 }
  deletePending=()=>{
    var url = devconfig.adminV3+"/vendor/delete-pending-vendor";
    var data = JSON.stringify({
      "vendor_id": parseInt(this.state.deleteId)
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        this.setState({toast: response.message, toastType:"success" })
        setTimeout( function() { this.setState({ toast: null}); }.bind(this), 4000);
         this.listInvitation();
       }
      else{
        this.setState({toast: response.message, toastType:"fail" })
        setTimeout( function() { this.setState({ toast: null}); }.bind(this), 4000);
        this.setState({ dataLoading: false});
      }
    }.bind(this))
  }
  confirmUpdate = () =>{
    this.setState({toast: 'Invitation Updated!', toastType:"success" })
    setTimeout( function() { this.setState({ toast: null}); }.bind(this), 4000);
    this.listInvitation();
  }

  render() {
    return (
      <div className="col-lg-8">
        <div className=" mb-5 e-partnered-wrap">
          <h2 className="mb-4">Add New Vendor</h2>
          <div className="row">
            <div className="col-lg-6">
              {
                this.state.resetInput ?
                  <InputBox placeholder="Vendor Name" id="v-name" type="text" value={this.state.vName} onChange={(e) => this.setState({vName: e.target.value, errorMessage: ""})}/>
                :
                  null
              }
            </div>
            <div className="col-lg-6">
              {
                this.state.resetInput ?
                  <InputBox placeholder="Email Address" id="v-email" type="text" value={this.state.vEmail} onChange={(e) => this.setState({vEmail: e.target.value, errorMessage: ""})}/>
                :
                  null
              }
            </div>
            <div className="col-lg-6">
              {
                this.state.resetInput ?
                  <InputBox  placeholder="Phone Number" id="v-phone" type="text" value={this.state.vPhone} onChange={(e) => this.setState({vPhone: e.target.value, errorMessage: ""})}/>
                :
                  null
              }
            </div>
            {
              this.state.errorMessage.length ?
                <div className="col-lg-12">
                  <div className="mt-1 mb-3  text-danger">
                    {this.state.errorMessage}
                  </div>
                </div>
              :
                null
            }
          </div>
          {
            this.state.vName.length && this.state.vEmail.length && this.state.vPhone.length ?
              <span className="e-add-vendor e-p-btn" onClick={this.inviteVendor.bind(this)}>Add Vendor</span>
            :
              <span className="e-add-vendor e-p-btn e-inActive">Add Vendor</span>
          }
        </div>

        {
          this.state.dataList.length ?
            <div className="e-partnered-wrap">
              <h2 className="mb-4">Pending Acceptance</h2>
              <div className="row">
                <div className="col-lg-12">
                  <table id="example" className="table e-subscription-table-wrap table-striped">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Action</th>
                        <th>Edit Pending</th>
                        <th>Delete Pending</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        this.state.dataList.map(function(item, index){
                          var spanClass = "e-s-btn e-resend-"+item.id;
                          var spanSelector = ".e-resend-"+item.id;
                          return(
                            <tr key={index}>
                              <td className="e-vendor-names">{item.name}</td>
                              <td>{item.email}</td>
                              <td>{item.mobile}</td>
                              <td>
                                <span className={spanClass} onClick={this.resendClick.bind(this, item.id, spanSelector)}>Resend</span>
                              </td>
                              <td>
                                <span className="e-s-btn"  onClick={ async ()=>{ await this.setState({ resentData: {name: item.name, number: item.mobile, id: item.id} }); await $('#updatePending').modal('show'); }}>Edit</span>
                              </td>
                              <td>
                                <span className="e-s-btn" onClick={this.deletModal.bind(this, item.id)}>Delete</span>
                              </td>
                            </tr>
                          )
                        }.bind(this))
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          :
            null
        }
         {
              this.state.toast ?
                <Toast data={this.state.toast} type={this.state.toastType}/>
              :
              null
             }
         <ConfirmationModal modalTitle="Confirm Delete" modalContent="Are you sure want to delete Request" confirmSuccess={()=>{this.deletePending()}}/>
         <UpdatePending  data={this.state.resentData}confirm={()=> this.confirmUpdate()}/>
      </div>
    );
  }
}

export default AddVendor;
