/*Import Packages*/
import React from 'react';
import $ from 'jquery';
import { useHistory } from 'react-router-dom';


/*Import components*/
import AddVideos from '../../components/popups/addVideo';
import ConfirmationModal from '../../components/popups/confirmationModal';
import Modal from '../../components/modal/VideoModal';
import Resources from '../../container/resource/main.js';


// import assets
import videothumb2 from '../../assets/images/resource/videothumb2.svg';
import videothumb from '../../assets/images/resource/videothumb1.svg';
import playbtn from '../../assets/images/slider/carbon_play-outline.svg';
import VideoAdd from '../../assets/images/video-icon.svg';


function VideoListing(props) {

  let history = useHistory();
  function RouteChange() {
    let path = `resourceVideo?id=`+ props.data.id +`&product=` +props.data.title;
    history.push(path);
  }

  
   
    return(
     
      <div className="col-lg-4  mt-4">
      {
      props.data ?
      <div className="card border-0 e-videolist-card e-card-hover e-video-card"onClick={RouteChange} >
        <div className="card-image e-video-card-headersec">
          <img className="e-videolist-thumb"   id="video-cover" src={props.data.thumbnail} alt="Video title"/>
          <span className="e-vidoe-duration"> {props.data.duration}</span>
        </div>
        <div className="card-content p-3">
          <h5 className="e-video-card-head">{props.data.title}</h5>
          {
            props.data && props.data.products.map((item , key)=>{
              return(
                <span className="mt-3 mr-2 e-vidoe-list-span">{item.name}</span>  
              )
            })
          }
                               
        </div>
      </div>
      :
      null
          }
          <ConfirmationModal/>
    </div>      
        



      );
}

export default VideoListing;
