import React from 'react';
import $ from 'jquery';
// import services
import APIPost from '../../components/apiCall/apiPost';
import APIGet from '../../components/apiCall/apiGet';
import devconfig from '../../config/config.js';

//import components
import Input from '../../components/common/inputBox';
import Text from '../../components/common/textarea';
// import Select from '../../components/common/selectBox';
import Toast from '../../components/popups/Toast';
import Select from 'react-select';
import UpdatePopUp from '../../components/popups/updatePopUp';
import AddPopUp from '../../components/popups/addPopUp';
import NoDataFound from '../../components/common/noData';
import ApiLoader from '../../components/Loaders/ApiLoader';

export default class Notifications extends React.Component{

	constructor(props){
		super(props);
		this.state={
			tabIndex: 1,
      errorMessage: ""
		}
	}

  componentDidMount(){
    this.getNotification();
  }

  getNotification=()=>{
    this.setState({loading: true})
    var url = devconfig.adminV3+"/notification/list";
    APIGet(url).then(function(response){
      console.log(response);
      if(response.status === "ok"){
         this.setState({data: response.data, loading: false});

      }else{
        this.setState({loading: false});
      }
    }.bind(this))

  }
  getProduct=()=>{
    var url = devconfig.adminV3+"/products/product-names";
    APIGet(url).then(function(response){
      console.log(response);
      if(response.status === "ok"){
          var tempArry = [];
        tempArry.push({ value: "undefined", label: 'All' });
        response.data.map(function(item, index){
          var item = {value: item.id, label:item.name};
          tempArry.push(item);
          // tempModalArry.push(item);
        });
        this.setState({options: tempArry});

      }
    }.bind(this))

  }
  getPopUp=()=>{
    this.setState({loading: true});
    var url = devconfig.adminV3+"/notification/get-banner";
    APIGet(url).then(function(response){
      if(response.status){
        this.setState({popUpData: response.data, loading: false});
      }else{
        this.setState({loading: false});
      }
    }.bind(this));
  }
  tabChange=(e)=>{
    this.setState({tabIndex: e});
    if(e == 2){
      this.getProduct();
    }
    if(e == 3){
      this.getPopUp();
    }
  }
  productChange(tempData){
   alert(tempData.value)
    this.setState({ selectedProduct: tempData.value});

  }

  sendNotification=(e)=>{
    e.preventDefault();
    var reg = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
    if(!reg.test(this.state.link)){
      this.setState({errorMessage: 'Not a valid url'})
    }
    else{
      var url = devconfig.adminV3+"/notification/sent-all";
      var data = JSON.stringify({
        "title": this.state.title,
        "message": this.state.message,
        "product_id" : parseInt(this.state.selectedProduct.value),
        "other_url" : this.state.link
    })
    APIPost(url, data).then(function (response) {
       if(response.status){
          this.triggerToast('Notification send successfully');
          this.setState({title: "", message: " ", link: "", selectedProduct: null});

        }

      else{
      }
    }.bind(this))
    }
  }
  reloadPops=()=>{
    this.setState({toast: "Updated Successfully" , toastType:"success"})
    setTimeout( function() { this.setState({ toast: null}); }.bind(this), 4000);
    this.getPopUp();
  }
  triggerToast=(e)=>{
    this.setState({toast: e , toastType:"success"})
    setTimeout( function() { this.setState({ toast: null}); }.bind(this), 4000);
  }

  openEdit=()=>{
    var overlaylogin = '<div class="e-login-overlay1" id="e-log-overlay1"></div>';
    $("body").append(overlaylogin);
    this.setState({modalClass: 'e-login-fly'});
  }

  modalClose=()=>{
    this.setState({modalClass: false});
  }

	render(){
		return(
			<div className="container">
				<div className="row">
          <div className="col-lg-12 mt-4 e-Product-tab-link-wrap">
            {
              this.state.tabIndex === 1 ?
                <span className="e-tabs-item e-active pr-4">Notifications</span>
              :
                <span onClick={()=>{this.tabChange(1)}} className="e-color-inactive pr-4">Notifications</span>
            }

            {
              this.state.tabIndex === 2 ?
                <span className="e-tabs-item e-active pr-4">Send Push Notifications</span>
              :
                <span onClick={()=>{this.tabChange(2)}} className="e-color-inactive pr-4">Send Push Notifications</span>
            }

            {
              this.state.tabIndex === 3 ?
                <span className="e-tabs-item e-active pr-4">Home screen promotion banner</span>
              :
                <span onClick={()=>{this.tabChange(3)}} className="e-color-inactive">Home screen promotion banner</span>
            }
          </div>

        </div>
        {
        	this.state.tabIndex==1?
        	<div className="row">
          {
            this.state.data ?
            this.state.data.map((item, index)=>{
              return(
                <div className="col-lg-12 mt-4" key={index}>
                  <div className="card border-0 e-profile-card pt-4">
                    <div className="row">
                      <div className="col-lg-10">
                        <h6 className="mb-3">{item.product_name}: Edited by {item.vendor_name}, Edited On : {item.created}</h6>
                        <span className="font-weight-bold">Edited elements: </span>
                        {
                          item.edited_history.map((item, index)=>{
                            return(
                              <React.Fragment key={index}>

                              <p className="">
                              <b>Edited Field: </b>{item.field}<br/>
                              <b>Edited Text: </b>{item.edited_text}<br/>
                              <b>New value: </b>{item.new_value}<br/>
                              <b>Old value: </b>{item.old_value}<br/>
                              </p>
                              </React.Fragment>
                            )
                          })

                        }

                      </div>
                      <div className="col-lg-2 text-right mt-3 ">
                        <button className="e-p-btn " onClick={()=>{window.location.href = '/product-details?id='+item.product_id}}>View Product</button>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
        		:
            null
          }
          </div>
          :
          null
        }
        	
          <React.Fragment>
        	{
            this.state.tabIndex ===2 ?
            <div className="row">
        	<form className="col-lg-6 e-custom-notifiction-form">
        		<div className="mt-4">
        			<Input value={this.state.title} onChange={(e)=>{this.setState({title: e.target.value})}} placeholder="Title"/>
        		</div>
        		<div className="mt-4">
        			<Text value={this.state.message} onChange={(e)=>{this.setState({message: e.target.value})}} placeholder="Message"/>
        		</div>
        		<div className="mt-4">
        			<Select
        isSearchable={false}
        options={this.state.options}
        value={this.state.selectedProduct}
        onChange={(e)=>{this.setState({selectedProduct: e})}}
        placeholder="Select the product (optional)"/>
        		</div>
            <div className="mt-4">
              <Input value={this.state.link} onChange={(e)=>{this.setState({link: e.target.value, errorMessage: ''})}} placeholder="Link"/>
            </div>
            {
              this.state.errorMessage.length?
              <div className="col-lg-12  e-p-left-0">
                <p className="e-f-weight-500 pt-4 e-c-red">{this.state.errorMessage}</p>
              </div>
            :
            null
             }
        		<div className="mt-4">
        			<button className="e-p-btn" onClick={this.sendNotification}>Send Notifications</button>
        		</div>

        	</form>
          </div>
          :
          null
        }
        </React.Fragment>
        <React.Fragment>
        {
          this.state.loading ?
          <div className="col-lg-12">
            <ApiLoader/>
          </div>
          :
          null
        }
        {
          this.state.tabIndex === 3 && this.state.popUpData && this.state.popUpData.id && !this.state.loading ?
          <React.Fragment>
          <div className="row">
            <div className="col-lg-6 mt-4">
              <React.Fragment>
              {
                this.state.popUpData && this.state.popUpData.image ?
                  <span className="e-img-preview">
                    <img src={this.state.popUpData.image}/>
                  </span>
                  :
                null
              }
              </React.Fragment>
                </div>
                </div>
                <div className="row">
                  {
                    this.state.popUpData.redirection_url ?
                    <div className="col-lg-6 mt-4">
                      <Input value={this.state.popUpData.redirection_url} readOnly={true}/>
                    </div>
                  :
                    <div className="col-lg-6 ">
                      <p className="e-nodata-input mt-4">No url found</p>
                    </div>
                  }
                </div>
                <div className="mt-4">
              <button className="e-p-btn" onClick={this.openEdit}>Edit promotion banner</button>
            </div>
            <UpdatePopUp modalClass={this.state.modalClass} data={this.state.popUpData} reload={()=>{this.reloadPops()}} modalClose={this.modalClose.bind(this)}/>
          </React.Fragment>

          :
          this.state.tabIndex === 3 && this.state.popUpData && this.state.popUpData.id == null && !this.state.loading ?
          <div className="col-lg-12 text-center mt-2">
            <NoDataFound data="No promotion banner found!!"/>
            <button className="e-p-btn mt-3" onClick={this.openEdit}>Add new promotion banner</button>
          </div>
          :
          null
        }
        </React.Fragment>
        {
          this.state.toast ?
            <Toast data={this.state.toast} type={this.state.toastType}/>
         :
            null
        }
        {
          this.state.tabIndex === 3 && this.state.popUpData && this.state.popUpData.id == null ?
          <AddPopUp modalClass={this.state.modalClass} reload={()=>{this.reloadPops()}} modalClose={this.modalClose.bind(this)}/>
          :
          null
        }
        
			</div>
		);
	}
}