/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Components*/
import InputBox from '../../components/common/inputBox';
import EmailValidation from '../../components/validation/email';
import PhoneValidation from '../../components/validation/phone';
import APIPost from '../../components/apiCall/apiPost';
import APIGet from '../../components/apiCall/apiGet';
import devconfig from '../../config/config.js';
import PayNowModal from '../../components/popups/payNowModal';
import Toast from '../../components/popups/Toast';
import NoData from '../../components/common/noData';
// assets
import Download from '../../assets/images/download-icon.svg';

class Payouts extends Component {
  constructor(props){
    super(props);
    this.state = {
      vName: "",
      vEmail: "",
      vPhone: "",
      errorMessage: "",
      btnClicked: false,
      resetInput: true,
      dataList: []
    }
  }

  componentWillMount() {
    this.listPayout();
  }

  listPayout(){
    var url = devconfig.adminV3+"/vendor/payout-list";

    APIGet(url).then(function(response){
      if(response.status){
        this.setState({dataList: response.data});
      }
    }.bind(this))
  }
  openTrigger=(temp)=>{
    this.setState({toast: temp , toastType:"success"})
    setTimeout( function() { this.setState({ toast: null}); }.bind(this), 4000);
    this.listPayout();
  }

  downloadPayout=()=>{
    var url=devconfig.adminV3+"/vendor/download-payout-list";

    APIGet(url).then(function(response) {
      if(response.status){
        this.setState({downloadList: response.file_url});
        window.open(response.file_url)
      }
    }.bind(this))
  }

  // async inviteVendor(){
  //   var validationSuccess = true;

  //   await EmailValidation(this.state.vEmail).then(function(response){
  //     if(!response){
  //       this.setState({errorMessage: "Invalid email address, please check and retry."});
  //       validationSuccess = false;
  //     }
  //   }.bind(this))

  //   await PhoneValidation(this.state.vPhone).then(function(response){
  //     if(!response){
  //       this.setState({errorMessage: "Invalid phone number, please check and retry."});
  //       validationSuccess = false;
  //     }
  //   }.bind(this))

  //   if(validationSuccess){
  //     this.setState({btnClicked: true});
  //     var url = devconfig.adminV2+"/vendor/invite";
  //     var data = JSON.stringify({
  //       "name": this.state.vName,
  //       "email": this.state.vEmail,
  //       "mobile": parseInt(this.state.vPhone)
  //     })

  //     APIPost(url, data).then(function(response){
  //       if(response.status){
  //         this.setState({resetInput: false});
  //         this.clearStates();
  //         this.listInvitation();
  //       }
  //       else{
  //         this.setState({errorMessage: response.message, btnClicked: false});
  //       }
  //     }.bind(this))
  //   }
  // }

  // clearStates(){
  //   this.setState({
  //     vName: "",
  //     vEmail: "",
  //     vPhone: "",
  //     errorMessage: "",
  //     btnClicked: false,
  //     resetInput: true
  //   });
  // }

  // resendClick(tempId,tempSelector, e){

  //   $(tempSelector).html("Sending")
  //   $(tempSelector).addClass("e-sending")

  //   var url = devconfig.adminV2+"/vendor/resent-invite";
  //   var data = JSON.stringify({
  //     "vendor_id" : tempId
  //   })

  //   APIPost(url, data).then(function(response){
  //     if(response.status){
  //       $(tempSelector).html("Success")
  //       $(tempSelector).addClass("e-success")
  //     }
  //     else{
  //       $(tempSelector).html("Failed")
  //       $(tempSelector).addClass("e-failed")
  //     }
  //   }.bind(this))
  // }

  // payNow=()=>{
  //  var url = devconfig.adminV3+"/vendor/payout";
  //   var data = JSON.stringify({
  //     "payout_id" : parseInt(this.state.id),
  //     "amount" : 
  //   })
  //   APIPost(url, data).then(function(response){
  //     if(response.status === "ok"){
  //        window.location.href = '/resources?tab=2';
  //     }
  //   }.bind(this))
    
  // }

  render() {
    return (
      <div className="col-lg-10">

        {
          this.state.dataList ?
            <div className="e-partnered-wrap">
              <div className="row ">
                <div className="col-lg-6">
                <h2 className="mb-4">Payouts</h2>
                </div>
                <div className="col-lg-6 text-right">
                <a className="e-download-link mr-5" onClick={this.downloadPayout}  target="_blank">Download CSV</a>
                </div>
              </div>
              
              <div className="row">
                <div className="col-lg-12">
                  <table id="example" className="table e-subscription-table-wrap table-striped e-payout-table">
                    <thead>
                      <tr>
                        <th>Vendor name</th>
                        <th>Last payment on</th>
                        <th>Total amount purchased</th>
                        <th>Aliceblue commission</th>
                        <th>Amount to pay</th>
                        <th>Pending amount</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        this.state.dataList.map(function(item, index){
                          var spanClass = "e-s-btn e-resend-"+item.id;
                          var spanSelector = ".e-resend-"+item.id;
                          return(
                            <tr key={index} >
                              <td onClick={()=> window.location.href="/ledger?id="+item.id}>{item.user_name}</td>
                              <td onClick={()=> window.location.href="/ledger?id="+item.id}>{item.last_payment ? item.last_payment : '--'}</td>
                              <td onClick={()=> window.location.href="/ledger?id="+item.id}> {item.total_amount_purchased ? '₹ '+ item.total_amount_purchased :'--'}</td>
                              <td onClick={()=> window.location.href="/ledger?id="+item.id}>{item.ab_commission ? '₹ ' + item.ab_commission : '--' }</td>
                              <td onClick={()=> window.location.href="/ledger?id="+item.id}> {item.total_vendor_commission ? '₹ '+item.total_vendor_commission : '--' }</td>
                              <td onClick={()=> window.location.href="/ledger?id="+item.id}>₹ {item.balance}</td>
                              <td>
                                <span className="e-payout-btn e-p-btn d-inline-block" onClick={()=>{this.setState({id: item.id})}} data-toggle="modal" data-target="#vendorpayout">Pay now</span>
                              </td>
                              
                            </tr>

                          )
                        }.bind(this))

                      }
                    </tbody>
                  </table>
                </div>
              </div>
              {
          this.state.toast ?
            <Toast data={this.state.toast} type={this.state.toastType}/>
         :
            null
        }
            </div>
          :
            <NoData data="No Pay Out Found"/>
        }
        <PayNowModal confirmSuccess={(temp)=>{this.openTrigger(temp)}} data={this.state.id} id="vendorpayout" />
      </div>
    );
  }
}

export default Payouts;
