/*Import Packages*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';

/*Import pages*/
import VideoListing from '../../container/resource/videoListing.js';
import LiveWebinar from '../../container/resource/liveWebinar.js';
import WebinarReplay from '../../container/resource/webinarReplays.js';
import BlogListing from '../../container/resource/blogListing.js';
import ReportListing from '../../container/resource/reportListing.js';


/*Import components*/
import AddVideos from '../../components/popups/addVideo';
import AddWebinar from '../../components/popups/addWebinar';
import AddArticle from '../../components/popups/addArticle';
import AddReport from '../../components/popups/addReport';

import ConfirmationModal from '../../components/popups/confirmationModal';
import SelectBox from '../../components/common/selectBox';
import Modal from '../../components/modal/VideoModal';
import Toast from '../../components/popups/Toast';
import Pagination from '../../components/pagination/pagination';

import OverlayLoader from '../../components/Loaders/OverlayLoader';
import NothingFound from '../../components/common/noData';
import ApiLoader from '../../components/Loaders/ApiLoader';

import APIPost from '../../components/apiCall/apiPost';
import APIGet from '../../components/apiCall/apiGet';
import GetURLVar from '../../services/apiCall/getParams/get-url.js';
// import services
import devconfig from '../../config/config.js';

// import assets
import videothumb2 from '../../assets/images/resource/videothumb2.svg';
import videothumb from '../../assets/images/resource/videothumb1.svg';
import playbtn from '../../assets/images/slider/carbon_play-outline.svg';
import VideoAdd from '../../assets/images/video-icon.svg';
import ArticleAdd from '../../assets/images/article.svg';


$(function() {
  $('.e-tablinks').click(function() {
    $( ".e-tabbtn-active" ).removeClass('e-tabbtn-active');
    addActive(this);
  });
});
  function addActive(element) {
    $(element).addClass('e-tabbtn-active');
  }


class Resources extends Component {

  constructor(props){
    super(props);
    this.state = {
      tabIndex:1,
      isOpen: false,
      type: 1,
      activeItem: "",
      productsList: null,
      pageNum: 1,
      videoList: null,
      webinarProduct: null,
      videoLoading: true,
      webinarLoading: true,
      blogLoading: true,
      reportsLoading: true,

    }
  }



  componentDidMount() {
    this.getProduct();
    this.getVideo();
    GetURLVar().then(function(query){
      this.tabItemClick(parseInt(query.tab))
    }.bind(this));

  }

  getProduct =  ()=>{
    this.setState({FilterLoading: true});
    var url = devconfig.adminV3+"/resources/product-names";
    APIGet(url).then(async function(response){
      console.log(response);
      if(response.status === "ok"){
        await this.setState({productsList: response.data, FilterLoading: false});
        await console.log(this.state.productsList)
      }
      else{
        this.setState({FilterLoading: false});
      }
    }.bind(this))
  }
  getVideo=(e)=>{

    var url = devconfig.adminV3+"/resources/list-video";
    var data = JSON.stringify({
      "page_num": parseInt(this.state.pageNum),
      "product_id": parseInt(e),
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
         this.setState({videoList: response.data, videoLoading: false, filterLoading: false});
      }
      else{
        this.setState({videoLoading: false, filterLoading: false});
      }
    }.bind(this))
  }

   getWebinar=(e)=>{
    var url = devconfig.adminV3+"/resources/list-webinar";
    var data = JSON.stringify({
      "page_num": parseInt(this.state.pageNum),
      "product_id": parseInt(this.state.webinarProduct),
      "type" : parseInt(e),
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
         this.setState({webinarList: response.data, webinarLoading: false, filterLoading: false});
      }
      else{
        this.setState({webinarLoading: false, filterLoading: false});
      }
    }.bind(this))
  }


  getBlog=(e)=>{
    var url = devconfig.adminV3+"/resources/list-blog";
    var data = JSON.stringify({
      "page_num": parseInt(this.state.pageNum),
      "product_id": parseInt(e),
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
         this.setState({blogList: response.data, blogLoading: false, filterLoading: false});
      }
      else{
        this.setState({blogLoading: false, filterLoading: false});
      }
    }.bind(this))
  }
  getReport=(e)=>{
    var url = devconfig.adminV3+"/resources/list-report";
    var data = JSON.stringify({
      "page_num": parseInt(this.state.pageNum),
      "product_id": parseInt(e),
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
         this.setState({reportList: response.data, reportsLoading: false, filterLoading: false});
      }
      else{
        this.setState({reportsLoading: false, filterLoading: false});
      }
    }.bind(this))
  }

  handleSelect(tempVal){
    this.setState({filterLoading: true});
    switch(this.state.tabIndex){
      case 1 :this.getVideo(tempVal.value);

      break;
      case 2: this.setState({webinarProduct: tempVal.value});
      this.getWebinar();
      case 3: this.getBlog(tempVal.value);
      break;
      case 4: this.getReport(tempVal.value);
      break;
    }

  }
  tabItemClick(tempItem){
    this.setState({tabIndex: tempItem});
    switch(tempItem){
      case 1: this.getVideo();
      break;
      case 2: this.getWebinar(1);
      break;
      case 3: this.getBlog();
      break;
      case 4: this.getReport();
      break;

    }
  }

   addVideo=()=> {
    var overlaylogin = '<div class="e-login-overlay" id="e-log-overlay"></div>';
    $("body").append(overlaylogin);
    $('.e-login').addClass('e-login-fly');

  }
   addWebinar=()=> {
    var overlaylogin = '<div class="e-login-overlay1" id="e-log-overlay1"></div>';
    $("body").append(overlaylogin);
    $('.e-login1').addClass('e-login-fly');
  }
  addBlog=()=> {
    var overlaylogin = '<div class="e-login-overlay2" id="e-log-overlay2"></div>';
    $("body").append(overlaylogin);
    $('.e-login2').addClass('e-login-fly');
  }
  addReport=()=> {
    var overlaylogin = '<div class="e-login-overlay3" id="e-log-overlay3"></div>';
    $("body").append(overlaylogin);
    $('.e-login3').addClass('e-login-fly');
  }

   toggleModal = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
    var overlaylogin = '<div class="e-modal-overlay" id="e-modal-overlay"></div>';
    $("body").append(overlaylogin);
    document.getElementById('e-modal-overlay').addEventListener('click', this.removeOverlay);
  }
  removeOverlay = () =>{
    this.setState({
      isOpen: !this.state.isOpen
    });
    $('#e-modal-overlay').remove();
  }
  handleTab = (e) => {

    this.setState({ type: e });
    this.getWebinar(e);
   }

  // renderTab(){

  //   if (this.state.type === 1){
  //     return <LiveWebinar/>
  //   }
  //   if (this.state.type === 2){
  //     return <WebinarReplay/>
  //   }
  // }

  openToast=(e)=>{
   if (e === "Success"){

      this.setState({toast: "Video Added successfully" , toastType:"success"})
      setTimeout( function() { this.setState({ toast: null}); }.bind(this), 4000);
    this.getVideo();
    }


  }
  openWebToast=(e)=>{
   if (e === "Webinar Added Successfully"){
    this.setState({toast: "Webinar Added successfully",  toastType:"success"})
    setTimeout( function() { this.setState({ toast: null}); }.bind(this), 4000);
    this.getWebinar();
    }


  }
  openBlogToast=(e)=>{
   if (e === "Blog Added Successfully"){
    this.setState({toast: e,  toastType:"success"})
    setTimeout( function() { this.setState({ toast: null}); }.bind(this), 4000);
    this.getBlog();
    }


  }
  openReportToast=(e)=>{
   if (e === "Report Added Successfully"){
    this.setState({toast: e,  toastType:"success"})
    setTimeout( function() { this.setState({ toast: null}); }.bind(this), 4000);
    this.getReport();
    }


  }
  renderPagiantion(){
    switch(this.state.tabIndex){
      case 1 :
      return(
        this.state.videoList && this.state.videoList.no_of_pages > 1 ?
        <Pagination pageNum={this.state.videoList.no_of_pages} change={this.pageNumChange.bind(this)} currentPage={this.state.pageNum}/>
        :
        null
      )
      break;
      case 2:
      return(
        this.state.webinarList && this.state.webinarList.no_of_pages > 1 ?
        <Pagination pageNum={this.state.webinarList.no_of_pages} change={this.pageNumChange.bind(this)} currentPage={this.state.pageNum}/>
        :
        null
      )
      break;
      case 3:
      return(
        this.state.blogList && this.state.blogList.no_of_pages > 1 ?
        <Pagination pageNum={this.state.blogList.no_of_pages} change={this.pageNumChange.bind(this)} currentPage={this.state.pageNum}/>
        :
        null
      )
      break;
      case 4:
      return(
        this.state.reportList && this.state.reportList.no_of_pages > 1 ?
        <Pagination pageNum={this.state.reportList.no_of_pages} change={this.pageNumChange.bind(this)} currentPage={this.state.pageNum}/>
        :
        null
      )
      break;
    }
  }
  async pageNumChange(tempVal){
    await this.setState({pageNum: tempVal});
    window.scrollTo({top: 0, behavior: 'smooth'});
    switch(this.state.tabIndex){
      case 1 :
      this.getVideo();
      break;
      case 2 :
      this.getWebinar();
      break;
      case 3 :
      this.getBlog();
      break;
      case 4 :
      this.getReport();
      break;
    }

  }

  render() {
    const options =[]
      options.push({value: null, label: "All Products"})

    options.push(this.state.productsList ?

      this.state.tabIndex ===1 ?

      this.state.productsList.video_products.map((item, index) => {
        return(


        options.push({ value: item.id, label: item.name })
        );
      })
      :
      this.state.tabIndex ===2 ?
      this.state.productsList.webinar_products.map((item, index) => {
        return(


         options.push({ value: item.id, label: item.name })
        );
      })
     :
     this.state.tabIndex ===3 ?
     this.state.productsList.blog_products.map((item, index) => {
        return(


        options.push({ value: item.id, label: item.name })
        );
      })
     :
     this.state.productsList.report_products.map((item, index) => {
        return(


        options.push({ value: item.id, label: item.name })
        );
      })
   
     :
      null)


    const renderButton = ()=>{
      switch(this.state. tabIndex) {
        case 1:{
          return  <span className="e-add-product-btn e-p-btn e-add-video-btn" value="1" onClick={this.addVideo.bind(this)}><img src={VideoAdd} className="e-add-video-img pr-2"/>Add New Video</span>
        }
        case 2:{
          return  <span className="e-add-product-btn e-p-btn e-add-video-btn" value="2" onClick={this.addWebinar.bind(this)}><img src={VideoAdd} className="e-add-video-img pr-2"/>Add New Webinar</span>
        }
        case 3:{
          return  <span className="e-add-product-btn e-p-btn e-add-article-btn" value="3" onClick={this.addBlog.bind(this)}><img src={ArticleAdd} className="e-add-video-img pr-2"/>Add New Article</span>
        }
        case 4:{
          return  <span className="e-add-product-btn e-p-btn e-add-article-btn" value="4" onClick={this.addReport.bind(this)}><img src={ArticleAdd} className="e-add-video-img pr-2"/>Add New Report</span>
        }

      }
    }
    return(
      <React.Fragment>
       <div className="container e-main-content pt-4">
        <div className="col-md-12">
          <div className="row mb-4">
            <div className="col-md-8">
              {
                this.state.tabIndex === 1 ?
                  <span className="e-tabs-item e-active pr-5">Videos</span>
                :
                  <span onClick={this.tabItemClick.bind(this, 1)} className="e-color-inactive pr-5">Videos</span>
              }

              {
                this.state.tabIndex === 2 ?
                  <span className="e-tabs-item e-active pr-5">Webinars</span>
                :
                  <span onClick={this.tabItemClick.bind(this, 2)} className="e-color-inactive pr-5">Webinars</span>
              }
              {
                this.state.tabIndex === 3 ?
                  <span className="e-tabs-item e-active pr-5">Blogs</span>
                :
                  <span onClick={this.tabItemClick.bind(this, 3)} className="e-color-inactive pr-5">Blogs</span>
              }
              {
                this.state.tabIndex === 4 ?
                  <span className="e-tabs-item e-active pr-5">Performance Reports</span>
                :
                  <span onClick={this.tabItemClick.bind(this, 4)} className="e-color-inactive pr-5">Performance Reports</span>
              }
             </div>

            <div className="col-md-4 mt-2">
              <div className="text-right mb-2">
                {renderButton()}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-2">
              <div className="e-filter-wrap">
                <label>Products</label>
                <SelectBox  placeholder="All Products" options={options} selectchange={(selectedValue) => this.handleSelect(selectedValue)}/>
              </div>
            </div>

          { /* <div className="col-lg-2">
                        <div className="e-filter-wrap pt-2">
                          <label>Vendor</label>
                          <SelectBox  placeholder="All Vendors"/>
                        </div>
                      </div>*/}
          </div>

          {
            this.state.tabIndex === 1 ?
            <div className="row">
            {
              this.state.videoLoading?
              <div className="col-lg-12 text-center e-min-height-loader">
                <ApiLoader/>
              </div>
              :
              null
            }
             {
              this.state.filterLoading?
              <OverlayLoader/>
              :
              null
            }
            {
                  this.state.videoList &&
                  !this.state.videoList.videos.length ?
                  <div className="col-lg-12 text-center">
                    <NothingFound data="No Video Found"/>
                  </div>
                  :
                  null
                  }

            {
            this.state.videoList ?
              this.state.videoList.videos.map((item, index) => {
                  const id = item.videoID;
                  const idwithhash = id ? `#${id}` : "nope";
                  return(<VideoListing data={item} dataId={idwithhash} key={index}/>);
              })
            :
            null
              // <React.Fragment>
              //   {
              //     !this.state.videoLoading && !this.state.filterLoading ?
              //       <NothingFound text="No videos found! Clear the filters and try again."/>
              //     :
              //       null
              //   }
              // </React.Fragment>
            }
            <AddVideos triggerToast={(tempVal)=>{this.openToast(tempVal)}}/>
            {
              this.state.toast ?
                <Toast data={this.state.toast} type={this.state.toastType}/>
              :
              null
             }
            </div>
            :
            null

          }


          {
            this.state.tabIndex === 2 ?
              <div>
              {
              this.state.webinarLoading?
              <div className="col-lg-12 text-center e-min-height-loader">
                <ApiLoader/>
              </div>
              :
              null
            }
            {
              this.state.filterLoading?
              <OverlayLoader/>
              :
              null
            }

                  <div className="e-tab">
                    <button className={ this.state.type===1 ? " mr-4 e-tablinks e-tabbtn-active " : "mr-4 e-tablinks" } id="pro-tab" onClick={() =>this.handleTab(1)}>Upcoming Live Webinars</button>
                    <button className={ this.state.type===2 ? " mr-4 e-tablinks e-tabbtn-active" : "mr-4 e-tablinks" } id="review-tab" onClick={() => this.handleTab(2)}>Webinar Replays</button>
                  </div>
                  <div className="row">
                  {
                  this.state.webinarList &&
                  !this.state.webinarList.webinars.length ?
                  <div className="col-lg-12 text-center">
                    <NothingFound data="No Webinar Found"/>
                  </div>
                  :
                  null
                  }
                  {
                  this.state.webinarList ?
                  this.state.webinarList.webinars.map((item, index) => {
                    return(<LiveWebinar data={item} key={index}/> );
                  })
                  :
                null

            }
              </div>
              <AddWebinar triggerToast={(tempVal)=>{this.openWebToast(tempVal)}}/>
               {
              this.state.toast ?
                <Toast data={this.state.toast} type={this.state.toastType}/>
              :
              null
             }

              </div>
          :
            null
          }
           {
            this.state.tabIndex === 3 ?
            <div className="row">
            {
              this.state.blogLoading?
              <div className="col-lg-12 text-center e-min-height-loader">
                <ApiLoader/>
              </div>
              :
              null
            }
            {
              this.state.filterLoading?
              <OverlayLoader/>
              :
              null
            }
            {
                  this.state.blogList &&
                  !this.state.blogList.blogs.length ?
                  <div className="col-lg-12 text-center">
                    <NothingFound data="No Blog Found"/>
                  </div>
                  :
                  null
                  }
            {
              this.state.blogList ?
              this.state.blogList.blogs.map((item, index) =>{
                return( <BlogListing data={item} key={index}/>);
              })
              :
            null
            }
            <AddArticle triggerToast={(tempVal)=>{this.openBlogToast(tempVal)}}/>
             {
              this.state.toast ?
                <Toast data={this.state.toast} type={this.state.toastType}/>
              :
              null
             }
            </div>

            :
            null
          }
          {
            this.state.tabIndex === 4 ?
            <div className="row">
            {
              this.state.reportsLoading?
              <div className="col-lg-12 text-center e-min-height-loader">
                <ApiLoader/>
              </div>
              :
              null
            }
            {
              this.state.filterLoading?
              <OverlayLoader/>
              :
              null
            }
            {
                  this.state.reportList &&
                  !this.state.reportList.reports.length ?
                  <div className="col-lg-12 text-center">
                    <NothingFound data="No Report Found"/>
                  </div>
                  :
                  null
            }

            {this.state.reportList ?
              this.state.reportList.reports.map((item, index) =>{
                return(
                  <ReportListing data={item} key={index}/>
                );
              })

              :
              null
            }
            <AddReport triggerToast={(tempVal)=>{this.openReportToast(tempVal)}}/>
            {
              this.state.toast ?
                <Toast data={this.state.toast} type={this.state.toastType}/>
              :
              null
             }
            </div>
          :
            null

          }
            <div className="row pb-5">
              {this.renderPagiantion()}

            </div>
          </div>

        </div>

          <ConfirmationModal/>
        </React.Fragment>

      );


  }
}

export default Resources;
