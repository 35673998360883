/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';
import Radio from '@material-ui/core/Radio';
import moment from 'moment';

/*Import custom components and assets*/
import SelectBox from '../../components/common/selectBox';
import ColorPicker from '../../components/common/colorPicker';
import ImageUploader from '../../components/common/imageUploader';
import UploadLoader from '../../components/common/uploadLoader';
import InputBox from '../../components/common/inputBox';
import Textarea from '../../components/common/textarea';
import DatePicker from '../../components/common/datePicker';
import Checkbox from '../../components/common/checkbox';
import ConfirmationModal from '../../components/popups/confirmationModal';
import SimpleSlider from '../../components/slider/PreviewSlider';


// import assets
import AddIllustration from '../../assets/images/add-illustartion.svg';
import AddVideo from '../../assets/images/video.svg';
import Close from '../../assets/images/close-btn.svg';
import Img from '../../assets/images/image.svg';
import Delete from '../../assets/images/delete-black.svg';
import IdeasWithSmallcase from '../../assets/images/ideaswithsmallcase.svg';
import View from '../../assets/images/carbon_view.svg';
import Edit from '../../assets/images/carbon_edit.svg';
import FreeSticker from '../../assets/images/marketplaceDetails/freesticker.svg';
/*Services*/
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';
import devconfig from '../../config/config.js';

const options = [
      { value: 1, label: "Day" },
      { value: 2, label: "Month" },
      { value: 3, label: "Year" },
    ]

class AddProduct extends Component {
  constructor(props){
    super(props);
    this.state = {
      colorOne: "",
      colorTwo: "",
      pickerOneID: "colorPick1",
      pickerTwoID: "colorPick2",
      logoURL: "",
      illustrationURL: "",
      showUploadLoader: 0,
      filterTypeValue: null,
      filterLevelValue: null,
      pTitle: "",
      pPrice: "",
      pOffer: "",
      pLink: "",
      pDesc: "",
      errorMessage: "",
      btnClicked: false,
      inputReset: false,
      pName: "",
      imgLoader: 0,
      benefits:["", "", "", ""],
      options: null,
      filter: [],
      segments: [],
      via_external_link: 0,
      free_trial: 0,
      subscription: [{price: null, offer_price: null, validity: null, features:["", "", "", ""], "validity_type" : 1,"validity_text" : "Day", name: null}],
      mob_based: false,
      web_based: false,
      compatible_on: [],
      images: ["", "", ""],
      images_prev: [{url: null, video: false}, {url: null, video: false}, {url: null, video: false}],
      trial_type: { value: 1, label: "Day" },
      instructions_1: ["", "", ""],
      instructions_2: ["", "", ""],
      error: "",
      optionsVendors: [],
      external_link_web: "",
      apiLoading: false,
      deleteItem: {},
      all: false,
      deleteIndex: 0,
      video: null,
      video_thumbnail: null,
      preview: false,
      link_upload: 0,
      error: "",

    }
  }

  async componentDidMount() {
    this.getSegments();
    this.getVendors();

    console.log("this.props.data");
      console.log(this.props.data);


    if(this.props.data){
      console.log("this.props.data");
      console.log(this.props.data);

      var data = this.props.data;
      console.log(this.props.data.temp_release_date)
      var tempTrialType = options.filter(function (el) {
        return el.value <= data.trial_type
      });

      var video = null;
      var videoThumbnail = null;
      var images = [];
      if(data.media_data && data.media_data.length){

        data.media_data.map(function(item, index){

          if(item.video){
            video = item.url;
            videoThumbnail = item.thumbnail;
          }
          else{
            images.push(item.url);
          }
        });
      }

      var tempReleaseDate = null;
      if(data.temp_release_date){
        tempReleaseDate = moment(data.temp_release_date, 'DD-MM-YYYY');
      }

      var tempIOSUpdateDate = null;
      if(data.temp_ios_update_date){
        tempIOSUpdateDate = moment(data.temp_ios_update_date, 'DD-MM-YYYY');
      }

      var tempAndroidUpdateDate = null;
      if(data.temp_android_update_date){
        tempAndroidUpdateDate = moment(data.temp_android_update_date, 'DD-MM-YYYY');
      }


      console.log("data.tempReleaseDate");
      console.log(tempReleaseDate);

      var tempWebBased = null;
      var webBased = false;
      var tempWebBased = data.application_types.filter(function (el) {
        return el.id == 1
      });

      if(tempWebBased){
        webBased = true;
      }

      console.log("tempWebBased");
      console.log(tempWebBased);


      var tempMobBased = null;
      var mobBased = false;
      var tempMobBased = data.application_types.filter(function (el) {
        return el.id == 2
      });

      if(tempMobBased){
        mobBased = true;
      }

      var tempfree_trial = 0;

      if(data.is_trail_available){
        tempfree_trial = 1;
      }

      if(!data.is_trail_available && !data.free){
        tempfree_trial = 2;
      }
      var regVideo = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/gi;
    if(regVideo.test(data.video)){
      this.setState({link_upload: 1});
    }
      await this.setState({
        name: data.product_name,
        title: data.title,
        breif: data.description,
        about: data.about,
        colorCode: data.gradient_1,
        logo: data.logo,
        illustration: data.illustration,
        free_trial: tempfree_trial,
        trial_type: tempTrialType[0],
        trail_validity: data.trail_validity,
        subscription: data.subscription_plans,
        via_external_link: data.via_external_link,
        external_link_web: data.external_link,
        filter: data.filter_ids,
        compatible_on: data.compatible_ids,
        segments: data.segment_ids,
        minInvestment: data.min_investment ? data.min_investment : "",
        benefits: data.benefits,
        release_date: tempReleaseDate,
        usp: data.usp,
        video: video,
        video_thumbnail: videoThumbnail,
        images: images,
        mob_based: mobBased,
        web_based: webBased,
        external_link_ios: data.ios_app_link,
        external_link_andoid: data.android_app_link,
        ios_install_count: data.ios_install_count,
        ios_rating: data.ios_rating,
        ios_update_date: tempIOSUpdateDate,
        android_install_count: data.android_install_count,
        android_rating: data.android_rating,
        android_update_date: tempAndroidUpdateDate,
        instructions_1: data.instructions_1 ? data.instructions_1 : ["", "", ""],
        instructions_2: data.instructions_2 ? data.instructions_2  : ["", "", ""],
        vendor:{value: data.created_by, label: data.vendor_name},
        redirect_url: data.redirection_url

      })
    }
  }

  getSegments(){
    var url = devconfig.adminV3 +'/product/data-list';

    APIGet(url).then(function(response){
      if(response.status){
        this.setState({options: response.data});
      }
    }.bind(this))
  }

  getVendors  (){
    var url = devconfig.adminV3 +'/vendor/names';

    APIGet(url).then(function(response){
      if(response.status){
        var tempArry = []
        response.data.map(function(item, index){
          var item = {value: item.id, label:item.name};
          tempArry.push(item);
        });
        this.setState({optionsVendors: tempArry});
      }
    }.bind(this))
  }

  hideLogin=()=> {
    this.props.modalClose();
  }

  async uploadStatus(status){
    this.setState({uploading: status})
  }

  logoUploaded(tempData){
    // alert(tempData)
    this.setState({logo: tempData})
  }

  async logoUploadTrigger(tempType){
    await this.setState({imgLoader: tempType});
    $("#e-prod-logo-upload").click()
  }

  async illustrationUploadTrigger(tempType){
    await this.setState({imgLoader: tempType});
    $("#e-prod-illustration-upload").click()
  }

  async uploadTrigger(tempType, tempId){
    await this.setState({imgLoader: tempType});
    $(tempId).click()
  }

  segmentFeatureChange(e, tempIndex1, tempIndex2){
    var tempArray = this.state.subscription;
    tempArray[tempIndex1].features[tempIndex2] = e.target.value;
    this.setState({subscription: tempArray})
  }

  submit=()=>{
    var errormsg= "";
    var  linkReg =/(https?:\/\/)?[\w\-~]+(\.[\w\-~]+)+(\/[\w\-~@:%]*)*(#[\w\-]*)?(\?[^\s]*)?/gi;

    console.log(this.state.via_external_link);
    this.setState({apiLoading: true})
    var error = false;
    var tempBenefits = this.state.benefits.filter(function (item) {
      return item != "";
    });

    var tempImages = this.state.images.filter(function (item) {
      return item != "";
    });

    var tempApplication_types = [];
    if(this.state.web_based){
      tempApplication_types.push(1);
    }

    if(this.state.mob_based){
      tempApplication_types.push(2);
    }

    if(this.state.free_trial){
      this.state.subscription.map(function(item, index){

        var tempArry = item.features.filter(function (item) {
          return item != "";
        });
        // || (tempArry && !tempArry.length)
        if(!item.price || !item.validity ){
          error = true;
          // this.setState({error: "Check the subscription section, at least one subscription plan & all the plan required fields are mandatory."})

          errormsg ="Check the subscription section, at least one subscription plan & all the plan required fields are mandatory."
        }
      });
      this.setState({error: errormsg});
    }


    if(!tempBenefits.length){
      error = true;
      this.setState({error: "Add atleast one benefits"})
    }
    var RegExp = /^#[0-9A-F]{6}$/i;

    if(!RegExp.test(this.state.colorCode)){
       error = true;
      this.setState({error: "Incorrect color code"})
    }

     
      if(this.state.video && !linkReg.test(this.state.video)){
        error = true;
        this.setState({error: "please check uploaded url in Video and Screenshote section"})
      }
      var Reg = /(https?:\/\/)?[\w\-~]+(\.[\w\-~]+)+(\/[\w\-~@:%]*)*(#[\w\-]*)?(\?[^\s]*)?/gi;
      if(this.state.redirect_url && !Reg.test(this.state.redirect_url)){
        error = true;
        this.setState({error: "please check url entered in Redirect URL"})
      } 


    if(!tempImages){
      error = true;
      this.setState({error: "Add atleast one screenshot"})
    }

    var tempInstructions_1 = this.state.instructions_1.filter(function (item) {
      return item != "";
    });

    var tempInstructions_2 = this.state.instructions_2.filter(function (item) {
      return item != "";
    });

    var tempRelease_date = this.state.release_date;
    if(this.state.release_date && this.state.release_date._i){
      tempRelease_date = this.state.release_date.format("DD-MM-YYYY");
    }

    var tempIos_date = this.state.ios_update_date;
    if(this.state.ios_update_date && this.state.ios_update_date._i){
      tempIos_date = this.state.ios_update_date.format("DD-MM-YYYY");
    }

    var tempAndroid_date = this.state.android_update_date;
    if(this.state.android_update_date && this.state.android_update_date._i){
      tempAndroid_date = this.state.android_update_date.format("DD-MM-YYYY");
    }

    if(!error){
      console.log(this.state.video);
      var url = devconfig.adminV3 +'/product/create';
      if(this.props.data){
        url = devconfig.adminV3 +'/product/update';
      }
      console.log(tempImages);
      console.log(this.state.images);
      console.log(this.state.video);
      console.log(this.state.video_thumbnail);
      var data = JSON.stringify({
        "id": this.props.data ? this.props.data.id : null,
        "product_name" : this.state.name,
        "title" :  this.state.title,
        "description" :  this.state.breif,
        "about" :  this.state.about,
        "gradient_1": this.state.colorCode,
        "gradient_2": this.state.colorCode,
        "logo": this.state.logo,
        "illustration": this.state.illustration,
        "free" :  this.state.free_trial == 0 ? true : false,
        "is_trail_available" : this.state.free_trial == 1 ? true : false,
        "trial_type" : this.state.trial_type ? this.state.trial_type.value : null,
        "trail_validity" : this.state.trail_validity,
        "subscription_plans" : this.state.subscription,
        "via_external_link" :  this.state.via_external_link != 0 ? true : false,
        "external_link" :  this.state.external_link_web,
        "filter_ids" :  this.state.filter,
        "compatible_on" :  this.state.compatible_on,
        "segment_ids" : this.state.segments,
        "min_investment" : Number(this.state.minInvestment),
        "benefits" :  tempBenefits,
        "release_date" :  tempRelease_date,
        "usp" :  this.state.usp,
        "video" :  this.state.video,
        "video_thumbnail" : this.state.video_thumbnail,
        "images" : tempImages,
        "application_types" :  tempApplication_types,
        "ios_app_link" :  this.state.external_link_ios,
        "android_app_link" :  this.state.external_link_andoid,
        "redirection_url" : this.state.redirect_url,
        "ios_install_count" : this.state.ios_install_count,
        "ios_rating" : this.state.ios_rating,
        "ios_update_date" : tempIos_date,

        "android_install_count" :this.state.android_install_count,
        "android_rating" : this.state.android_rating,
        "android_update_date" : tempAndroid_date,
        "offer" :  true,
        "instructions_1" : tempInstructions_1,
        "instructions_2" : tempInstructions_2,
        "created_by": this.state.vendor ? this.state.vendor.value : null
      })

      APIPost(url, data).then(function(response){
        if(response.status == "ok"){
          this.setState({apiLoading: false})
          this.props.success(response.message);
          this.setState({subscription: [{price: null, offer_price: null, validity: null, features:["", "", "", ""], "validity_type" : 1,"validity_text" : "Day", name: null}],})
          this.hideLogin();
          window.location.reload();

        }
        else{
          this.setState({error: response.message, apiLoading: false})
        }
      }.bind(this))


    }
  }


  deleteSubscription(){
    var tempArray = this.state.subscription;
    tempArray.splice(this.state.deleteIndex, 1);
    if(!tempArray.length){
      tempArray = [{price: null, offer_price: null, validity: null, features:["", "", "", ""], "validity_type" : 1,"validity_text" : "Day"}];
    }
    this.setState({subscription: tempArray}) ;
    var url = devconfig.adminV3 +'/subscriptionplan/remove';
    var data = JSON.stringify({
      "plan_id"  :  this.state.deleteItem.id
    })

    APIPost(url, data).then(function(response){
      if(response.status == "ok"){
        this.setState({apiLoading: false})
      }
      else{
        this.setState({error: response.message, apiLoading: false})
      }
    }.bind(this))

  }

  render() {
    var prevImage=[];
    var prevPlan=[];
    var tempIDSelectOne = "#"+this.state.pickerOneID;
    var tempIDSelectTwo = "#"+this.state.pickerTwoID;
    var tempColorOne = { background: this.state.colorOne };
    var tempColorTwo = { background: this.state.colorTwo };
    var release =  moment(this.state.release_date , 'DD-MM-YYYY');
    var ios_date = moment(this.state.ios_update_date , 'DD-MM-YYYY');
    var android_date = moment(this.state.android_update_date , 'DD-MM-YYYY');

    console.log("Vendor before trial_type");
    console.log(this.state.trial_type)
    console.log(this.state.release_date);

    return(
      <React.Fragment>
      <div className={"container text-left e-login e-add-product-modal "+this.props.modalClass}>
      <section className="pl-5 pr-5">
            <div className="row mb-3 e-modal-border ">

              <div>
                <button className="e-login-closebtn">
                  <img src={Close} alt="close button" className="e-toggle" onClick={this.hideLogin.bind(this)}/>
                </button>
              </div>
              <div className="col-lg-4 pt-5 mt-3 ml-5">
                <h5 className="">{this.props.data ? "Edit Product " : "Add New Product" }</h5>
              </div>
              <div className="col-lg-7 pt-5 mt-3">


                {
                  this.state.name &&
                  this.state.title &&
                  this.state.logo &&
                  this.state.illustration &&
                  this.state.colorCode &&
                  this.state.breif &&
                  this.state.about &&
                  this.state.usp &&
                  this.state.filter.length &&
                  this.state.segments.length &&
                  this.state.release_date &&
                  this.state.vendor &&
                  (this.state.web_based || this.state.mob_based) &&
                  (this.state.video ? this.state.video && this.state.video_thumbnail : !this.state.video && !this.state.video_thumbnail) &&
                  !this.state.apiLoading || this.state.error
                   ?
                    <span className="e-save e-p-btn float-right" onClick={this.submit.bind(this)}>{this.props.data ? "Save & Update Product" : "Save & Add Product"}</span>
                  :
                    <span className="e-save e-p-btn float-right e-in-active">

                          <React.Fragment>
                            {
                              this.props.data ?
                                "Save & Add Product"
                              :
                                "Save & Update Product"
                            }
                          </React.Fragment>

                    </span>
                }
                {
                  this.state.preview ?
                  <button className="e-product_prev float-right mr-4 mt-3" onClick={()=>{this.setState({preview: false, release_date: release, ios_update_date: ios_date, android_update_date: android_date})}}><img src={Edit}/> Edit Product</button>
                  :
                  <button className="e-product_prev float-right mr-4 pt-2 mt-1" onClick={()=>{this.setState({preview: true})}}><img src={View}/> View Preview</button>
                }

              </div>
            </div>
            {
              this.state.error.length ?

              <div className="row pt-4">
                <div className="col-lg-6">
                  <div className="e-error mb-3">{this.state.error}</div>
                </div>
              </div>

              :
                null

            }
            </section>


          <React.Fragment>
          {
            !this.state.preview ?

        <div className="row">


            <section className="pl-5 pr-5">
            <div className="row pt-4">
              <div className="col-lg-6">
                <span className="e-add-product-desc">Section: Breif Info</span>
              </div>
            </div>
            <div className="row pt-3">
              <div className="col-lg-12">
                <label>PRODUCT NAME*</label>
                <InputBox
                  required={true}
                  uppercase={false}
                  placeholder="Name of your product"
                  id="e-prod-name"
                  type="text"
                  value={this.state.name}
                  onChange={(e) => {this.setState({name: e.target.value}) }}
                />
              </div>
              <div className="col-lg-12">
                <label>MAIN TITLE*</label>
                <InputBox
                  required={true}
                  uppercase={false}
                  placeholder="Main title of your product (Max 45 characters)"
                  id="e-prod-title"
                  type="text"
                  value={this.state.title}
                  onChange={(e) => {this.setState({title: e.target.value}) }}
                  maxlength={100}
                />
              </div>
            </div>
            <div className="row pt-3">
              <div className="col-lg-4">
                <label>upload logo and illustration*</label>
                <div className="row">
                  <div className="col-lg-6 position-relative">
                  {
                    this.state.imgLoader == 1 && this.state.uploading ?
                        <span className="e-img-upload-loader"></span>
                    :
                      <React.Fragment>
                        {
                          this.state.logo ?
                            <span className="e-img-preview">
                              <img src={this.state.logo}/>
                              <span className="e-change" onClick={()=>{ this.setState({logo: null}) }}></span>
                            </span>
                          :
                            <React.Fragment>
                              <span className="e-upload-btn e-store text-center mb-3" onClick={this.logoUploadTrigger.bind(this, 1)}><span className="mt-2">Add Logo</span></span>
                           </React.Fragment>
                        }
                      </React.Fragment>
                  }
                  <ImageUploader
                    accept="application/pdf, image/*"
                    className="hidden"
                    tempKey="/prod/illustration"
                    id="e-prod-logo-upload"
                    uploadStatus={this.uploadStatus.bind(this)}
                    uploadSucess={this.logoUploaded.bind(this)}
                  />
                  </div>

                  <div className="col-lg-6">
                    {
                      this.state.imgLoader == 2 && this.state.uploading ?
                          <span className="e-img-upload-loader"></span>
                      :
                        <React.Fragment>
                          {
                            this.state.illustration ?
                              <span className="e-img-preview">
                                <img src={this.state.illustration}/>
                                <span className="e-change" onClick={()=>{ this.setState({illustration: null}) }}></span>
                              </span>
                            :
                              <React.Fragment>
                                <span className="e-upload-btn e-store text-center mb-3" onClick={this.illustrationUploadTrigger.bind(this, 2)}><span className="mt-2">Add illustration</span></span>
                             </React.Fragment>
                          }
                        </React.Fragment>
                    }
                    <ImageUploader
                      accept="application/pdf, image/*"
                      className="hidden"
                      tempKey="/prod/logo"
                      id="e-prod-illustration-upload"
                      uploadStatus={this.uploadStatus.bind(this)}
                      uploadSucess={(tempVal) => this.setState({illustration: tempVal, uploading: false})}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <label>choose color*</label>
                <InputBox
                  required={true}
                  uppercase={false}
                  placeholder="Enter color code in hexadecimal"
                  id="e-prod-bg-color"
                  type="text"
                  value={this.state.colorCode}
                  onChange={(e) => {this.setState({colorCode: e.target.value}) }}
                />
                <span className="e-example">Eg: #000000</span>
                <span className="e-tips">Tip: Use colors from the illustration</span>
              </div>
              <div className="col-lg-1 pt-4 mt-1">
                <div className="e-choose-color" style={{background: this.state.colorCode}}></div>
              </div>
            </div>
            <div className="row pt-3">
              <div className="col-lg-12">
                <label>Breif about the product*</label>
                <Textarea
                  required={true}
                  uppercase={false}
                  maxlength="200"
                  placeholder="Brief about the product (Max 200 characters)"
                  id="e-prod-breif"
                  type="text"
                  value={this.state.breif}
                  onChange={(e) => {this.setState({breif: e.target.value}) }}
                />
              </div>
            </div>
            <div className="row pt-5">
              <p className="pl-2"> <span className="e-add-product-desc">Section: About the Product</span> </p>
            </div>
            <div className="row pt-3">
              <div className="col-lg-12">
                <label>About*</label>
                <Textarea
                  required={true}
                  uppercase={false}
                  placeholder="Write about the product"
                  id="e-prod-about"
                  type="text"
                  value={this.state.about}
                  onChange={(e) => {this.setState({about: e.target.value}) }}
                />
              </div>
              <div className="col-lg-12">
                <label>USP*</label>
                <InputBox
                  required={true}
                  uppercase={false}
                  placeholder="USP of your product"
                  id="e-prod-usp"
                  type="text"
                  value={this.state.usp}
                  onChange={(e) => {this.setState({usp: e.target.value}) }}
                />
              </div>
              <div className="col-lg-12">
                <label>Redirect URL</label>
                <InputBox
                  required={true}
                  uppercase={false}
                  placeholder="Redirect URL"
                  id="e-prod-title"
                  type="text"
                  value={this.state.redirect_url}
                  onChange={(e) => {this.setState({redirect_url: e.target.value}) }}
                 
                />
              </div>
            </div>
            <div className="row e-prod-benefits-wrap">
              <div className="col-lg-12">
                <label>Add benefits*</label>
              </div>

              {
                this.state.benefits.map(function(item, index){
                  return(
                    <div className="col-lg-6">
                      <InputBox
                        required={true}
                        uppercase={false}
                        placeholder={"Point "+ parseInt(index + 1)}
                        id="e-prod-benefits-1"
                        type="text"
                        value={this.state.benefits[index]}
                        onChange={(e) => {
                          var tempArry = this.state.benefits;
                          tempArry[index] = e.target.value;
                          this.setState({benefits: tempArry})
                        }}
                      />
                    </div>
                  )
                }.bind(this))
              }

              <div className="col-lg-12">
                <span className="e-linktext" onClick={(e) => {
                  var tempArry = this.state.benefits;
                  tempArry.push("")
                  this.setState({benefits: tempArry}) }
                } >+ Add another Benefit</span>
              </div>

              <div className="col-lg-8">
                <label className="pb-2 pt-4">Suitable for*</label>
                  <div className="custom-control custom-checkbox mb-3 pl-0">
                  <label className="e-checkbox-wrap ">
                              <p className="e-normal-p">All</p>
                              <input type="checkbox" checked={this.state.all} onChange={() => {
                                this.setState({all: !this.state.all})
                                 var tempArry = this.state.filter;
                                if(this.state.all === false){
                                  this.state.options.filters.map(function(item, index){
                                    return(
                                      tempArry.push(item.id)
                                    );
                                  })
                                  this.setState({filter: tempArry})
                                }else{
                                 this.setState({filter: []})

                                }}} />
                              <span className="checkmark"></span>
                            </label>

                    {
                      this.state.options ?
                        this.state.options.filters.map(function(item, index){
                          return(
                            <label className="e-checkbox-wrap ">
                              <p className="e-normal-p">{item.name}</p>
                              <input type="checkbox" checked={$.inArray(item.id, this.state.filter) !== -1 ? true : false} onChange={(e) => {
                                var tempArry = this.state.filter;
                                var tempIndex = $.inArray(item.id, tempArry);
                                if(tempIndex == -1){
                                  tempArry.push(item.id)
                                }
                                else{
                                  tempArry.splice(tempIndex, 1);
                                }
                                this.setState({filter: tempArry})
                              }} />
                              <span className="checkmark"></span>
                            </label>
                          )
                        }.bind(this))

                      :
                        null
                    }

                  </div>
              </div>
              <div className="col-lg-8">
                <label className="pb-2 pt-4">segments*</label>
                  <div className="custom-control custom-checkbox mb-3 pl-0">

                    {
                      this.state.options ?
                        this.state.options.segments.map(function(item, index){
                          return(
                            <label className="e-checkbox-wrap ">
                              <p className="e-normal-p">{item.name}</p>
                              <input type="checkbox" checked={$.inArray(item.id, this.state.segments) !== -1 ? true : false} onChange={(e) => {
                                var tempArry = this.state.segments;
                                var tempIndex = $.inArray(item.id, tempArry);
                                if(tempIndex == -1){
                                  tempArry.push(item.id)
                                }
                                else{
                                  tempArry.splice(tempIndex, 1);
                                }
                                this.setState({segments: tempArry})
                              }} />
                              <span className="checkmark"></span>
                            </label>
                          )
                        }.bind(this))

                      :
                        null
                    }
                  </div>
              </div>
              <div className="col-lg-5 pt-2">
                <label>minimum investment</label>
                <InputBox
                  required={true}
                  uppercase={false}
                  placeholder="₹ Enter minimum investment amount"
                  id="e-prod-usp"
                  type="text"
                  value={this.state.minInvestment}
                  onChange={(e) => {this.setState({minInvestment: e.target.value}) }}
                />
              </div>
              <div className="col-lg-12 pt-2">
                <label>product purchase links</label>
                <p className="e-product-purchase">Can users able to purchase this product directly from Tradestore?
                <Radio value={0} checked={this.state.via_external_link == 0} onChange={(e) => { this.setState({via_external_link: e.target.value}) }}/>Yes, they can
                <Radio value={1} checked={this.state.via_external_link == 1} onChange={(e) => { this.setState({via_external_link: e.target.value}) }}/>No, they need to buy from the original site/app.
                </p>

              </div>
              {
                this.state.via_external_link == 1 &&
                  <React.Fragment>
                    <div className="col-lg-8 pt-1">
                      <InputBox
                        required={true}
                        uppercase={false}
                        placeholder="Website URL"
                        id="e-prod-usp"
                        type="text"
                        value={this.state.external_link_web}
                        onChange={(e) => {this.setState({external_link_web: e.target.value}) }}
                      />
                    </div>
                    <div className="col-lg-8 pt-1">
                      <InputBox
                        required={true}
                        uppercase={false}
                        placeholder="iOS app link"
                        id="e-prod-usp"
                        type="text"
                        value={this.state.external_link_ios}
                        onChange={(e) => {this.setState({external_link_ios: e.target.value}) }}
                      />
                    </div>
                    <div className="col-lg-8 pt-1">
                      <InputBox
                        required={true}
                        uppercase={false}
                        placeholder="Andoid app link"
                        id="e-prod-usp"
                        type="text"
                        value={this.state.external_link_andoid}
                        onChange={(e) => {this.setState({external_link_andoid: e.target.value}) }}
                      />
                    </div>
                  </React.Fragment>
              }


            </div>
            <div className="row pt-5">
              <p className="pl-2"> <span className="e-add-product-desc">Section: Subscription Plans & Features</span> </p>
            </div>
            <div className="row">
              <div className="col-lg-8">
                <label className="pb-2 pt-2"><u>free or trial plans*</u></label>
                <div className="custom-control custom-checkbox mb-3 pl-0">
                  <Radio value={0} className="pl-0" checked={this.state.free_trial == 0} onChange={(e) => { this.setState({free_trial: e.target.value}) }}/>Mark free for Aliceblue customers
                  <Radio value={1} checked={this.state.free_trial == 1} onChange={(e) => { this.setState({free_trial: e.target.value}) }}/>Free trial available
                  <Radio value={2} checked={this.state.free_trial == 2} onChange={(e) => { this.setState({free_trial: e.target.value}) }}/>No Free trial available
                </div>
              </div>
              {
                this.state.free_trial == 1 &&
                  <div className="col-lg-6 pt-1">
                    <InputBox
                        required={true}
                        uppercase={false}
                        placeholder="Enter Trial Period"
                        id="e-prod-trial-period"
                        type="number"
                        value={this.state.external_link_andoid}
                        onChange={(e) => {this.setState({trail_validity: e.target.value}) }}
                      />
                      <span className="e-valid-type-select e-trial-type-wrap">
                          <SelectBox placeholder="Select the product" options={options} defaultValue={this.state.trial_type}  selectchange={(tempVal) =>{
                            this.setState({trial_type: tempVal})
                          }}/>
                        </span>
                  </div>
              }

            </div>
          </section>

          <React.Fragment>
            {
              this.state.subscription.map(function(item, index){
                return(
                  <section className="e-subscription mt-3">
                    <div className="container pr-5 pl-5">
                      <div className="row py-2 ">
                        <div className="col-lg-12 pt-4">
                          <label>{!this.state.free_trial ? "Free plan details" :"Subscription plan"+(index+1)+"*" }

                          {
                            this.props.data ?
                              <span className="pl-3 cursor-pointer" data-toggle="modal" data-target="#e-confirmModal-1" onClick={(e) => {this.setState({deleteIndex: index, deleteItem: item}) }}><img src={Delete}/></span>
                            :
                              <span className="pl-3" onClick={(e) => {
                                  var tempArray = this.state.subscription;
                                  tempArray.splice(index, 1)

                                  if(!tempArray.length){
                                    tempArray = [{price: null, offer_price: null, validity: null, features:["", "", "", ""], "validity_type" : 1,"validity_text" : "Day", name: null}];
                                  }
                                  this.setState({subscription: tempArray})
                                }} ><img src={Delete}/>
                              </span>
                          }

                          </label>
                        </div>
                      </div>
                      <div className="row pt-2">
                        <div className="col-lg-4">
                          <label>Plan Name*</label>
                          <InputBox
                            required={true}
                            uppercase={false}
                            placeholder=" Enter name of the subscription plan"
                            id="e-prod-name"
                            type="text"
                            value={item.name}
                            onChange={(e) => {
                              var tempArray = this.state.subscription;
                              tempArray[index].name = e.target.value;
                              this.setState({subscription: tempArray})
                            }}
                          />
                        </div>
                        <div className="col-lg-4">
                          <label>Regular price*</label>
                          <InputBox
                            required={true}
                            uppercase={false}
                            placeholder="₹ Enter regular price"
                            id="e-prod-price"
                            type="text"
                            value={item.price}
                            onChange={(e) => {
                              var tempArray = this.state.subscription;
                              tempArray[index].price = e.target.value;
                              this.setState({subscription: tempArray})
                            }}
                          />
                        </div>
                        {
                          this.state.free_trial ?
                            <React.Fragment>
                              <div className="col-lg-4">
                                <label>Tradestore price</label>
                                <InputBox
                                  required={true}
                                  uppercase={false}
                                  placeholder="₹ Offer price"
                                  id="e-prod-offer-price"
                                  type="text"
                                  value={item.offer_price}
                                  onChange={(e) => {
                                    var tempArray = this.state.subscription;
                                    tempArray[index].offer_price = e.target.value;
                                    this.setState({subscription: tempArray})
                                  }}
                                />
                              </div>
                              <div className="col-lg-4 pt-2 mt-3">
                                <InputBox
                                  required={true}
                                  uppercase={false}
                                  placeholder="Enter subscription period*"
                                  id="e-prod-subscription-period"
                                  type="number"
                                  value={item.validity}
                                  onChange={(e) => {
                                    var tempArray = this.state.subscription;
                                    tempArray[index].validity = e.target.value;
                                    this.setState({subscription: tempArray})
                                  }}
                                />
                                <span className="e-valid-type-select">
                                  <SelectBox placeholder="Select the product" options={options} defaultValue={{value: item.validity_type, label: item.validity_text}}  selectchange={(tempVal) =>{
                                    var tempArray = this.state.subscription;
                                    tempArray[index].validity_type = tempVal.value;
                                    tempArray[index].validity_text = tempVal.label;
                                    this.setState({subscription: tempArray})
                                  }}/>
                                </span>
                              </div>
                            </React.Fragment>
                          :
                            null
                        }



                      </div>
                      <div className="row pt-2">
                        <div className="col-lg-12">
                          <label>features of this plan</label>
                        </div>
                        <SubscriptionFeatures data={item.features} featureChange={(tempArry) => {
                            var tempArray = this.state.subscription;
                            tempArray[index].features = tempArry;
                            this.setState({subscription: tempArray});
                          }}/>

                          <div className="col-lg-12 pl-0 pt-1 pl-2 pb-4">
                            <span className="e-add-product-link" onClick={(e) => {
                              var tempArray = this.state.subscription;
                              tempArray[index].features.push("");
                              this.setState({subscription: tempArray})
                            }}>
                            <a className="e-link">+ Add another feature</a>
                          </span>
                        </div>
                      </div>
                    </div>
                  </section>
                )
              }.bind(this))
            }

            {
              this.state.free_trial ?
                <div className="pl-4 ml-3 pt-4">
                  <span className="e-add-product-link" onClick={(e) => {
                    var tempArray = this.state.subscription;
                    tempArray.push({price: null, offer_price: null, validity: null, features:["", "", "", ""], "validity_type" : 1,"validity_text" : "Day", name: null});
                    this.setState({subscription: tempArray})
                  }}>
                    <a className="e-link">+ Add another subscription plan</a>
                  </span>
                </div>
              :
                null
            }

          </React.Fragment>



          <section className=" pl-5 pr-5">
            <div className="row pt-5">
              <p className="pl-2"> <span className="e-add-product-desc">Section: Other Details</span> </p>
            </div>
            <div className="row pt-3">
              <div className="col-lg-7 pt-2">
                <div className="e-modal-select-wrap-lg">
                  <label>vendor*</label>
                  {
                    this.props.data ?
                      <React.Fragment>
                        {
                          this.state.vendor ?
                            <SelectBox placeholder="Select the vendor" options={this.state.optionsVendors} defaultValue={this.state.vendor}  selectchange={(tempVal) =>{
                              this.setState({vendor: tempVal})
                            }}/>
                          :
                            null
                        }
                      </React.Fragment>
                    :
                      <SelectBox placeholder="Select the vendor" options={this.state.optionsVendors} defaultValue={this.state.vendor}  selectchange={(tempVal) =>{
                        this.setState({vendor: tempVal})
                      }}/>
                  }

                </div>
              </div>
              <div className="col-lg-7 pt-2">
                <label>release date*</label>
              </div>
              <div className="col-lg-7 pt-2 pb-3 e-release-date">
              {
                this.props.data ?
                  <React.Fragment>
                    {
                      this.state.release_date ?
                        <DatePicker value={this.state.release_date} dateChange={(e) => this.setState({release_date: e})} outsideRange={true}/>
                      :
                        null
                    }
                  </React.Fragment>
                :
                  <DatePicker value={this.state.release_date} dateChange={(e) => this.setState({release_date: e})} outsideRange={true}/>
              }
              </div>
              <div className="col-lg-10">
                <label className="pb-2 pt-2">application type*</label>
                <div className="custom-control custom-checkbox mb-3 pl-0">
                  <Checkbox label="Web based" value={this.state.web_based} onChange={(e) => this.setState({web_based: !this.state.web_based})}/>
                  <Checkbox label="Mobile Application" value={this.state.mob_based} onChange={(e) => this.setState({mob_based: !this.state.mob_based})}/>
                </div>
              </div>
              {
                this.state.mob_based ?
                  <React.Fragment>
                    <div className="col-lg-3">
                      <label>application popularity</label>
                      <InputBox
                        required={true}
                        uppercase={false}
                        placeholder="iOS installation count"
                        id="e-ios-installation"
                        type="text"
                        value={this.state.ios_install_count}
                        onChange={(e) => {this.setState({ios_install_count: e.target.value}) }}
                      />
                    </div>
                    <div className="col-lg-3 pt-4 mt-1">
                      <InputBox
                        required={true}
                        uppercase={false}
                        placeholder="iOS rating (eg: 4.3)"
                        id="e-ios_rating"
                        type="text"
                        value={this.state.ios_rating}
                        onChange={(e) => {this.setState({ios_rating: e.target.value}) }}
                      />
                    </div>
                    <div className="col-lg-3 e-on-date pt-4 mt-1 pr-5 mr-5">
                      {
                        this.props.data ?
                          <React.Fragment>
                            {
                              this.state.ios_update_date ?
                                <DatePicker value={this.state.ios_update_date} dateChange={(e) => this.setState({ios_update_date: e})}/>
                              :
                                <DatePicker dateChange={(e) => this.setState({ios_update_date: e})}/>
                            }
                          </React.Fragment>
                        :
                          <DatePicker value={this.state.ios_update_date} dateChange={(e) => this.setState({ios_update_date: e})}/>
                      }
                    </div>

                    <div className="col-lg-3">
                      <InputBox
                        required={true}
                        uppercase={false}
                        placeholder="Android installations count"
                        id="e-android_install_count"
                        type="text"
                        value={this.state.android_install_count}
                        onChange={(e) => {this.setState({android_install_count: e.target.value}) }}
                      />
                    </div>
                    <div className="col-lg-3 ">
                      <InputBox
                        required={true}
                        uppercase={false}
                        placeholder="Android rating (eg: 4.3)"
                        id="e-android_rating"
                        type="text"
                        value={this.state.android_rating}
                        onChange={(e) => {this.setState({android_rating: e.target.value}) }}
                      />
                    </div>
                    <div className="col-lg-3 e-on-date">
                      {
                        this.props.data ?
                          <React.Fragment>
                            {
                              this.state.android_update_date ?
                                <DatePicker value={this.state.android_update_date} dateChange={(e) => this.setState({android_update_date: e})}/>
                              :
                                <DatePicker dateChange={(e) => this.setState({android_update_date: e})}/>
                            }
                          </React.Fragment>
                        :
                          <DatePicker value={this.state.android_update_date} dateChange={(e) => this.setState({android_update_date: e})}/>
                      }
                    </div>

                  </React.Fragment>
                :
                  null
              }




              {
                this.state.web_based ?
                  <div className="col-lg-8">
                    <label className="pb-2">compaitable on</label>
                    <div className="custom-control custom-checkbox mb-3 pl-0">
                      <input type="checkbox" className="custom-control-input pr-4 " />
                      <Checkbox label="Chrome" value={$.inArray(1, this.state.compatible_on) !== -1 ? true : false} onChange={(e) =>{
                          var tempArry = this.state.compatible_on;
                          var tempIndex = $.inArray(1, tempArry);
                          if(tempIndex == -1){
                            tempArry.push(1)
                          }
                          else{
                            tempArry.splice(tempIndex, 1);
                          }
                          this.setState({compatible_on: tempArry})
                        }}/>

                        <Checkbox label="Firefox" value={$.inArray(2, this.state.compatible_on) !== -1 ? true : false} onChange={(e) =>{
                          var tempArry = this.state.compatible_on;
                          var tempIndex = $.inArray(2, tempArry);
                          if(tempIndex == -1){
                            tempArry.push(2)
                          }
                          else{
                            tempArry.splice(tempIndex, 1);
                          }
                          this.setState({compatible_on: tempArry})
                        }}/>

                        <Checkbox label="Opera" value={$.inArray(3, this.state.compatible_on) !== -1 ? true : false} onChange={(e) =>{
                          var tempArry = this.state.compatible_on;
                          var tempIndex = $.inArray(3, tempArry);
                          if(tempIndex == -1){
                            tempArry.push(3)
                          }
                          else{
                            tempArry.splice(tempIndex, 1);
                          }
                          this.setState({compatible_on: tempArry})
                        }}/>


                        <Checkbox label="Edge" value={$.inArray(4, this.state.compatible_on) !== -1 ? true : false} onChange={(e) =>{
                          var tempArry = this.state.compatible_on;
                          var tempIndex = $.inArray(4, tempArry);
                          if(tempIndex == -1){
                            tempArry.push(4)
                          }
                          else{
                            tempArry.splice(tempIndex, 1);
                          }
                          this.setState({compatible_on: tempArry})
                        }}/>

                    </div>
                  </div>
                :
                  null
              }

            </div>
            <div className="row pt-5">
              <p className="pl-2"> <span className="e-add-product-desc">Section: Videos & Screenshots</span> </p>
            </div>
            <div className="row">
              <div className="col-lg-12 pt-2">
                <label>upload promo video</label>
                </div>
                <div className="col-lg-12 pl-0 mb-3">
                <Radio value={0} checked={this.state.link_upload == 0} onChange={(e) => { this.setState({link_upload: e.target.value}) }}/>Upload Video
                <Radio value={1} checked={this.state.link_upload  == 1} onChange={(e) => { this.setState({link_upload: e.target.value}) }}/>Upload Video Link
                </div>

              <div className="col-lg-4 pl-0">
              {
                this.state.link_upload == 1?
                <div className="mt-5 pl-3">
                <InputBox
                        required={true}
                        uppercase={false}
                        placeholder="Upload Video Link"
                        id="e-android_rating"
                        type="text"
                        value={this.state.video}
                        onChange={(e) => {this.setState({video: e.target.value}) }}
                      />
                      </div>
                  :
                  <div className="">
                  {
                    this.state.imgLoader == 3 && this.state.uploading ?
                        <span className="e-img-upload-loader ml-3"></span>
                    :
                      <React.Fragment>
                        {
                          this.state.video ?
                            <span className="e-img-preview ml-3">
                              <span className="e-video-success">Upload Success</span>
                              <span className="e-change" onClick={()=>{ this.setState({video: null}) }}></span>
                            </span>
                          :
                            <React.Fragment>
                              <span className="e-upload-btn e-store e-video text-center mb-3 ml-3" onClick={this.uploadTrigger.bind(this, 3, "#e-upload-video")}><span className="mt-2">Add Video</span></span>
                           </React.Fragment>
                        }
                      </React.Fragment>
                  }
                  <ImageUploader
                    accept=".mp4,.mov,.webm"
                    className="hidden"
                    tempKey="/prod/video"
                    id="e-upload-video"
                    uploadStatus={this.uploadStatus.bind(this)}
                    uploadSucess={(tempVal) => this.setState({video: tempVal, uploading: false})}
                  />

                </div>

              }

              </div>
              <div className="col-lg-4 pl-0">
                {
                  this.state.imgLoader == 4 && this.state.uploading ?
                      <span className="e-img-upload-loader ml-3"></span>
                  :
                    <React.Fragment>
                      {
                        this.state.video_thumbnail ?
                          <span className="e-img-preview ml-3">
                            <img src={this.state.video_thumbnail}/>
                            <span className="e-change" onClick={()=>{ this.setState({video_thumbnail: null}) }}></span>
                          </span>
                        :
                          <React.Fragment>
                            <span className="e-upload-btn e-video-thumbnail-upload text-center mb-3 ml-3" onClick={this.uploadTrigger.bind(this, 4, "#e-upload-video-thumbnail")}><span className="mt-2">Add Video Thumbnail</span></span>
                         </React.Fragment>
                      }
                    </React.Fragment>
                }
                <ImageUploader
                  accept=".jpeg,.jpg,.png"
                  className="hidden"
                  tempKey="/prod/video"
                  id="e-upload-video-thumbnail"
                  uploadStatus={this.uploadStatus.bind(this)}
                  uploadSucess={(tempVal) => this.setState({video_thumbnail: tempVal, uploading: false})}
                />
              </div>


              <div className="col-lg-12 pt-2">
                <label>upload screenshots / images*</label>
              </div>


              {
                this.state.images.map(function(item, index){
                  return(
                    <div className="col-lg-2">
                    <div className="">
                        {
                          this.state.imgLoader == (5+index) && this.state.uploading ?
                              <span className="e-img-upload-loader "></span>
                          :
                            <React.Fragment>
                              {
                                item && item.length ?
                                  <span className="e-img-preview">
                                    <img src={item}/>
                                    <span className="e-change" onClick={()=>{
                                      var tempArry =  this.state.images;
                                      tempArry[index] = "";
                                      this.setState({images: tempArry})
                                    }}></span>
                                  </span>
                                :
                                  <React.Fragment>
                                    <span className="e-upload-btn e-store text-center mb-3" onClick={this.uploadTrigger.bind(this, 5+index, "#e-upload-screeshot"+index)}><span className="mt-2">Add Screenshot</span></span>
                                 </React.Fragment>
                              }
                            </React.Fragment>
                        }
                        <ImageUploader
                          accept=".jpeg,.jpg,.png"
                          className="hidden"
                          tempKey="/prod/screenshot"
                          id={"e-upload-screeshot"+index}
                          uploadStatus={this.uploadStatus.bind(this)}
                          uploadSucess={(tempVal) => {
                            var tempArry =  this.state.images;
                            tempArry[index] = tempVal;
                            this.setState({images: tempArry, uploading: false});
                            }
                          }
                        />

                     </div>
                  </div>
                  )
                }.bind(this))
              }



            </div>
            <div className="pt-3">
                <span className="e-add-product-link" onClick={(tempVal) => {
                    var tempArry =  this.state.images;
                    tempArry.push("");
                    this.setState({images: tempArry, });
                    }
                  }><a>+ Add another image</a>
                </span>
            </div>

            <div className="row py-5 mt-3">
              <div className="col-lg-6">
                <div className="mb-4">
                  <span className="e-add-product-desc mb-3">Section: Instruction for Aliceblue customers</span>
                </div>

                {
                  this.state.instructions_1 && this.state.instructions_1.map(function(item, index){
                    return(
                      <div className="mb-3">
                        <InputBox
                          required={true}
                          uppercase={false}
                          placeholder= {"Instruction"+ (index+1)}
                          id={"e-ab-instruction-1"+ (index+1)}
                          type="text"
                          value={item}
                          onChange={(e) => {
                              var tempArry = this.state.instructions_1;
                              tempArry[index] = e.target.value;
                              this.setState({instructions_1: tempArry})
                            }}
                        />
                      </div>
                    )
                  }.bind(this))
                }

                <div className="pt-3">
                  <span className="e-add-product-link" onClick={(tempVal) => {
                      var tempArry =  this.state.instructions_1;
                      tempArry.push("");
                      this.setState({instructions_1: tempArry});
                      }
                    }><a>+ Add another instruction</a>
                  </span>
                </div>

              </div>
              <div className="col-lg-6">
                <div className="mb-4">
                  <span className="e-add-product-desc mb-3">Section: Instruction for other customers</span>
                </div>
                {
                  this.state.instructions_2 && this.state.instructions_2.map(function(item, index){
                    return(
                      <div className="mb-3">
                        <InputBox
                          required={true}
                          uppercase={false}
                          placeholder= {"Instruction "+ (index+1)}
                          id={"e-ab-instruction-2"+ (index+1)}
                          type="text"
                          value={item}
                          onChange={(e) => {
                              var tempArry = this.state.instructions_2;
                              tempArry[index] = e.target.value;
                              this.setState({instructions_2: tempArry})
                            }}
                        />
                      </div>
                    )
                  }.bind(this))
                }

                 <div className="pt-3">
                  <span className="e-add-product-link" onClick={(tempVal) => {
                      var tempArry =  this.state.instructions_2;
                      tempArry.push("");
                      this.setState({instructions_2: tempArry});
                      }
                    }><a>+ Add another instruction</a>
                  </span>
                </div>

              </div>
            </div>


          </section>


          <SubscriptionDeleteConfirm modalTitle="Confirmation" modalContent="Are you sure want to delete this plan? Deletion is a permanent process." confirmSuccess={this.deleteSubscription.bind(this)}/>
      </div>
      :
      <React.Fragment>
      <section className="e-marketDetails-header-sec">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-8">
                      <h6 className="e-marketDetails-h5 pb-2">{this.state.name ? this.state.name : null}</h6>
                      <h4 className="e-marketDetails-h4 pb-2">{this.state.title ? this.state.title : null}</h4>
                      <p className="mt-4 e-marketDetails-p">{this.state.breif ? this.state.breif : null}</p>
                    </div>
                    <div className="col-lg-4 text-right mt-5">
                      <img src={this.state.illustration ? this.state.illustration : null}  alt="bg image" className="mt-4 e-prod-detail-illustration"/>
                    </div>
                  </div>
                </div>
              </section>
              <section>
                <div className="container">
                    <div className="row">
            <div className="col-lg-7">
              <SimpleSlider media={

                 prevImage = this.state.images.filter(function (item) {
      return item != "";
    })}/>
              <div className="e-productdetls-about-smallcase">
                <hr className="e-form-rule"/>
                <h5 className="e-prdtdtls-smallcase-h5">About {this.state.name ? this.state.name : null}</h5>
                <p className="mt-4 e-prdtdtls-p">
                  {this.state.about ? this.state.about : null}
                </p>
              </div>
              <div className="e-prdtdtls-usp">
                <h6 className="mt-4">USP</h6>
                <p>{this.state.usp ? this.state.usp : null}</p>
              </div>
              <div className="e-smallcase-bnft">
                <hr className="e-form-rule"/>
                <h5 className="e-prdtdtls-smallcase-h5">{this.state.name ? this.state.name : null} Benefits</h5>
                <ul className="pt-2">
                  {
                    this.state.benefits ?
                    this.state.benefits.map((item, index) => {
                      return(
                        <li className="e-features-list mt-3" key={index}>{item }</li>
                        );
                      })
                    :
                      null
                  }
                </ul>
              </div>

            </div>

            <div className="col-lg-5">
              <div className="card border-0 e-prdtdtls-card ">
                <hr className="e-form-rule"/>
                <div className="row d-flex justify-content-between px-2">
                <h5 className="e-prdtdtls-smallcase-h5">Features</h5>
                {
                  this.state.free_trial && this.state.free_trial == 0?
                    <span className="e-free-sticker"><img src={FreeSticker} alt="image" /></span>
                  :
                  null
                }
                </div>
                <ul>
                {
                 this.state.subscription?
                 this.state.subscription.[0].features.map((item, index) => {
                    return(
                        <li className="e-features-list mt-3" key={index}>{item } </li>
                      );
                  })
                  :
                  null
                }
                </ul>
              <div className="e-product-wrap">
                                <SelectBox className="d-inline-block e-plan-select mt-3 text-left" options={prevPlan =this.state.subscription  ?
                 this.state.subscription.map((item, index) => {
                    return(
                        {value: index, label: item.name}
                      );
                  })
                  :
                  {value: 1, label: "test"}} selectchange={(tempVal) => this.handleSelect(tempVal)} defaultValue={options[0]} />
                              </div>
                              {
                                this.state.subscription?
                                this.state.subscription.map((item, index) => {
                                  return(
                                  <div className="e-bg-product mt-3 p-3">
                                    <div className="pb-2">
                                      <span className="e-prdtdtls-bold">Regular Price: &#8377; {item.price} </span>
                                      <span className="e-card-p"> </span>
                                    </div>
                                    <div>
                                      <span className="e-prdtdtls-bold">Trade Store Price:</span>
                                      <span className="e-grn-span">&#8377; {item.offer_price+"/"+item.validity_type+item.validity_text}</span>
                                      <span className="e-card-p"> </span>
                                    </div>
                                  </div>

                                  :null
                                  );
                                })
                                :
                                null
                              }

              </div>
         <div className="card mt-5 border-0 e-platform-card ">
                       <hr className="e-form-rule"/>
                       <h5 className="e-prdtdtls-smallcase-h5">Platforms</h5>
                       <p className="pt-3"><span className="e-prdtdtls-bold e-platform">Developed by: </span><span className="e-blue-p"> {this.state.vendor ? this.state.vendor.label : null}</span></p>
                       <p><span className="e-prdtdtls-bold">Type:</span> {this.state.web_based ? "Web Based" : "Mobile Based"}</p>
                       <p><span className="e-prdtdtls-bold">Released:</span>  {this.state.release_date._i}</p>
                       <p><span className="e-prdtdtls-bold">Compaitable on:</span> {
                         this.state.compatible_on ?
                         this.state.compatible_on.map((item, index) => {
                           return(
                              item == 1 ? "Chrome " : item == 2 ? "Firefox " : item ==3 ? "Opera " : item == 4 ? "Edge " : null

                           );
                         })
                         :
                         null
                       }</p>
                      <p><span className="mt-5 e-prdtdtls-bold">App Store:</span> {this.state.ios_update_date&&this.state.ios_rating&&this.state.ios_install_count?
                                               this.state.ios_install_count+"+" + " installs on " + this.state.ios_update_date._i +","+ " Rated " + this.state.ios_rating : null}</p>
                                              <p><span className="e-prdtdtls-bold">Play Store:</span> {this.state.android_install_count&&this.state.android_update_date&&this.state.android_rating?
                                              this.state.android_install_count+"+" + " installs on " + this.state.android_update_date._i +","+ " Rated " + this.state.android_rating : null}</p>
                     </div>
                   </div>
                <div className="col-lg-12">
                                      <div className="row e-smallcase-bnft">
                                    {
                                      this.state.instructions_1 && this.state.instructions_1.length ?
                                        <div className="col-lg-6 e-ac-direction ">
                                          <hr className="e-form-rule"/>
                                          <h5 className="e-prdtdtls-smallcase-h5 pt-3">If you are an Aliceblue Client</h5>
                                          <ul>
                                            {
                                              this.state.instructions_1 ?
                                              this.state.instructions_1.map((item, index) => {
                                                return(
                                                  <li className="e-features-list mt-3" key={index}>{item}</li>
                                                  );
                                                })
                                              :
                                                null
                                            }
                                          </ul>
                                        </div>
                                      :
                                        null
                                    }

                                    {
                                      this.state.instructions_2 && this.state.instructions_2.length ?
                                        <div className="col-lg-6 e-ac-direction ">
                                          <hr className="e-form-rule"/>
                                          <h5 className="e-prdtdtls-smallcase-h5 pt-3">If you are not an Aliceblue Client</h5>
                                          <ul>
                                            {
                                              this.state.instructions_2 ?
                                              this.state.instructions_2.map((item, index) => {
                                                return(
                                                  <li className="e-features-list mt-3" key={index}>{item}</li>
                                                  );
                                                })
                                              :
                                                null
                                            }
                                          </ul>
                                        </div>
                                      :
                                        null
                                    }
                                  </div>
                                    </div>
          </div>
                </div>
              </section>
              </React.Fragment>
            }
</React.Fragment>
    </div>


    {
      this.props.modalClass.length ?
        <div className="e-login-overlay" id="e-log-overlay"></div>
      :
        null
    }

    </React.Fragment>

    )
  }
}

export default AddProduct;


class SubscriptionFeatures extends React.Component {
  render() {
    return (
      <React.Fragment>
        {
          this.props.data.map(function(item, index){
            return(
              <div className="col-lg-6">
                <InputBox
                  required={true}
                  uppercase={false}
                  placeholder={"Point "+ (index+1)}
                  id="e-prod-subscription-period"
                  type="text"
                  value={item}
                  onChange={(e) => {
                    var tempArry = this.props.data;
                    tempArry[index] = e.target.value;
                    this.props.featureChange(tempArry);
                  }}
                />
              </div>
            )
          }.bind(this))
        }
      </React.Fragment>
    );
  }
}

class  SubscriptionDeleteConfirm extends React.Component {
  render() {
    return (
      <div className="modal fade e-modal-wrap" id="e-confirmModal-1" tabIndex="-1" role="dialog" aria-labelledby="e-confirmModalLabel" aria-hidden="true">
        <div className="modal-dialog  e-modal-confirm" role="document">
          <div className="modal-content">
            <div className="text-right">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body pt-2">
              <div className="row mb-3">
                <div className="col-lg-12">
                  <h5 className="pb-3">{this.props.modalTitle}</h5>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <p>{this.props.modalContent}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 text-right">
                  <span className="e-s-btn mr-4" data-dismiss="modal">Cancel</span>
                  <span className="e-p-btn" onClick={(tempVal) => {
                      this.props.confirmSuccess();
                      $('#e-confirmModal-1').modal('toggle');
                      }
                    }>Proceed</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
