/*Package importing*/
import React, { Component } from 'react';

/*Assets and Components*/
import InputBox from '../../components/common/inputBox';
import NewRequestPending from './newRequest';
import DeletePending from './deleteRequest';
// import PartneredVendors from './partneredVendors';
// import VendorDetail from './vendorDetail';


class RequestsMain extends Component {
  constructor(props){
    super(props);
    this.state = { 
      activeMenu: 1,
      detailID: null
    }
  }

  componentWillMount() {
    var query = this.getUrlVars();
    if(query.deleted){
      this.setState({activeMenu: 2});
    }
  }

  getUrlVars(){
    var vars = [], hash;
    var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
    for(var i = 0; i < hashes.length; i++){
      hash = hashes[i].split('=');
      vars.push(hash[0]);
      vars[hash[0]] = hash[1];
    }
    return vars;
  }

  sideMenuClick(tempItem){
    this.setState({activeMenu: tempItem});
  }

  vendorDetailClick(tempId){
    window.location.href = '/vendors?id='+tempId;
  }

  render() {
    return (
      <div className="container e-main-content ">
        <div className="row">
          <div className="col-lg-3 e-vendors-sideMenu-wrap">
            <div className="e-vendors-sideMenu">
              {
                this.state.activeMenu === 1 ?
                  <div className="e-vendors-sideMenu-item e-active" onClick={this.sideMenuClick.bind(this, 1)}>New Product Requests</div>
                :
                  <div className="e-vendors-sideMenu-item" onClick={this.sideMenuClick.bind(this, 1)}>New Product Requests</div>
              }
              {
                this.state.activeMenu === 2 ?
                  <div className="e-vendors-sideMenu-item e-active" onClick={this.sideMenuClick.bind(this, 2)}>Delete Requests</div>
                :
                  <div className="e-vendors-sideMenu-item" onClick={this.sideMenuClick.bind(this, 2)}>Delete Requests</div>
              }
              {/*
                this.state.activeMenu === 3 ?
                  <div className="e-vendors-sideMenu-item e-active" onClick={this.sideMenuClick.bind(this, 3)}>Vendor Requests</div>
                :
                  <div className="e-vendors-sideMenu-item" onClick={this.sideMenuClick.bind(this, 3)}>Vendor Requests</div>
              */}
            </div>
          </div>



          {
            this.state.activeMenu === 1 ?
              <NewRequestPending/>
            :
              null
          }

          {
            this.state.activeMenu === 2 ?
              <DeletePending/>
            :
              null
          }

          {/*
            this.state.activeMenu === 3 ?
              <VendorRequest/>
            :
              null
          */}

          {/*
            this.state.activeMenu === 4 ?
              <VendorDetail id={this.state.detailID}/>
            :
              null
          */}

        </div>
      </div>
    );
  }
}

export default RequestsMain;
