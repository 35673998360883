/*Import Packages*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';


/*Import components*/
import AddVideos from '../../components/popups/addVideo';
import ConfirmationModal from '../../components/popups/confirmationModal';
import Modal from '../../components/modal/VideoModal';

// import assets
import webinarimg from '../../assets/images/resource/webinarimg.svg';
import webinarimg1 from '../../assets/images/resource/webinarimg1.svg';
import playbtn from '../../assets/images/slider/carbon_play-outline.svg';
import videothumb2 from '../../assets/images/resource/videothumb2.svg';
import videothumb from '../../assets/images/resource/videothumb1.svg';




class WebinarReplay extends Component {

  constructor(props){
    super(props);
    this.state = {
      isOpen: false,
    }
  }

  componentDidMount() {

  }
  tabItemClick(tempItem){
    this.setState({tabIndex: tempItem});
  }
  // addVideo=()=> {
  //   var overlaylogin = '<div class="e-login-overlay" id="e-log-overlay"></div>';
  //   $("body").append(overlaylogin);
  //   $('.e-login').addClass('e-login-fly');

  // }
   toggleModal = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
    var overlaylogin = '<div class="e-modal-overlay" id="e-modal-overlay"></div>';
    $("body").append(overlaylogin);
    document.getElementById('e-modal-overlay').addEventListener('click', this.removeOverlay);
  }
  removeOverlay = () =>{
    this.setState({
      isOpen: !this.state.isOpen
    });
    $('#e-modal-overlay').remove();
  }

  render() {
    return(
      <div>
        <div className="row">
          <div class="col-lg-4  mt-3">
            <div class="card border-0 e-videolist-card e-webinar">
              <div class="card-image e-webinar-card-headersec">
                <img className=""   id="video-cover" src={webinarimg1} alt="Video title"/>
                <button id="play" className="e-vidoelist-play-btn" >
                  <img className="e-vidoelist-play" src={playbtn} alt="playbtn"/>
                </button>
                {/*<span className="e-vidoe-duration"> 2.00</span>*/}
               </div>
              <div class="card-content p-3">
                <span className="e-video-delete e-webinar" data-toggle="modal" data-target="#e-confirmModal"></span>
                <h5 class="e-video-card-head pt-2">Invest ideas with Small case</h5>
                <p className="e-webinar-p">Smallcases are investment products that help build a diversified, low-cost & long term portfolio.</p>
                <div className="">
                  <span className="e-mrktplce-usrtype mr-2">Smallcase</span>
                  <span className="e-mrktplce-usrtype">Aliceblue</span>
                </div>
                <div className="row pt-4 no-gutters">
                  <div className="col-lg-5 mt-3">
                    <span className="e-webinar-span">1 Hr + Live Q&A</span>
                  </div>
                  <div className="col-lg-7 text-right">
                    <span className="e-p-btn e-product d-inline-block">View More Details</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Modal show={this.state.isOpen}>
            <iframe className="e-slidnav-img" id="player" src="https://www.youtube.com/embed/jSNxzzw_1v4" allow="autoplay"></iframe>
          </Modal>

        </div>


        <AddVideos/>
        <ConfirmationModal/>
       </div>




      );


  }
}

export default WebinarReplay;
