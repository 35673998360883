import React from 'react';

// import asstes
import More from '../../assets/images/icons/feather_more-vertical.svg';

// import components
import PrimaryButton from '../../components/buttons/primaryBlueButton';
import ApiLoader from '../../components/Loaders/ApiLoader';

// import services
import APIPost from '../../components/apiCall/apiPost';
import APIGet from '../../components/apiCall/apiGet';
import devconfig from '../../config/config.js';


class Box extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div
        className="col-lg-12 mt-2"
        // style={{ backgroundColor: this.props.box.color }}
        draggable={this.props.draggable}
        onDragStart={this.props.onDragStart({ id: this.props.data.id })}
        onDragOver={this.props.onDragOver({ id: this.props.data.id })}
        onDrop={this.props.onDrop({ id: this.props.data.id })}
        >
        <div className="card border-0 e-drag-card mb-3 py-3">
          <div className="row ">
            <div className="col-lg-1 text-center">
              <img src={More} className="ml-2 "/>
            </div>
            <div className="col-lg-9 text-left">
              <span>{this.props.data.name}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class ManageList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount(){
    this.getList();
  }
  getList=()=>{
    this.setState({loading: true});
    var url = devconfig.adminV3+"/marketplace/get-layout";
    APIGet(url).then(async function(response){
      console.log(response);
      if(response.status === "ok"){
        await this.setState({list: response.data.products, initialOrder: response.data.layout_order, loading: false});
        await console.log('before drag ' +this.state.list)
      }else{
        this.setState({loading: false})
      }
    }.bind(this))
  }
  updateLayout=()=>{
    this.setState({updating: true});
    var url = devconfig.adminV3+"/marketplace/update-layout";
    var data = JSON.stringify({
      "layout_order" :  this.state.finalLayOut
    })
    APIPost(url, data).then(function(response){
      if(response.status){
        this.setState({updating: false});
        this.getList();
      }
    }.bind(this))
  }
  swapBoxes = async (fromBox, toBox) => {
  
    let boxes = this.state.list.slice();
    let fromIndex = -1;
    let toIndex = -1;
    var temp =[];
console.log('before swap '+this.state.initialOrder)
    for (let i = 0; i < boxes.length; i++) {
      if (boxes[i].id === fromBox.id) {
        fromIndex = i;
      }
      if (boxes[i].id === toBox.id) {
        toIndex = i;
      }
    }

    if (fromIndex != -1 && toIndex != -1) {
      let { fromId, ...fromRest } = boxes[fromIndex];
      let { toId, ...toRest } = boxes[toIndex];
      boxes[fromIndex] = { id: fromBox.id, ...toRest };
      boxes[toIndex] = { id: toBox.id, ...fromRest };
      // temp.push(fromBox.id,)

      this.setState({ list: boxes, });
      boxes.map((item, key)=>{
        return(
          temp.push(item.id)
        )
      })
      await this.setState({finalLayOut: temp})
      await console.log('after drag '  +this.state.finalLayOut);
    }
  };

/* The dragstart event is fired when the user starts dragging an element or text selection */
/* event.target is the source element : that is dragged */
/* Firefox requires calling dataTransfer.setData for the drag to properly work */
handleDragStart = data => event => {
  let fromBox = JSON.stringify({ id: data.id });
  event.dataTransfer.setData("dragContent", fromBox);
};

/* The dragover event is fired when an element or text selection is being dragged */
/* over a valid drop target (every few hundred milliseconds) */
/* The event is fired on the drop target(s) */
handleDragOver = data => event => {
  event.preventDefault(); // Necessary. Allows us to drop.
  return false;
};

/* Fired when an element or text selection is dropped on a valid drop target */
/* The event is fired on the drop target(s) */
handleDrop = data => event => {
  event.preventDefault();

  let fromBox = JSON.parse(event.dataTransfer.getData("dragContent"));
  let toBox = { id: data.id };

  this.swapBoxes(fromBox, toBox);
  return false;
};

// makeBoxes = () => {
//   return 
// };

render() {
  return(
  <React.Fragment>
  {
    this.state.loading ? 
    <div className="col-lg-12 text-center">
      <ApiLoader/>
    </div>
    :
  
    <div className="col-lg-6 py-4 e-manage-list-container mt-5">
     
        {
          this.state.list && this.state.list.map((item, key)=>{
            return(
             <Box
      data={item}
      key={key}
      draggable="true"
      onDragStart={this.handleDragStart}
      onDragOver={this.handleDragOver}
      onDrop={this.handleDrop}
      />
      )
          })
   

        }
    <div className="row mt-4">
      <div className="col-lg-12 text-right">
        <PrimaryButton className="e-p-btn e-login-btn" name={this.state.updating ? "Updating..": "Save Changes"} handleClick={this.updateLayout}/>
      </div>
    </div>
    
    </div>
  }
    </React.Fragment>
  ); 
}
}
export default ManageList;