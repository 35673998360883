/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Assets and Components*/
import APIPost from '../../components/apiCall/apiPost';
import APIGet from '../../components/apiCall/apiGet';
import devconfig from '../../config/config.js';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {DateRangePicker, isInclusivelyBeforeDay} from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import PayNowModal from '../../components/popups/payPartnerModal';
import LazyLoaderGif from '../../components/common/lazyLoaderGif';
import SelectBox from '../../components/common/selectBox';
import NothingFound from '../../components/common/noData';
import Toast from '../../components/popups/Toast';
import PlaceholderStyleOneLarge from '../../components/placeholders/placeholderCol1Large.js';
// assets
import Download from '../../assets/images/download-icon.svg';
import noResultImg from '../../assets/images/no-result.png';

class DataMain extends Component {
  notify = (e) => toast(e);
  constructor(props){
    super(props);
    this.state = {
      loading: true,
      leadStatusData: {},
      productData: {},
      partnerData: {},
      initalDate: moment().format(),
      startDate: moment().startOf('month'),
      endDate: moment(),
      tabIndex:1,
      pageNum: 1,
      selected: null,
    }
  }

  componentDidMount(){
    this.getRefferals(this.state.selected);
    this.getNames();
  }
 // getAggregates = async (first) => {
 //    if(first){
 //      var data = {
 //        start_date: undefined,
 //        end_date: undefined,
 //      }
 //    }else{
 //      var data = {
 //        start_date: this.state.startDate.format("YYYY-MM-DD"),
 //        end_date: this.state.endDate.format("YYYY-MM-DD"),
 //      }
 //    }
 //    $.ajax({
 //        type: "POST",
 //        url: devconfig.adminV2 +'/data/aggregates',
 //        dataType: 'json',
 //        headers: {
 //          'Accept': 'application/json',
 //          'Content-Type': 'application/json',
 //          'Access-Token': this.state.accessToken
 //        },
 //        data: JSON.stringify(data)
 //    }).then(function (response) {
 //        if (response.status) {
 //          this.setState({
 //            leadStatusData: response.lead_status_data,
 //            productData: response.product_data,
 //            partnerData: response.partner_data,
 //          })
 //        }else{
 //          this.notify(response.message)
 //        }
 //    }.bind(this))
 //  }

 //  onDatesChange = async ({ startDate, endDate }) => {
 //    if(startDate && endDate){
 //      var fromDate = startDate.format("YYYY-MM-DD");
 //      var toDate = endDate.format("YYYY-MM-DD");

 //      await this.setState({
 //        startDate,
 //        endDate,
 //        fromDate: fromDate,
 //        toDate: toDate,
 //        data: [],
 //        pageNum: 1,
 //        loading: true
 //      });

 //      await this.getAggregates();
 //    }
 //  }
 //  tabItemClick(tempItem){
 //    this.setState({tabIndex: tempItem});
 //  }
 //  sideMenuClick(tempItem){
 //    this.setState({activeMenu: tempItem});
 //  }
 //  selectTypeChange(tempData){
 //    this.prodListAPI(this.state.pageNum, tempData.value, this.state.expFilterValue);
 //    this.setState({ typeFilterValue: tempData.value});
 //  }

 getNames =()=>{
  var url = devconfig.adminV3+"/products/product-names";
    APIGet(url).then(function(response){
      console.log(response);
      if(response.status === "ok"){
          var temp = [];
        temp.push({ value: null, label: 'All Products' });
        response.data.map(function(item, index){
          var item = {value: item.id, label:item.name};
          temp.push(item);
          // tempModalArry.push(item);
        });
        this.setState({products: temp});
      }
    }.bind(this))
 }

 getRefferals =(e) =>{
  this.setState({dataLoading: true})
    var url = devconfig.adminV3+"/partner/referral-list";
    var data = JSON.stringify({
      "page_num" : this.state.pageNum,
      "product_id" : parseInt(e),
    });
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        // this.props.confirmSuccess(response.message)
        this.setState({data: response.data, dataLoading: false});
      }
      else{
        this.setState({ dataLoading: false});
      }
    }.bind(this))
  }

  selectChange(tempData){
    this.getRefferals( tempData.value );
    this.setState({ selected: tempData.value});
  }
   openTrigger=(temp)=>{
    this.setState({toast: "Partner Payout success" , toastType:"success"})
    setTimeout( function() { this.setState({ toast: null}); }.bind(this), 4000);
    this.getRefferals(this.state.selected);
  }

   downloadPayout=()=>{
    var url=devconfig.adminV3+"/vendor/download-payout-list";

    APIGet(url).then(function(response) {
      if(response.status){
        this.setState({downloadList: response.file_url});
        window.open(response.file_url)
      }
    }.bind(this))
  }


  render() {
    const leadOptions = {
      0: "New",
      1: "Contacted",
      2: "Postponed",
      3: "Subscribed",
      4: "Lost",
      5: "Other",
    }


    return (
      <div className="container e-main-content e-leads pt-4">
        <div className="col-md-12">
         <h2 className="mb-4">Referral Partners</h2>
        { /* <div className="row mb-4">
                    <div className="col-md-12">
                       {
                      this.state.tabIndex === 1 ?
                        <span className="e-tabs-item e-active pr-4">Referral Partners</span>
                      :
                        <span onClick={this.tabItemClick.bind(this, 1)} className="e-color-inactive pr-4">Referral Partners</span>
                    }

                    {
                      this.state.tabIndex === 2 ?
                        <span className="e-tabs-item e-active pr-4">Leads</span>
                      :
                        <span onClick={this.tabItemClick.bind(this, 2)} className="e-color-inactive">Leads</span>
                    }
                     </div>
                  </div>*/}

              <div>
                <div className="row">
                  <div className="col-lg-2">
                    <div className="e-filter-wrap e-referral-product pt-2">

                      <label>Products</label>
                      <SelectBox options={this.state.products}  placeholder="All" selectchange={(temp)=>{this.selectChange(temp)} }/>
                    </div>
                  </div>
                  <div className="col-lg-10 text-right">
                    <a className="e-download-link mr-5" onClick={this.downloadPayout}  target="_blank">Download CSV</a>
                  </div>
                </div>
                <div className="row pt-4">
                <div className="col-lg-12">
                  <table id="example" className="table e-subscription-table-wrap table-striped e-payout-table e-referral">
                    <thead>
                      <tr>
                        <th>Partner ID</th>
                        <th>Partner Name</th>
                        <th>Product Name</th>
                        <th>Joined Leads</th>
                        <th>Earned Commission</th>
                        <th>Pending Amount</th>
                        <th>Last Payment</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                    {
                      this.state.data?
                      this.state.data.map((item, key)=>{
                        return(
                          <tr key={key}>
                            <td>{item.partner_alice_blue_id}</td>
                            <td>{item.partner}</td>
                            <td>{item.product_name}</td>
                            <td>{item.joined_leads}</td>
                            <td>₹{item.earned_commission}</td>
                            <td>₹{item.pending_amount}</td>
                            <td>{item.last_paid}</td>
                        <td>
                          <span className="e-payout-btn e-p-btn d-inline-block" onClick={()=>{this.setState({id: item.id})}} data-toggle="modal" data-target={"#partnerpayout"+this.state.id}>Pay now</span>
                        </td>
                      </tr>
                        );
                      })
                      :
                      null
                    }

                    </tbody>
                  </table>
                </div>
                {
                  !this.state.dataLoading && this.state.data && !this.state.data.length ?
                  <NothingFound data="No Records Found!!" />
                  :
                  null
                }
              </div>
            </div>

                 {
          this.state.toast ?
            <Toast data={this.state.toast} type={this.state.toastType}/>
         :
            null
        }


                  <PayNowModal confirmSuccess={(temp)=>{this.openTrigger(temp)}} data={this.state.id} type={2} id={"partnerpayout"+this.state.id} />
        </div>
      </div>
    );
  }
}

export default DataMain;
