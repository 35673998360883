import React from 'react';
import $ from 'jquery';
// import component
import InputBox from '../../components/common/inputBox';

import APIPost from '../../components/apiCall/apiPost';
import APIGet from '../../components/apiCall/apiGet';
import devconfig from '../../config/config.js';

export default class payPartnerModal extends React.Component{
	constructor(props){
		super(props);
		this.state={

		}
	}
  payNow=()=>{
   
   var url = devconfig.adminV3+"/partner/payout";
     var data = JSON.stringify({
      "payout_id" : parseInt(this.props.data),
      "amount" : parseInt(this.state.payment),
    })

    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        this.props.confirmSuccess(response.message);
        var id ='#'+this.props.id
        $(id).modal('toggle');
        this.setState({amount: ""})
      }
    }.bind(this))
    
  }

	render(){
		return(

			<div class="modal fade" id={this.props.id} tabindex="-1" role="dialog" aria-labelledby="exampleModal" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Amount to Pay</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <InputBox placeholder="Enter the payment amount" value={this.state.payment} onChange={(e)=>{this.setState({payment: e.target.value})}} />
     
      </div>
      <div class="modal-footer">
        <button type="button" class="e-payout-btn e-p-btn border-0" onClick={this.payNow}>Pay</button>
      </div>
    </div>
  </div>
</div>
		);
	}
}  