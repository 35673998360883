

{/*Production link */ }
// const urlV1= "https://3vyyzk8vv6.execute-api.ap-south-1.amazonaws.com/v1/api/v1";
// const urlV2= "https://3vyyzk8vv6.execute-api.ap-south-1.amazonaws.com/v1/api/v2";
// const urlV3= "https://3vyyzk8vv6.execute-api.ap-south-1.amazonaws.com/v1/api/v2";

// const baseUrl = "https://eggqh64ytc.execute-api.ap-south-1.amazonaws.com/dev3/";
const baseUrl = "https://i6a3gt6ndd.execute-api.ap-south-1.amazonaws.com/live3/";
const devV1 = `${baseUrl}api/v1`;
const devV2 = `${baseUrl}api/v2`;
const devV3 = `${baseUrl}api/v3`;

var devconfig = {
    vendorV1: `${devV1}/vendor`,
    adminV1: `${devV1}/admin`,
    vendorV2: `${devV2}/vendor`,
    adminV2: `${devV2}/admin`,
    urlOnly: baseUrl,
    adminV3: `${devV3}/admin`,
}

module.exports = devconfig;




// const url= "https://unt6he2pxf.execute-api.ap-south-1.amazonaws.com/prod/api/v1";
// const url= "https://9d4eritqz9.execute-api.ap-south-1.amazonaws.com/dev/api/v1";