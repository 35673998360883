
import React from 'react';
import $ from 'jquery';

//import components
import Input from '../../components/common/inputBox';
import Text from '../../components/common/textarea';
import Toast from '../../components/popups/Toast';
import Select from 'react-select';

// import services
import APIPost from '../../components/apiCall/apiPost';
import APIGet from '../../components/apiCall/apiGet';
import devconfig from '../../config/config.js';

export default class extends React.Component{
    constructor(props){
        super(props);
        this.state={
    
        }
    }

    saveContent = () =>{
        let url  = devconfig.adminV3+'/referralcontent/update';
        let data =JSON.stringify({
            "user_type" : this.state.selectedProduct.value,
            "content" : this.state.message,
            "value" : this.state.title
        })
        APIPost(url, data).then((response)=>{
            if(response.status){
                this.setState({toast: true, message: 'Content added', type: 'success', title: null, selectedProduct: null, message: null});
            }
            else{
                this.setState({ toast: true, message: response.message, type: 'fail'})
            }
        })
        setTimeout(()=>{ this.setState({ toast: false})}, 4000)
    }
    render(){
        const options = [
            {value: 1, label: "Users"},
            {value: 2, label: "Partners"}
        ]
        return(
            <div className="container">
                <div className="row mt-4">
                    <div className="col-lg-12">
                        <h6>Add Refer & Earn Content</h6>
                    </div>
                    <div className="col-lg-6">
                    `   <div className="col-lg-12 my-4">
                            <Select
                                isSearchable={false}
                                options={options}
                                value={this.state.selectedProduct}
                                onChange={(e)=>{this.setState({selectedProduct: e})}}
                                placeholder="Select user type"
                            />
                         </div>
                        <div className="col-lg-12">
                            <Input value={this.state.title} onChange={(e)=>{this.setState({title: e.target.value})}} placeholder="Percentage"/>
                        </div>
                        <div className="col-lg-12">
                            <Text value={this.state.message} onChange={(e)=>{this.setState({message: e.target.value})}} placeholder="Message"/>
                        </div>
                        <div className="col-lg-3">
                        <button className="e-p-btn" onClick={this.saveContent}>Save</button>
                        </div>
                    </div>
                </div>
                {
                this.state.toast ? 
                    <Toast data={this.state.message} type={this.state.type}/>
                :
                    null
            }
            </div>
           

        )
    }
  
}