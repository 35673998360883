import React, { Component } from "react";
import Slider from "react-slick";
import $ from 'jquery'; 
import ReactPlayer from 'react-player/lazy';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import components
import VideoModal from '../../components/modal/VideoModal';
// import assets
import SliderImg from '../../assets/images/slider/sliderimg.png';
import SliderImg1 from '../../assets/images/slider/aliceblue-slider.png';
import SliderImg2 from '../../assets/images/slider/aliceblue-slider.svg';
import SliderImg3 from '../../assets/images/slider/trade-action.png';
import larrow from '../../assets/images/slider/slicknavlarrow.svg';
import rarrow from '../../assets/images/slider/slicknavrarrow.svg';
import playbtn from '../../assets/images/slider/carbon_play-outline.svg';
import thumpnail from '../../assets/images/slider/thump.jpeg';
import closebtn from '../../assets/images/header/close.png';


function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block",backgroundImage:`url(${rarrow})`, backgroundPosition: "50% 50%", right: "20px", borderRadius: "50px", width: "24px", height: "25px"}}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
       style={{ ...style, display: "block",backgroundImage:`url(${larrow})`, backgroundPosition: "50% 50%", left: "20px", borderRadius: "50px", width: "24px", height: "25px", zIndex: "10"}}
      onClick={onClick}
    />
  );
}


export default class SliderWithNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null,
      isOpen: false, 
      play: true,
      isModalOpen: false,
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });
    console.log(this.props.media);
  }
  playVideo=()=>{
    this.setState({isModalOpen: true, play: true})
  }
   pauseVideo=()=>{
    this.setState({play: false, isModalOpen: false});
  }
  render() {
     const settings = {
       nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
     }

    return (
      <div className="e-slick-nav">
        <Slider {...settings}
          arrows={true}
          autoplay={false}
          autoplaySpeed= {4000}
          asNavFor={this.state.nav2}
          ref={slider => (this.slider1 = slider)}
         
        >
        {
              this.props.media.map((item, index) => {

                return(
                  <div className="mt-3" key={index}>
                    {
                      item.video ?
                        <div className="e-wrapper e-video-wrapp position-relative">
                             <img className=" e-slidnav-img"   id="video-cover" src={item.thumbnail } alt="Video title"/>
                             <button className="e-play-btn" data-toggle="modal" data-target="#e-videoplayer" onClick={this.playVideo}>
                                <img className="e-video-play" src={playbtn} alt="playbtn"/>
                              </button> 
                             
                           </div> 
                      :
                       <img className=" e-slidnav-img"  src={item.url} alt="slider image"/>
                    }
                    
                    
                  </div>
          );
        })
        }
        </Slider>
        <Slider className="mt-4"
          asNavFor={this.state.nav1}
          ref={slider => (this.slider2 = slider)}
          slidesToShow={this.props.media.length}
          swipeToSlide={true}
          focusOnSelect={true}
        >
          {
              this.props.media.map((item, index) => {

                return(
                 <div>
                      <img className=" e-img-thum" src={item.video ? item.thumbnail : item.url} alt="slider image"/>
                 
                </div>
          );
        })
        }
        </Slider>
        <VideoModal modalId="slidervideo">
            <button className="e-videomodal-close"data-dismiss="modal" aria-hidden="true" onClick={this.pauseVideo}><img className="e-close" src={closebtn} alt="button"/></button>
            <ReactPlayer id="player" url={this.props.video} playing={this.state.play && this.state.isModalOpen }  width="100%" height="100%" />
        </VideoModal>
      </div>

    );
  }
}