/*Import Packages*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { useHistory } from 'react-router-dom';



/*Import components*/
import AddVideos from '../../components/popups/addVideo';
import ConfirmationModal from '../../components/popups/confirmationModal';
import Modal from '../../components/modal/VideoModal';
import PrimaryBlueButton from '../../components/buttons/primaryBlueButton';

// import assets
import webinarimg from '../../assets/images/resource/webinarimg.svg';
import videocam from '../../assets/images/resource/videocam.svg';
import webinarimg1 from '../../assets/images/resource/webinarimg1.svg';
import playbtn from '../../assets/images/slider/carbon_play-outline.svg';
import calender from '../../assets/images/calender.svg'



function LiveWebinar(props){

  let history = useHistory();
  function RouteChange() {
    let path = `webinarDetails?id=`+ props.data.id +`&product=` +props.data.title;
    history.push(path);
  }

    return(
          <div className="col-lg-4  mt-3">
        {
          props.data ?
          <div className="card border-0 e-videolist-card e-webinar e-card-hover  e-video-card" onClick={RouteChange}>
            <div className="card-image e-webinar-card-headersec">
              <img className="e-videolist-thumb"   id="video-cover" src={props.data.image} alt="Video title"/>
              {
                props.data.type == 1 ?
               
                    <React.Fragment>
                      <img src={calender} alt="video cam" className="e-webinar-videocam e-webinar-replay-icon"/>
                      <span className="e-webinar-schedule">{props.data.scheduled_on}</span>
                    </React.Fragment>
                
                :
                <React.Fragment>
                  <img src={videocam} alt="video cam" className="e-webinar-videocam e-webinar-replay-icon"/>
                  <span className="e-webinar-schedule">{props.data.duration}</span>
                </React.Fragment>
              }
            </div>
            <div className="card-content p-3">
              <h5 className="e-video-card-head">{props.data.title}</h5> 
              <p className="e-webinar-p">{props.data.description}</p>
              {
                props.data && props.data.products.map((item ,key)=>{
                  return(
                    <span className="mt-3 e-vidoe-list-span mr-2">{item.name}</span>
                  )
                })
              }
              <div className="row pt-4 pl-3 d-flex justify-content-between">
                <span className="e-webinar-span mt-4">{props.data.duration}</span> 
                <PrimaryBlueButton name="View More Details"/>   
              </div>                
            </div>
          </div>
          :
          null
        }
        </div>
         );
      }

export default LiveWebinar;
