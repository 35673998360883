/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Assets and Components*/
import devconfig from '../../config/config.js';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LazyLoaderGif from '../../components/common/lazyLoaderGif';
import SelectBox from '../../components/common/selectBox';
import InputBox from '../../components/common/inputBox';
import EditData from '../../components/popups/editLeadData';
import CSVReader from 'react-csv-reader';
import NoDataFound from '../../components/common/noData';
import {DateRangePicker, isInclusivelyBeforeDay} from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';

import noResultImg from '../../assets/images/no-result.png';
import PlaceholderStyleOneLarge from '../../components/placeholders/placeholderCol1Large.js';

class DataMain extends Component {
  notify = (e) => toast(e);
  constructor(props){
    super(props);
    this.state = {
      loading: true,
      selectedSort: {value: 6, label: "Created desc"},
      selectedProduct: {value: undefined, label: "All"},
      selectedPartner: {value: undefined, label: "All"},
      selectedLeadStatus: {value: undefined, label: "All"},
      productFilterDefault: {value: undefined, label: "All"},
      partnerFilterDefault: {value: undefined, label: "All"},
      sortDefault: {value: 6, label: "Created desc"},
      leadDefault: {value: 0, label: "New"},
      leadStatusDefault: {label: "All", value: undefined},
      pageNum: 1,
      productFilters: [],
      partnerFilters: [],
      data: [],
      errorMessage: [],
      itemToEdit: {},
      initalDate: moment().format(),
      startDate:  null,
      endDate: null ,
      dateFilter: false
    }
  }

  componentDidMount(){
    this.getFilters();
    this.getData();

    $(window).scroll(
        async () => {
          if (
            $(window).scrollTop() + $(window).height() >
            $(document).height() - 400
          ) {
            await this.setState({
              pageNum: this.state.pageNum + 1
            });
            await this.getData()
          }
        }
      );
  }

  getData = async () => {
    // this.setState({loading: true})
    if(this.state.dateFilter){
      var start_date_temp = this.state.startDate;
      var end_date_temp = this.state.endDate;
      if(this.state.startDate && this.state.endDate){
      var start_date = start_date_temp.subtract(1, 'days').format("YYYY-MM-DD");
      var end_date = end_date_temp.add(1, 'days').format("YYYY-MM-DD");
      start_date_temp.add(1, 'days').format("YYYY-MM-DD");
      end_date_temp.subtract(1, 'days').format("YYYY-MM-DD");
    }
    }else{
      var start_date = undefined
      var end_date = undefined
    }
    $.ajax({
        type: "POST",
        url: devconfig.adminV2 +'/data/list',
        dataType: 'json',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Token': this.state.accessToken
        },
        data: JSON.stringify({
          sort_by: this.state.selectedSort.value,
          page_num: this.state.pageNum,
          assignee: this.state.selectedProduct.value,
          partner_id: this.state.selectedPartner.value,
          lead_status: this.state.selectedLeadStatus.value,
          start_date: start_date,
          end_date: end_date,
        })
    }).then(function (response) {
        if (response.status) {
          var newData = [...this.state.data, ...response.data];
          this.setState({
            data: newData,
            loading: false
          })
        }else{
          this.notify(response.message);
        }
    }.bind(this))
  }

  searchData = async () => {
    // await this.setState({loading: true})
    $.ajax({
        type: "POST",
        url: devconfig.adminV2 +'/data/search',
        dataType: 'json',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Token': this.state.accessToken
        },
        data: JSON.stringify({
          query: this.state.searchQuery
        })
    }).then(function (response) {
        if (response.status) {
          // var newData = [...this.state.data, ...response.data];
          this.setState({
            data: response.data,
            loading: false
          })
        }else{
          this.notify(response.message);
        }
    }.bind(this))
  }

  getFilters = async () => {
    $.ajax({
        type: "GET",
        url: devconfig.adminV2 +'/data/filters',
        dataType: 'json',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Token': this.state.accessToken
        }
    }).then(function (response) {
        if (response.status) {
          var productFilters = response.assignedto_filters;
          productFilters.unshift({value: undefined, label: "All"});
          var partnerFilters = response.partner_filters;
          partnerFilters.unshift({value: undefined, label: "All"});
          this.setState({
            productFilters: productFilters,
            partnerFilters: partnerFilters,
          })
        }
    }.bind(this))
  }

  createModalClick = () => {}
  uploadBtnClick = () => {}
  uploadDocument = () => {}
  cancelModal = () => {}
  handleForce = (data) => { console.log(data); this.setState({csvData: data, csvLength: data.length}) }
  handleDarkSideForce = (err) => { console.log(err) }
  uploadData = () => {
    $.ajax({
        type: "POST",
        url: devconfig.adminV2 +'/data/upload-csv',
        dataType: 'json',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Token': this.state.accessToken
        },
        data: JSON.stringify({
          'data': this.state.csvData
        })
    }).then(function (response) {
        if (response.status) {
          this.setState({ file: response.cdn_url})
          $('#addcatgory').modal('hide');
        }else{
          this.notify(response.message);
        }
    }.bind(this))
  }

  sortChange = async (selectedSort) => {
    await this.setState({selectedSort: selectedSort, loading: true, pageNum: 1, data: []});
    await this.getData();
  }

  productFilterChange = async (selectedProduct) => {
    await this.setState({selectedProduct: selectedProduct, loading: true, pageNum: 1, data: []});
    await this.getData();
  }

  partnerFilterChange = async (selectedPartner) => {
    await this.setState({selectedPartner: selectedPartner, loading: true, pageNum: 1, data: []});
    await this.getData();
  }

  leadFilterChange = async (selectedLeadStatus) => {
    await this.setState({selectedLeadStatus: selectedLeadStatus, loading: true, pageNum: 1, data: []});
    await this.getData();
  }

  leadStatusChange = async (item, selectedPartner) => {
    $.ajax({
        type: "POST",
        url: devconfig.adminV2 +'/data/update-lead-status',
        dataType: 'json',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Token': this.state.accessToken
        },
        data: JSON.stringify({
          'id': item.id,
          'lead_status': selectedPartner,
        })
    }).then(function (response) {
        if (response.status) {
          if(selectedPartner.value===2){
            this.setState({itemToEdit: item});
            $("#e-edit-lead-data").modal();
          }
        }
        this.notify(response.message);
    }.bind(this)).fail((err, errTxt, errCode)=>{
      this.notify(errTxt);
    })
  }

  onDatesChange = async ({ startDate, endDate }) => {
    if(startDate && endDate){
      var fromDate = startDate.format("YYYY-MM-DD");
      var toDate = endDate.format("YYYY-MM-DD");
       }

      await this.setState({
        startDate,
        endDate,
        fromDate: fromDate,
        toDate: toDate,
        data: [],
        pageNum: 1,
        loading: true,
        dateFilter: true
      });

      await this.getData();
     }

  async searchChange(e, kopp){
    var query = e.target.value;
    await this.setState({searchQuery: query});

    if(query.length >= 3){
      await this.setState({data: [], loading: true})
      this.searchData();
    }
    if(query.length===0){
      await this.setState({loading: true, pageNum: 1, data: []});
      await this.getData();
    }
  }
  editRemarks = async (item) => {
    await this.setState({itemToEdit: item})
    $("#e-edit-lead-data").modal();
  }

  render() {
    const papaparseOptions = {
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      transformHeader: header =>
        header
          .toLowerCase()
          .replace(/\W/g, '_')
    }
    const { mainCatOption } = this.state;
    const sortOptions = [
      {value: 0, label: "Expiring soon"},
      {value: 1, label: "Follow-up soon"},
      {value: 2, label: "Subscription date asc"},
      {value: 3, label: "Subscription date desc"},
      {value: 4, label: "Last updated"},
      {value: 5, label: "New first"},
      {value: 6, label: "Old first"},
    ];
    const leadOptions = [
      {value: 0, label: "New"},
      {value: 1, label: "Contacted"},
      {value: 2, label: "Postponed"},
      {value: 3, label: "Subscribed"},
      {value: 4, label: "Lost"},
      {value: 5, label: "Other"},
    ]

    const leadOptionsFilter = [
      {value: undefined, label: "All"},
      {value: 0, label: "New"},
      {value: 1, label: "Contacted"},
      {value: 2, label: "Postponed"},
      {value: 3, label: "Subscribed"},
      {value: 4, label: "Lost"},
      {value: 5, label: "Other"},
    ]
    var productFilters = this.state.productFilters;
    var partnerFilters = this.state.partnerFilters;
    return (
      <div className="e-main-content e-subscription-wrap">
        <div className="container">
          <div className="row">
            <div className="col-md-6 e-align-left pb-3">

            </div>
            <div className="col-md-6 e-align-left pb-3 e-upload-csv">
              <span className="e-p-btn float-right" data-toggle="modal" data-target="#addcatgory" onClick={this.createModalClick.bind(this)}>Upload CSV</span>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-2">
              <div className="e-filter-wrap pt-2">
                <label>Date</label>
              </div>
              <DateRangePicker
                startDate={this.state.startDate}
                endDateId="e-endDate"
                startDateId="e-startDate"
                endDate={this.state.endDate}
                onDatesChange={this.onDatesChange}
                focusedInput={this.state.focusedInput}
                onFocusChange={focusedInput => this.setState({ focusedInput })}
                startDatePlaceholderText={"Start date"}
                endDatePlaceholderText={"End date"}
                disableScroll={false}
                hideKeyboardShortcutsPanel={true}
                regular={false}
                small
                numberOfMonths={1}
                isOutsideRange={day => !isInclusivelyBeforeDay(day, moment())}
              />
            </div>
            <div className="col-lg-2">
              <div className="e-filter-wrap pt-2">
                <label>Vendor</label>
                <SelectBox defaultValue={this.state.sortDefault} options={sortOptions} selectchange={this.sortChange} placeholder="Sort"/>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="e-filter-wrap pt-2">
                <label>Lead Status</label>
                <SelectBox defaultValue={this.state.leadStatusDefault} options={leadOptionsFilter} selectchange={this.leadFilterChange} placeholder="Filter"/>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="e-filter-wrap pt-2">
                <label>Subscribed from</label>
                <SelectBox defaultValue={this.state.sortDefault} options={sortOptions} selectchange={this.sortChange} placeholder="Sort"/>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="e-filter-wrap pt-2">
                <label>Sort by:</label>
                <SelectBox defaultValue={this.state.sortDefault} options={sortOptions} selectchange={this.sortChange} placeholder="Sort"/>
              </div>
            </div>
          <div className="col-lg-2">
              <div className="e-filter-wrap pt-2">
                <label>Filter by Assignee:</label>
                <SelectBox defaultValue={this.state.productFilterDefault} options={productFilters} selectchange={this.productFilterChange} placeholder="Assignee"/>
              </div>
            </div>


            <div className="col-lg-2 pt-4 mt-2">
              <div className="e-filter-wrap pt-2">
                <input className="e-dash-search e-search-subscription m-0" placeholder="Search for users" value={this.state.searchQuery} type="text" onChange={this.searchChange.bind(this)} required/>
              </div>
            </div>
          </div>
          </div>




          {/* <div className="modal fade e-modal-wrap" id="e-faqAdd" tabIndex="-1" role="dialog" aria-labelledby="e-faqAddLabel" aria-hidden="true">
            <div className="modal-dialog e-modal-editVendor" role="document">
              <div className="modal-content">
                <div className="text-right">
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body pt-2">
                  <div className="row mb-3">
                    <div className="col-lg-12">
                      <h5 className="pb-3">Add Faq</h5>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      {
                        this.state.reset ?
                          <InputBox label="Question" id="f-title" type="text" defaultValue={this.state.fTitle} valuechange={(tempValue) => this.setState({fTitle: tempValue, errorMessage: ""})}/>
                        :
                          null
                      }

                      {
                        this.state.reset ?
                          <Textarea label="Answer" id="f-body" type="text" defaultValue={this.state.fBody} valuechange={(tempValue) => this.setState({fBody: tempValue, errorMessage: ""})}/>
                        :
                          null
                      }

                    </div>
                    <div className="col-lg-4 mt-3">
                      {
                        this.state.fTitle.length && this.state.fBody.length && !this.state.btnClicked ?
                          <span className="e-p-btn" onClick={this.AddClick.bind(this)}>Add</span>
                        :
                          <span className="e-p-btn e-inActive">Add</span>
                      }
                    </div>
                    {
                      this.state.errorMessage.length ?
                        <div className="col-lg-12">
                          <div className="mt-4 text-danger">
                            {this.state.errorMessage}
                          </div>
                        </div>
                      :
                        null
                    }
                  </div>
                </div>
              </div>
            </div>
          </div> */}





          {/*Add Card Modal*/}
          <div className="modal fade e-modal-wrap" id="addcatgory" tabIndex="-1" role="dialog" aria-labelledby="addcatgoryLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                {/*<div className="modal-header">
                  <div className="text-right">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.cancelModal.bind(this)}>
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </div>*/}
                <div className="modal-body e-m-bottom-10">
                  <div className="row mb-3">
                    <div className="col-lg-12">
                      <h5 className="pb-3">Add Database</h5>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 e-m-bottom-20">
                      {
                        this.state.csvData ?
                          <span className="e-modal-img-uploaded-wrap e-inline-block">
                            <span>{this.state.csvLength} rows</span>
                          </span>
                        :
                          null
                      }
                      <CSVReader
                        cssclassName="e-ct-theme-img-upload-btn"
                        label="Click here to select .csv file"
                        onFileLoaded={this.handleForce}
                        onError={this.handleDarkSideForce}
                        parserOptions={papaparseOptions}
                        inputId="ObiWan"
                        inputStyle={{color: 'red'}}
                      />
                    </div>

                    <div className="col-lg-12 mt-3 text-right">
                      {/*
                        this.state.fTitle.length && this.state.fBody.length && !this.state.btnClicked ?
                          <span className="e-p-btn" onClick={this.AddClick.bind(this)}>Add</span>
                        :
                          <span className="e-p-btn e-inActive">Add</span>
                      */}

                      <span className="e-s-btn e-inline-block mr-4" data-toggle="modal" data-target="#addcatgory" onClick={this.cancelModal.bind(this)}>Cancel</span>
                      {
                        this.state.csvData && this.state.csvLength?
                          <span className="e-p-btn" onClick={this.uploadData.bind(this)}>Create</span>
                        :
                          <span className="e-p-btn e-inActive">Create</span>
                      }

                    </div>
                    {
                      this.state.errorMessage.length ?
                        <div className="col-lg-12">
                          <div className="mt-4 text-danger">
                            {this.state.errorMessage}
                          </div>
                        </div>
                      :
                        null
                    }


                  </div>
                </div>
                {/*
                  this.state.editModal ?
                    <div className="modal-footer">
                      <span className="e-s-btn e-m-right-10 e-inline-block" data-toggle="modal" data-target="#addcatgory" onClick={this.cancelModal.bind(this)}>Cancel</span>
                      {
                        this.state.file && this.state.catName ?
                          <span className="e-p-btn e-white" onClick={this.editCatgoryAPI.bind(this)}>Update</span>
                        :
                          <span className="e-p-btn e-white e-in-active">Update</span>
                      }
                    </div>
                  :
                    <div className="modal-footer">
                      <span className="e-s-btn e-m-right-10 e-inline-block" data-toggle="modal" data-target="#addcatgory" onClick={this.cancelModal.bind(this)}>Cancel</span>
                      {
                        this.state.csvData && this.state.csvLength?
                          <span className="e-p-btn e-white" onClick={this.uploadData.bind(this)}>Create</span>
                        :
                          <span className="e-p-btn e-white e-in-active">Create</span>
                      }
                    </div>
                */}

              </div>
            </div>
          </div>

          <EditData data={this.state.itemToEdit} />


          <ToastContainer position="bottom-left" autoClose={3000} hideProgressBar newestOnTop closeOnClick={false} rtl={false} pauseOnVisibilityChange={false} draggable={false} pauseOnHover/>


        <div className="container-fluid">
          {
            this.state.loading?
              <div className="row">
                <div className="col-lg-12 text-center pt-5">
                  <LazyLoaderGif/>
                </div>
              </div>
            :
              <div className="row mt-4">
              {
                this.state.data && this.state.data.length ?
                  <div className="col-md-12">
                    <table className="table e-subscription-table-wrap table-striped">
                      <thead>
                        <tr>

                          <th>Client Name</th>
                          <th>Product</th>
                          <th>Email</th>
                          <th>Phone</th>
                          <th>Subscr. from</th>
                          <th>Subscr. to</th>
                          <th>Amount</th>
                          <th>Transaction ID</th>
                          <th>Lead Status</th>
                          <th>Next Follow-up</th>
                          <th>Assigned to</th>
                          <th>Last Update</th>
                          <th>Created</th>
                          <th>Remarks</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          this.state.data.map((item, index)=>{
                            return(
                              <tr key={index}>
                                <td className="text-capitalize">{ item.clientname ? item.clientname.toLowerCase() : "--"}</td>
                                <td className="e-subscription-product">{item.productname}</td>

                                <td>
                                  <a href={"mailto:" + item.emailid} target="_blank">
                                    {item.emailid}
                                  </a>
                                </td>
                                <td>{item.mobilenumber}</td>

                                <td className="text-capitalize e-s-text">{item.subscriptionstartdate && item.subscriptionstartdate != "N/A" ? item.subscriptionstartdate : "--"}</td>
                                <td className="text-capitalize e-s-text">{item.subscriptionenddate && item.subscriptionenddate != "N/A" ? item.subscriptionenddate : "--"}</td>
                                 <td>399</td>
                                  <td>TID33333</td>
                                <td className="min-width-td">
                                  <SelectBox
                                    defaultValue={{value: item.leadstatus, label: item.leadstatustext}}
                                    placeholder="Lead status"
                                    selectchange={this.leadStatusChange.bind(this, item)}
                                    key={index} options={leadOptions} />
                                </td>
                                <td className="text-capitalize e-s-text">{item.nextfollowupdate && item.nextfollowupdate != "N/A" ? item.nextfollowupdate : "--"}</td>
                                <td className="text-capitalize">{item.assignedto ? item.assignedto : "--"}</td>
                                <td className="text-capitalize e-s-text">{item.lastupdateddate && item.lastupdateddate != "N/A" ? item.lastupdateddate : "--"}</td>
                                <td className="text-capitalize e-s-text">{item.created && item.created != "N/A" ? item.created : "--"}</td>
                                <td className="text-capitalize e-table-long-txt">
                                  {item.remarks ? item.remarks.toLowerCase() : "--"}
                                </td>
                                <td className="text-capitalize">
                                  <span
                                    className="e-nav-item"
                                    // data-toggle="modal"
                                    onClick={this.editRemarks.bind(this, item)}
                                    // data-target="#e-edit-lead-data"
                                    >
                                      <span className="e-p-btn d-inline-block">Edit</span>
                                  </span>
                                </td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                  </div>
                :
                  <NoDataFound data="No subscriptions found."/>
              }
              </div>
          }
        </div>
      </div>
    );
  }
}

export default DataMain;
