/*Package importing*/
import React, { Component } from 'react';

/*Assets and Components*/
import APIGet from '../../components/apiCall/apiGet';
import devconfig from '../../config/config.js';
import VendorCard from '../../components/card/vendorCard';
import NoDataFound from '../../components/common/noData';
import PlaceholderStyleOneSmall from '../../components/placeholders/placeholderCol1Small';
import SelectBox from '../../components/common/selectBox';

class ActiveSubAdmins extends Component {
  constructor(props){
    super(props);
    this.state = {
      data: [],
      dataLoading: true,
      blockedUsers: [],
      typeFilterDefault:{value: 1, label: 'Active'},
      blockedActive: false
    }
  }

  componentWillMount() {
    this.listData();
  }

  listData(){
    var url = devconfig.adminV1+"/subadmin/list";

    APIGet(url).then(function(response){
      if(response.status === "ok"){
        this.setState({data: response.data, blockedUsers: response.blocked, dataLoading: false});
      }
      else{
        this.setState({errorMessage: response.message, dataLoading: false});
      }
    }.bind(this))
  }

  cardClick(tempID){
    this.props.detailClick(tempID);
  }

  selectTypeChange(tempItem){
    if(tempItem.value === 2){
      this.setState({blockedActive: true});
    }
    else{
      this.setState({blockedActive: false});
    }
  }

  render() {
    const options = [
      { value: 1, label: 'Active' },
      { value: 2, label: 'Blocked' }
    ]
    return (
      <div className="col-lg-9">
        {
          this.state.dataLoading ?
            <div className="row">
              <div className="col-lg-6">
                <PlaceholderStyleOneSmall/>
              </div>
              <div className="col-lg-6">
                <PlaceholderStyleOneSmall/>
              </div>
            </div>
          :
            <div className="e-partnered-wrap mb-5">
              <h2 className="mb-4">Sub Admins</h2>

                {
                  this.state.blockedActive ?
                    <div>
                      {
                        this.state.blockedUsers.length ?
                          <div className="row">
                            {
                              this.state.blockedUsers.map(function(item, index){
                                return(
                                  <VendorCard noLink={true} data={item} key={index} cardClickSuccess={this.cardClick.bind(this)}/>
                                )
                              }.bind(this))
                            }
                          </div>
                        :
                          <div className="row">
                            <NoDataFound data="No vendors data found."/>
                          </div>
                      }
                    </div>
                  :
                    <div>
                      {
                        this.state.data.length ?
                          <div className="row">
                            {
                              this.state.data.map(function(item, index){
                                return(
                                  <VendorCard noLink={true} data={item} key={index} cardClickSuccess={this.cardClick.bind(this)}/>
                                )
                              }.bind(this))
                            }
                          </div>
                        :
                          <div className="row">
                            <NoDataFound data="No vendors data found."/>
                          </div>
                      }
                    </div>
                }
            </div>
        }
      </div>
    );
  }
}

export default ActiveSubAdmins;
