/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Import custom components and assets*/
import SelectBox from '../../components/common/selectBox';
import InputBox from '../../components/common/inputBox';
import Textarea from '../../components/common/textarea';
import APIPost from '../../components/apiCall/apiPost';
import  ProductDetail from '../../container/detail/main';
import devconfig from '../../config/config.js';
import 'react-dates/initialize';
import { SingleDatePicker} from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import GetURLVar from '../../services/apiCall/getParams/get-url.js'; 

/*Assets*/
// import AddLogo from '../../assets/images/add-logo.svg';

import Close from '../../assets/images/close-btn.svg';



class AddFaq extends Component {
  constructor(props){
    super(props);
    this.state = {
      faq: [{question: null, answer: null} ]
    }
  }



  hideLogin() {
  $('.e-login').removeClass('e-login-fly');
  $('#e-log-overlay').remove();
  }
    componentDidMount(){
    GetURLVar().then(function(query){ 
      this.setState({id: query.id});
    }.bind(this));
    
  }
  addFAQ=()=>{
    console.log(this.state.faq);
    var url = devconfig.adminV2+"/faq/add";
    var data = JSON.stringify({
      "product_id": this.state.id,
      "faq_data": this.state.faq
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        this.props.confirmSuccess(response.message);
        this.hideLogin();
      }
      else{
        this.setState({error: response.message})
      }
    }.bind(this))
    
  }

  render() {

    return(

      <div className="container text-left e-login e-add-faq e-add-product-modal">
        <div className="row">
          <div className="col-lg-12 pl-5 pr-5">
            <div className="row mb-3 e-modal-border">
              <div>
                <button className="e-login-closebtn">
                  <img src={Close} alt="close button" className="e-toggle" onClick={this.hideLogin.bind(this)}/>
                </button>
              </div>
              <div className="col-lg-4 pt-5 mt-3">
                <h5 className="">Add FAQs</h5>
              </div>
              <div className="col-lg-7 pt-5 mt-3 ml-5">
               <span className="e-save e-p-btn float-right" data-toggle="modal" onClick={this.addFAQ} data-target="#e-addProduct">Save & Add FAQs</span>
              </div>
            </div>
            {
              this.state.error ?

              <div className="row pt-4">
                <div className="col-lg-6"> 
                  <div className="e-error mb-3">{this.state.error}</div>
                </div>
              </div>

              :
                null

            }
            <div className="row pt-3">
              
              <div className="col-lg-12">
                <label>ADD QUESTIONS</label>
              </div>
              
                {
                  this.state.faq.map((item, index)=>{
                  return(
                    <React.Fragment>
                      <div className="col-lg-7">
                        <InputBox placeholder={"Question"+ " " + (index+1)} 
                          value={this.state.faq[index].question}
                          onChange={(e) => {
                          var tempArry = this.state.faq;
                          tempArry[index].question=e.target.value;
                          this.setState({faq: tempArry}) 
                        }} />
                      </div>
                      <div className="col-lg-7">
                        <Textarea placeholder="Answer"
                        value={this.state.faq[index].answer}
                        onChange={(e) => {
                          var tempArry = this.state.faq;
                          tempArry[index].answer=e.target.value;
                          this.setState({faq: tempArry}) 
                        }} />
                      </div>
                    </React.Fragment>
                  )
                })
              }
 
            </div>
            <div className="row pt-3">
              <div className="row pt-3"> <div className="col-lg-12"> <span
              className="e-add-product-link" onClick={(e) => {
               var tempArray = this.state.faq;  
              tempArray.push({question: null, answer: null }); 
              this.setState({faq: tempArray}) 
              }}> <a className="e-link">+ Add another Question</a> </span> </div> </div>
            </div>
          </div>

      </div>
    </div>

    )
  }
}

export default AddFaq;                 