/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

/*Import custom components and assets*/
import SelectBox from '../../components/common/selectBox';
import InputBox from '../../components/common/inputBox';
import Textarea from '../../components/common/textarea';
import APIPost from '../../components/apiCall/apiPost';
import APIGet from '../../components/apiCall/apiGet';
import  ProductDetail from '../../container/detail/main';
import devconfig from '../../config/config.js';
import ImageUploader from '../../components/common/imageUploader';

/*Assets*/
// import AddLogo from '../../assets/images/add-logo.svg';
import AddIllustration from '../../assets/images/add-illustartion.svg';
import AddVideo from '../../assets/images/video.svg';
import Close from '../../assets/images/close-btn.svg';
import Img from '../../assets/images/image.svg';
import Delete from '../../assets/images/delete-black.svg';



class AddArticle extends Component {
  constructor(props){
    super(props);
    this.state = {
      products:null,
      selectedProduct:[],
      sections: [{content: null, description: null }],
      image: null,
      authImage: null,
      authName: null,
      bio: null,
      blogTitle: null,
      introduction: null,
      description: [],
    }
  }
  componentDidMount(){
   this.getProduct();
  }

  addBlog=()=>{
    console.log(this.state.sections)
   var url = devconfig.adminV3+"/resources/add-blog";
    var data = JSON.stringify({
      "product_ids": this.state.selectedProduct,
      "title" : this.state.blogTitle,
      "image": this.state.image,
      "introduction" : this.state.introduction,
      "author_name" : this.state.authName,
      "author_bio" : this.state.bio,
      "author_image" : this.state.authImage,
      "meta_description" : this.state.meta_desc,
      "meta_keyword" : this.state.keyword,
      "sections" : this.state.sections,
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        this.props.triggerToast(response.message);
        this.hideLogin();

      }
      else{
        this.setState({error: response.message});
      }

    }.bind(this))
       }

  getProduct=()=>{
    var url = devconfig.adminV3+"/products/product-names";
    APIGet(url).then(function(response){
      console.log(response);
      if(response.status === "ok"){
         this.setState({products: response.data});
      }
    }.bind(this))
  }
  async uploadStatus(status){
    this.setState({uploading: status})
  }
   async ImguploadStatus(status){
    this.setState({imgUploading: status})
  }
  logoUploaded(tempData){
    console.log(tempData);
    this.setState({image: tempData})
    console.log(this.state.image)
  }
  AuthlogoUploaded(tempData){
    console.log(tempData);
    this.setState({authImage: tempData})
    console.log(this.state.authImgLoader)
  }
  async logoUploadTrigger(tempType){
    await this.setState({imgLoader: tempType});
    $("#e-prod-logo-upload").click()
  }
  async AuthUploadTrigger(tempType){
    await this.setState({authImgLoader: tempType});
    $("#e-prod-auth-upload").click()
  }
  handleSelect(tempVal){
    this.setState({ selectedProduct: Array.isArray(tempVal)  ? tempVal.map(x => x.value) : []});
  }

  hideLogin=()=> {
  $('.e-login2').removeClass('e-login-fly');
  $('#e-log-overlay2').remove();
  }



  render() {
    var data= "";
    const options = this.state.products ?

        this.state.products.map((item, index) => {
        return(
         { value: item.id, label: item.name }
        );
      })
    :
      null

    return(

      <div className="container text-left e-login2 e-add-product-modal pb-4">
        <div className="row ">
          <div className="col-lg-12 pl-5 pr-5">
            <div className="row mb-3 e-modal-border">
              <div>
                <button className="e-login-closebtn">
                  <img src={Close} alt="close button" className="e-toggle" onClick={this.hideLogin.bind(this)}/>
                </button>
              </div>
              <div className="col-lg-4 pt-5 mt-3">
                <h5 className="">Add New Blog</h5>
              </div>
              <div className="col-lg-7 pt-5 mt-3 ml-5">
              {
                this.state.selectedProduct[0] ?
               <span className="e-save e-p-btn float-right" onClick={this.addBlog} data-toggle="modal" data-target="#e-addProduct">Save & Add Article</span>
               :
               <span className="e-save e-p-btn float-right e-btn-inactive" >Save & Add Article</span>
              }
              </div>
            </div>
            {
              this.state.error ?

              <div className="row pt-4">
                <div className="col-lg-6">
                  <div className="e-error mb-3">{this.state.error}</div>
                </div>
              </div>

              :
                null

            }
            <div className="row pt-3">
              <div className="col-lg-6 e-select-product">
                <label>Product</label>
                <SelectBox placeholder="Select the product" options={options} selectType={true} selectchange={(selectedValue) => this.handleSelect(selectedValue)}/>
              </div>
            </div>
             <div className="row pt-3">
              <div className="col-lg-12">

                <div className="row">

                  <div className="col-lg-2 position-relative">
                  <label>upload banner image</label>
                  {
                    this.state.imgLoader == 1 && this.state.uploading ?
                        <span className="e-img-upload-loader"></span>
                    :
                      <React.Fragment>
                        {
                          this.state.image ?
                            <span className="e-img-preview">
                              <img src={this.state.image}/>
                              <span className="e-change" onClick={()=>{ this.setState({image: null}) }}></span>
                            </span>
                          :
                            <React.Fragment>
                              <span className="e-upload-btn e-store text-center mb-3" onClick={this.logoUploadTrigger.bind(this, 1)}><span className="mt-2">Add image</span></span>
                           </React.Fragment>
                        }
                      </React.Fragment>
                  }
                  <ImageUploader
                    accept="application/pdf, image/*"
                    className="hidden"
                    tempKey="/user/address-proof"
                    id="e-prod-logo-upload"
                    uploadStatus={this.uploadStatus.bind(this)}
                    uploadSucess={this.logoUploaded.bind(this)}
                  />
                  </div>
                  <div className="col-lg-8">
                  <label>AUTHOR DETAILS</label>
                    <InputBox placeholder="Name" value={this.state.authName} onChange={(e) => {this.setState({authName: e.target.value})}}/>
                    <Textarea placeholder="Bio" className="mt-4 e-bio" value={this.state.bio} onChange={(e) => {this.setState({bio: e.target.value})}}/>
                  </div>
                   <div className="col-lg-2 position-relative">
                   <label>UPLOAD AUTHOR IMAGE</label>
                  {
                    this.state.authImgLoader == 1 && this.state.imgUploading ?
                        <span className="e-img-upload-loader"></span>
                    :
                      <React.Fragment>
                        {
                          this.state.authImage ?
                            <span className="e-author-preview">
                              <img src={this.state.authImage}/>
                              <span className="e-change" onClick={()=>{ this.setState({authImage: null}) }}></span>
                            </span>
                          :
                            <React.Fragment>
                              <span className="e-author-upload e-upload-btn e-store text-center mb-3" onClick={this.AuthUploadTrigger.bind(this, 1)}><span className="mt-2">Add image</span></span>
                           </React.Fragment>
                        }
                      </React.Fragment>
                  }
                  <ImageUploader
                    accept="application/pdf, image/*"
                    className="hidden"
                    tempKey="/user/address-proof"
                    id="e-prod-auth-upload"
                    uploadStatus={this.ImguploadStatus.bind(this)}
                    uploadSucess={this.AuthlogoUploaded.bind(this)}
                  />
                  </div>
              </div>
              </div>
            </div>
            <div className="row pt-3">
            <div className="col-lg-12">
              <label>Blog Title & META</label>
            </div>
              <div className="col-lg-6">
                <InputBox placeholder="Title" maxlength="150" value={this.state.blogTitle} onChange={(e) => {this.setState({blogTitle: e.target.value})}}/>
              </div>
              <div className="col-lg-6">
                <InputBox placeholder="Keywords (use commas for seperate keywords Eg: Trade, Strategy) " value={this.state.keyword} onChange={(e) => {this.setState({keyword: e.target.value})}}/>
              </div>
              <div className="col-lg-12">
                <Textarea placeholder="Meta Description(160 Characters)"
                  value={this.state.meta_desc}
                  onChange={(e) => {this.setState({meta_desc: e.target.value})}}
                  maxlength="160"

                />
              </div>
            </div>
            <div className="row pt-3">
              <div className="col-lg-12">
                <label>Introduction</label>
                <Textarea 
                placeholder="Introduction"
                value={this.state.introduction}
                onChange={(e) => {
                  this.setState({introduction: e.target.value})
                }} />
              </div>
            </div>
            <React.Fragment>
            {
              this.state.sections.map((item, index)=>{
                return(
              <React.Fragment key={index}>
              <div className="row pt-4">
              <div className="col-lg-12">
                <label className="">{"Section"+  (index+1)}</label>
                <InputBox placeholder="Section Title"
                value={this.state.sections[index].content}
                      onChange={(e) => {

                          var tempArry = this.state.sections;
                          tempArry[index].content=e.target.value;
                          this.setState({sections: tempArry})
                        }}/>
              </div>
            </div>
            <div className="row pt-3">
              <div className="col-lg-12">
                <label className="">Section Content</label>
                {/*<Textarea placeholder="Section content..."
                                value={this.state.sections[index].description}
                                      onChange={(e) => {
                                          var tempArry = this.state.sections;
                                          tempArry[index].description=e.target.value;
                                          this.setState({sections: tempArry})
                                        }} />*/}
                <CKEditor
                  editor={ ClassicEditor }
                  // data={this.state.introduction}
                  onChange={(evt , editor) =>{
                  var tempArry = this.state.sections;
                  tempArry[index].description=editor.getData();
                  this.setState({sections: tempArry})
                }}
              />
                  <hr className="my-4"/>

              </div>
            </div>
            </React.Fragment>
            )
              })
            }

            </React.Fragment>
              <div className="row pt-4"> <div className="col-lg-12"> <span
              className="e-add-product-link" onClick={(e) => {
               var tempArray = this.state.sections;
              tempArray.push({content: null, description: null });
              this.setState({videoData: tempArray})
              }}> <a className="e-link  pb-4">+ Add New Section</a> </span> </div> </div>
              </div>
            </div>
          </div>



    )
  }
}

export default AddArticle;
