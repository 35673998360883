
import React from 'react';

import ApiLoader from '../../components/Loaders/ApiLoader';

// import services
import APIPost from '../../components/apiCall/apiPost';
import APIGet from '../../components/apiCall/apiGet';
import devconfig from '../../config/config.js';
import GetURLVar from '../../services/apiCall/getParams/get-url';
// import { responsiveFontSizes } from '@material-ui/core';
import bank from '../../assets/images/bank.svg';
import NoDataFound from '../../components/common/noData';

export default class extends React.Component{
    constructor(props){
        super(props);
        this.state={

        }
    }

    componentDidMount(){
        GetURLVar().then((query)=>{
            if(query.id){
                this.setState({id: query.id});
                this.getDetail(query.id);
                this.getLeder(query.id);
            }
        })
    }

    getDetail = (temp) =>{
        this.setState({ loader: true})
        let url = devconfig.adminV3+'/partner/detail';
        let data =JSON.stringify({
            "partner_id" : temp
        })
        APIPost(url, data).then((responce)=>{
            if(responce.status){
                this.setState({loader: false, data: responce.data})
            }
            else{
                this.setState({ loader: false})
            }
        })
    }
    getLeder = (temp) =>{
        this.setState({ledgerLoading: true});
        let url = devconfig.adminV3+"/partner/detail/ledger-list";
        let data = JSON.stringify({
            "partner_id" : temp
        })
        APIPost(url, data).then((response)=>{
            if(response.status){
                this.setState({ ledgerLoading: false, ledger: response.data});
            }
            else{
                this.setState({ ledgerLoading: false, ledger: null});
            }
        })
    }

    downloadLedger = () =>{
        let url = devconfig.adminV3+'/partner/detail/ledger-download';
        let data = JSON.stringify({
            "partner_id" : this.state.id
        })
        APIPost(url, data).then((response)=>{
            if(response.status){
                window.open(response.data, '_blank')
            }
        })
    }
    render(){
        return(
            <>
            {
               this.state. loader ?
                    <ApiLoader/>
                :
                <div className="container py-5">
                <div className="row">
                   <div className="col-lg-6">
                       <div className="card border-0 e-profile-card pt-4">
                           <div className="row no-gutters">
                               <div className="col-lg-2 mt-3 text-center">
                               <span className=" e-header-shortname rounded-circle e-partner-placeholder">{this.state.data && this.state.data.name[0]}</span>
                               </div>
                               <div className="col-lg-8">
                               <h6>{this.state.data &&  this.state.data.name}</h6>
                               <p className="mt-3 e-profile-phone">Email: {this.state.data && this.state.data.email}</p>
                               <p className="">phone: {this.state.data && this.state.data.mobile}</p>
                               </div>
                               <div className="col-lg-2 mt-5 pt-2 text-right">
                               {/*<button className="e-edit-btn" onClick={this.editProfile}> Edit</button>*/}
                               </div>
                           </div>
                       </div>
                       </div>
                       <div className="col-lg-6">
                           {
                               this.state.data && this.state.data.account_added ?
                               <div className="card border-0 e-profile-card pt-4">
                                    <div className="row">
                                    <div className="col-lg-2 mt-2 text-center">
                                        <img src={bank}/>
                                    </div>
                                        <React.Fragment>
                                        <div className="col-lg-8">
                                            <h6>{this.state.data && this.state.data.bank_info.bank}</h6>
                                            <p className="mt-3 e-profile-phone">Account no: {this.state.data && this.state.data.bank_info.account_no}</p>
                                            <p className="">IFSC: {this.state.data && this.state.data.bank_info.ifsc_code}</p>
                                            
                                        </div>
                                        <div className="col-lg-2 text-right mt-5 pt-2">
                                        {/* <button className="e-edit-btn" onClick={this.edit}> Edit</button> */}
                                        </div>
                                        </React.Fragment>
                                    </div>
                                </div>
                            :
                            <div className="card border-0 e-profile-card  pt-4">
                                <h6 className="e-dull-color mt-4">Bank details not found</h6>
                            </div>
                           }
                           
                           </div>
                       </div>
                       <div className="row mt-4">
                            <div className="col-lg-3 mb-4">
                                <div className="card border-0 e-profile-card p-4">
                                <h5 className="e-paid-card">Reffered Products</h5>
                                <div className="mt-3 text-right e-card-amount"> {this.state.data && this.state.data.referred_products}</div>
                                </div>
                            </div>
                            <div className="col-lg-3 mb-4">
                                <div className="card border-0 e-profile-card p-4">
                                <h5 className="e-paid-card">TDS Percentage</h5>
                                <div className="mt-3 text-right e-card-amount">% {this.state.data && this.state.data.tds_percentage}</div>
                                </div>
                            </div>
                            <div className="col-lg-3 mb-4">
                                <div className="card border-0 e-profile-card p-4">
                                <h5 className="e-paid-card">Total Commission Earned</h5>
                                <div className="mt-3 text-right e-card-amount">₹ {this.state.data && this.state.data.total_commission_earned}</div>
                                </div>
                            </div>
                            <div className="col-lg-3 mb-4">
                                <div className="card border-0 e-profile-card p-4">
                                    <h5 className="e-paid-card">Clients Joined</h5>
                                    <div className="mt-3 text-right e-card-amount"> {this.state.data && this.state.data.clients_joined}</div>
                                </div>
                            </div>
                       </div>
                       <div className="row mt-4">
                           <div className="col-lg-6">
                               <h6 className="pb-4">Ledger</h6>
                           </div>
                           <div className="col-lg-6 text-right">
                           <a className="e-download-link " onClick={this.downloadLedger}  target="_blank">Download CSV</a>
                           </div>
                           <div className="col-lg-12">
                               {
                                   this.state.ledgerLoading ?
                                         <ApiLoader/>
                                    :
                                        !this.state.ledgerLoading && !this.state.ledger ?
                                        <NoDataFound data="Ledger details not found!"/>
                                    :
                                    <table id="example" className="table e-subscription-table-wrap table-striped e-payout-table">
                                    <thead>
                                    <tr>
                                        <th>Description</th>
                                        <th>Date</th>
                                        <th>Debit</th>
                                        <th>Credit</th>
                                        <th>Balance</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                    this.state.ledger &&  this.state.ledger.map(function(item, index){
                                        var spanClass = "e-s-btn e-resend-"+item.id;
                                        var spanSelector = ".e-resend-"+item.id;
                                        return(
                                            <tr key={index} >
                                            <td onClick={()=> window.location.href="/ledger?id="+item.id}>{item.description}</td>
                                            <td onClick={()=> window.location.href="/ledger?id="+item.id}>{item.date ? item.date: '--'}</td>
                                            <td onClick={()=> window.location.href="/ledger?id="+item.id}> {item.debit ?  item.debit :'--'}</td>
                                            <td onClick={()=> window.location.href="/ledger?id="+item.id}>{item.credit ? item.credit : '--' }</td>
                                            <td onClick={()=> window.location.href="/ledger?id="+item.id}> {item.closing ? '₹ '+item.closing : '--' }</td>
                
                                            
                                            </tr>

                                        )
                                        }.bind(this))

                                    }
                                    </tbody>
                                </table>
                               }
                           
                           </div>
                       </div>
                </div>
            }
            </>
        )
    }
}