import React from 'react';
import $ from 'jquery';

// import services
import APIPost from '../../components/apiCall/apiPost';
import APIGet from '../../components/apiCall/apiGet';
import devconfig from '../../config/config.js';
// import components
import SelectBox from '../../components/common/selectBox';
import InputBox from '../../components/common/inputBox';
import NoDataFound from '../../components/common/noData';
import {DateRangePicker, isInclusivelyBeforeDay} from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import LazyLoaderGif from '../../components/common/lazyLoaderGif';
import moment from 'moment';
import Toast from '../../components/popups/Toast';
// import assets
import Linkarrow from '../../assets/images/selectArrow/linkarrow.svg';


export default class allUsers extends React.Component{
	constructor(props){
		super(props);
		this.state={
      pageNum: 1,
      startDate: moment(),
      endDate: moment(),
      rowClick: false,
      filterChange: false,
      data: [],

		}
	}

  componentDidMount(){
    this.getUsers();
    $(window).scroll(
        async () => {
          if (
            $(window).scrollTop() + $(window).height() >
            $(document).height() - 400
          ) {
            await this.setState({
              pageNum: this.state.pageNum + 1
            });
            await this.getUsers()
          }
        }
      );
  }


  getUsers=()=>{
      var start_date_temp = this.state.startDate;
      var end_date_temp = this.state.endDate;
      if(this.state.startDate && this.state.endDate){
      var start_date = start_date_temp.format("DD-MM-YYYY");
      var end_date = end_date_temp.format("DD-MM-YYYY");
      start_date_temp.format("dd-mm-yyyy");
      end_date_temp.format("dd-mm-yyyy");
    }
    // this.setState({loading: true});
    var url = devconfig.adminV3+"/user/list";
    var data = JSON.stringify({
      "page_num": parseInt(this.state.pageNum),
      "sort_by" : parseInt(this.state.selectedSort),
      "start_date" : start_date,
      "end_date" : end_date
    });
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        var temdta =[];

        if(this.state.data.length && !this.state.filterChange ){
          temdta = this.state.data;
          temdta = $.merge(temdta, response.data);
        }
        else{
          temdta = response.data;
        }
        this.setState({data: temdta, loading: false, filterChange: false});

      }
      else{
        this.setState({ loading: false, filterChange: false});
      }
    }.bind(this))
 }

	onDatesChange = async ({ startDate, endDate }) => {

    if(startDate && endDate){
      var fromDate = startDate.format("YYYY-MM-DD");
      var toDate = endDate.format("YYYY-MM-DD");

      await this.setState({
        startDate,
        endDate,
        fromDate: fromDate,
        toDate: toDate,
        pageNum: 1,
        loading: true,
        filterChange: true
      });

      await this.getUsers();
    }
  }

  sortChange = async (selectedSort) => {
    await this.setState({selectedSort: selectedSort.value, loading: true, pageNum: 1, filterChange: true});
    await this.getUsers();
  }

  downloadCSV=()=>{
     var start_date_temp = this.state.startDate;
      var end_date_temp = this.state.endDate;
      if(this.state.startDate && this.state.endDate){
      var start_date = start_date_temp.format("DD-MM-YYYY");
      var end_date = end_date_temp.format("DD-MM-YYYY");
      start_date_temp.format("dd-mm-yyyy");
      end_date_temp.format("dd-mm-yyyy");
    }
    var url = devconfig.adminV3+'/user/download';
    var data = JSON.stringify({

          page_num: this.state.pageNum,
          start_date: start_date,
          end_date: end_date,
          sort_by: this.state.selectedSort
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        this.setState({downloadList:response.file_url});
        if(response.file_url != null){
        window.open(response.file_url);
      }else{
        this.setState({toast: "No data found for download", toastType: "fail"});
        setTimeout( function() { this.setState({ toast: null}); }.bind(this), 4000);
      }
      }
      else{
      }
    }.bind(this))
  }

  searchAPI(){
    this.setState({dataLoading: true, filterChange: true});
    var url = devconfig.adminV3+"/user/search";
    var data = JSON.stringify({
      "text": this.state.searchQuery,
    })
    APIPost(url, data).then(function (response) {
       // var temdta = [];
       if(response.status){


        this.setState({ data: response.data, dataLoading: false, filterChange: false});
      }
      else{
        this.setState({ dataLoading: false, filterChange: false});
      }
    }.bind(this))
  }

  async searchChange(e, kopp){
    var query = e.target.value;
    await this.setState({searchQuery: query});

    if(query.length >= 3){
      await this.setState({data: [], loading: true, filterChange: true})
      this.searchAPI();
    }
    if(query.length===0){
      await this.setState({loading: true, pageNum: 1, data: [], filterChange: true});
      await this.getUsers();
      // await this.downloadCSV();
    }
  }
  viewDetails=(temp_Id)=>{
    this.setState({rowClick: true,loading: true});
    var url = devconfig.adminV3+"/user/detail";
    var data = JSON.stringify({
      "user_id": parseInt(temp_Id),
    })
    APIPost(url, data).then(function (response) {
       if(response.status){


        this.setState({ userData: response.data, loading: false});
      }
      else{
        this.setState({ loading: false});
      }
    }.bind(this))

  }

	render(){

		const sortOptions = [
      {value: 1, label: "New users first"},
      {value: 2, label: "Referral High to low"},
      {value: 3, label: "Wallet balance high to low"},
    ];
   	   	// this.state.products ?

    //     this.state.products.map((item, index) => {
    //     return(
    //      { value: item.id, label: item.name }
    //     );
    //   })
    // :
    //   null
		return(
			<div className="e-main-content e-subscription-wrap e-all-users">
      {
       ! this.state.rowClick ?
       <div className="container">
        <div className="row mt-4">
          <div className="col-lg-12">
            <span className="e-tabs-item e-active pr-4">All Users</span>
          </div>
          <div className="col-lg-12">
            <div className="row mt-4">
            <div className="col-lg-4">
              <div className="e-filter-wrap pt-2">
                <label>Date</label>
              </div>
              <DateRangePicker
                startDate={this.state.startDate}
                endDateId="e-endDate"
                startDateId="e-startDate"
                endDate={this.state.endDate}
                onDatesChange={this.onDatesChange}
                focusedInput={this.state.focusedInput}
                onFocusChange={focusedInput => this.setState({ focusedInput })}
                startDatePlaceholderText={"Start date"}
                endDatePlaceholderText={"End date"}
                disableScroll={false}
                hideKeyboardShortcutsPanel={true}
                regular={false}
                small
                numberOfMonths={1}
                isOutsideRange={() => false}
              />
            </div>
            <div className="col-lg-2"></div>
            <div className="col-lg-2">
              <div className="e-filter-wrap pt-2">
                <label>Sort by:</label>
                <SelectBox defaultValue={this.state.sortDefault} options={sortOptions} selectchange={this.sortChange} placeholder="Sort"/>
              </div>
            </div>


            <div className="col-lg-2 pt-4 mt-2">
              <div className="e-filter-wrap pt-2">
                <input className="e-dash-search e-search-subscription m-0" placeholder="Search for users" value={this.state.searchQuery} type="text" onChange={this.searchChange.bind(this)} required/>
              </div>
            </div>
            <div className="col-lg-2 pt-4 mt-2">
              <div className="e-filter-wrap pt-2 e-hover">
              <a className="e-download-link mr-5" onClick={this.downloadCSV}  target="_blank">Download CSV</a>
              </div>
            </div>

          </div>
          </div>
        </div>
      </div>
      :
      <div className="container pt-4">
        <a href="all-users" className="e-alluser-link">All Users</a>
        <img src={Linkarrow} alt="arrow" className="mx-2"/>
        <span className="e-detail">Details</span>
      </div>


      }


			<div className="container pt-3">
          {
            this.state.loading?
              <div className="row">
                <div className="col-lg-12 text-center pt-5">
                  <LazyLoaderGif/>
                </div>
              </div>
            :
              <div className="row mt-4">
              {
                this.state.data && this.state.data.length && !this.state.rowClick ?
                  <div className="col-md-12">
                    <table className="table  table-striped">
                      <thead>
                        <tr>
                          <th>Client ID</th>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Phone</th>
                          <th>Refferals</th>
                          <th>Wallet Balance</th>

                        </tr>
                      </thead>
                      <tbody>
                        {
                          this.state.data.map((item, index)=>{
                            return(
                              <tr key={index} className="e-pointer-row" onClick={()=>{this.viewDetails(item.user_id)}}>
                                <td className="text-capitalize">{item.alice_blue_id}</td>
                                <td className="text-capitalize">{ item.name ? item.name.toLowerCase() : "--"}</td>


                                <td>
                                  <a href={"mailto:" + item.email} target="_blank">
                                    {item.email}
                                  </a>
                                </td>
                                <td className="text-capitalize e-s-text">{item.mobile}</td>
                                <td className="text-capitalize e-s-text">{item.total_referrals}</td>
                                <td className="text-capitalize e-s-text">{item.wallet_balance}</td>

                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                  </div>
                :
                this.state.rowClick && this.state.userData?
                <React.Fragment>
                <div className="col-lg-6">
                  <div className="card border-0 e-profile-card p-3">
                    <div className="row">
                      <div className="col-lg-2 mt-3 text-center">
                        <span className=" e-header-shortname">AJ</span>
                      </div>
                      <div className="col-lg-10">
                        <h6>{this.state.userData.name}</h6>
                        <p className="mt-3 e-profile-phone">Email: {this.state.userData.email}</p>
                        <p className="">phone: {this.state.userData.mobile}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2">
                  <div className="card border-0 e-profile-card p-3">
                    <h5 className="e-paid-card">PRODUCTS</h5>
                   <div className="mt-5 text-right e-card-amount">{this.state.userData.owned_products_count}</div>
                  </div>
                </div>
                <div className="col-lg-2">
                  <div className="card border-0 e-profile-card p-3">
                    <h5 className="e-paid-card">REFFERALS</h5>
                   <div className="mt-5 text-right e-card-amount">{this.state.userData.total_referrals}</div>
                  </div>
                </div>
                <div className="col-lg-2">
                  <div className="card border-0 e-profile-card p-3">
                    <h5 className="e-paid-card">WALLET BAL</h5>
                   <div className="mt-5 text-right e-card-amount">₹ {this.state.userData.wallet_balance}</div>
                  </div>
                </div>
              <div className="col-md-12 mt-4">
                                   <table className="table  table-striped">
                                     <thead>
                                       <tr>
                                         <th >Product Name</th>
                                         <th>Joined Leads</th>
                                         <th>Refferal Earnings</th>

                                       </tr>
                                     </thead>
                                   <tbody>
                                                          {
                                                            this.state.userData.referral_data.map((item, index)=>{
                                                              return(
                                                                <tr key={index} className="e-pointer-row">
                                                                  <td className="text-capitalize">{item.product_name }</td>
                                                                  <td className="text-capitalize">{ item.joined_leads }</td>
                                                                  <td className="text-capitalize e-s-text">{item.earned_commission}</td>

                                                                </tr>
                                                              )
                                                            })
                                                          }
                                                        </tbody>
                                   </table>
                                 </div>
                </React.Fragment>
                :
                  <NoDataFound data="No User found."/>
              }
              </div>
          }
           {
              this.state.toast ?
                <Toast data={this.state.toast} type={this.state.toastType}/>
              :
              null
             }
        </div>
			</div>
		);
	}
}