/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Import custom components and assets*/
import AddProduct from '../../components/popups/addProduct';
import ConfirmationModal from '../../components/popups/confirmationModal';
import CommentReplay from '../../components/popups/commentReplay';
import APIPost from '../../components/apiCall/apiPost';
import ReviewText from './reviewText.js';
import devconfig from '../../config/config.js';
import NoDataFound from '../../components/common/noData';
import Links from '../../components/navigations/Links';
import AddFaq from '../../components/popups/addFaq';




import ProductDetails from '../../container/detail//ProductDetails';
import Review from '../../container/detail/Review';
import FaqPage from '../../container/detail/FaqPage';



import fourstars from '../../assets/images/four-stars.svg';
import linkarrow from '../../assets/images/linkarrow.svg';
import IdeasWithSmallcase from '../../assets/images/ideaswithsmallcase.svg';
import Delete from '../../assets/images/delete-product.svg';
import optimumgrp from '../../assets//images/marketplaceDetails/optimumgrp.svg';
import sensigroup from '../../assets//images/marketplaceDetails/sensigrop.svg';
import alicebluegrp from '../../assets//images/marketplaceDetails/alicebluegrp.svg';
import SimpleSlider from '../../components/slider/SliderWithNav';


$(function() {
  $('.e-tablinks').click(function() {
    $( ".e-tabbtn-active" ).removeClass('e-tabbtn-active');
    addActive(this);
  });
});
  function addActive(element) {
    $(element).addClass('e-tabbtn-active');
  }

class ProductRequestDetail extends Component {
  constructor(props){
    super(props);
    this.state = {
      seeMore: false,
      data: null,
      cardID: null,
      modalFilterTypeOptions: [],
      modalFilterLevelOptions: [],
      commentRemove: 0,
      confirmationModalType: "product",
      commentReplay: 0,
      reviews: [],
      reviewPagenum: 1,
      showLoadMore: false,
      replyModal: true,
      requestNewProd: false,
      requestDeleteProd: false,
      tabIndex: 'product',
      activeItem: ""
    }
  }

  componentWillMount() {
    this.findActiveItem();
    var query = this.getUrlVars();
    this.setState({ cardID: query.id });

    if(query.request === "new"){
      this.requestDetailApi(query.id);
      this.setState({requestNewProd: true});
    }
    else{
      this.productDetailApi(query.id);
      this.reviewsList(query.id, 1);
    }

    if(query.request === "delete"){
      this.setState({requestDeleteProd: true});
    }
  }
  findActiveItem(){
    var tempUrl = [];
    tempUrl = window.location.href.split("/");
    var newUrl = tempUrl[tempUrl.length-1].split("?");

    this.setState({activeItem: newUrl[0]});
  }

  getUrlVars(){
    var vars = [], hash;
    var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
    for(var i = 0; i < hashes.length; i++){
      hash = hashes[i].split('=');
      vars.push(hash[0]);
      vars[hash[0]] = hash[1];
    }
    return vars;
  }

  seeMoreClick(status){
    this.setState({ seeMore: status });
  }

  productDetailApi(tempId){
    var url = devconfig.adminV2+"/cards/detail";
    var data = JSON.stringify({
      "card_id" : tempId
    })
    APIPost(url, data).then(function(response){
      if(response.status){

        {/*Type Filter options for edit card*/}
        var tempModalArry = [];
        response.filters.map(function(item, index){
          var item = {value: item.id, label:item.name};
          tempModalArry.push(item);
        });

        {/*Exp Filter options for edit card*/}
        var tempModalArry1 = [];
        response.experience.map(function(item, index){
          var item = {value: item.id, label:item.name};
          tempModalArry1.push(item);
        });

        this.setState({data: response.data, modalFilterTypeOptions: tempModalArry, modalFilterLevelOptions: tempModalArry1});

      }
      else{
        this.setState({errorMessage: response.message});
      }
    }.bind(this))
  }

  requestDetailApi(tempId){
    var url = devconfig.adminV2+"/products/requested-product-detail";
    var data = JSON.stringify({
      "card_id" : tempId
    })
    APIPost(url, data).then(function(response){
      if(response.status){

        {/*Type Filter options for edit card*/}
        // var tempModalArry = [];
        // response.filters.map(function(item, index){
        //   var item = {value: item.id, label:item.name};
        //   tempModalArry.push(item);
        // });

        {/*Exp Filter options for edit card*/}
        // var tempModalArry1 = [];
        // response.experience.map(function(item, index){
        //   var item = {value: item.id, label:item.name};
        //   tempModalArry1.push(item);
        // });

        this.setState({data: response.data});

      }
      else{
        this.setState({errorMessage: response.message});
      }
    }.bind(this))
  }

  removeProduct(tempId){
    var url = devconfig.adminV1+"/cards/delete";
    var data = JSON.stringify({
      "card_id" : tempId
    })
    APIPost(url, data).then(function(response){
      if(response.status){
       window.location.href = '/';
      }
      else{
        this.setState({errorMessage: response.message});
      }
    }.bind(this))
  }

  removeCommentClick(tempId){
    this.setState({commentRemove: tempId, confirmationModalType: "comment"});
  }

  removeComment(){
    var url = devconfig.adminV2+"/cards/delete-review";
    var data = JSON.stringify({
      "review_id": this.state.commentRemove
    })
    APIPost(url, data).then(function(response){
      if(response.status){
        this.setState({reviewPagenum: 1});
        this.reviewsList(this.state.cardID, 1);
      }
      else{
        this.setState({errorMessage: response.message});
      }
    }.bind(this))
  }

  removeProductClick(){
    this.setState({confirmationModalType: "product"});
  }

  // addReplay(tempData){
  //   this.setState({replyModal: false});
  //   var url = devconfig.adminV2+"/cards/reply-review";
  //   var data = JSON.stringify({
  //     "review_id" : this.state.commentReplay,
  //     "reply" : tempData
  //   })
  //   APIPost(url, data).then(function(response){
  //     if(response.status){
  //       this.setState({reviewPagenum: 1, replyModal: true});
  //       this.reviewsList(this.state.cardID, 1);
  //     }
  //     else{
  //       this.setState({errorMessage: response.message, replyModal: true});
  //     }
  //   }.bind(this))
  // }

  // replayModalClick(tempId){
  //   this.setState({commentReplay: tempId, replyModal: true});
  // }

  removeReply(tempId){
    var url = devconfig.adminV2+"/cards/delete-reply";
    var data = JSON.stringify({
      "review_id": tempId
    })
    APIPost(url, data).then(function(response){
      if(response.status){
        this.setState({reviewPagenum: 1});
        this.reviewsList(this.state.cardID, 1);
      }
      else{
        this.setState({errorMessage: response.message});
      }
    }.bind(this))
  }

  reviewsList(tempId, tempPageNum){
    var url = devconfig.adminV2+"/cards/review-list";
    var data = JSON.stringify({
      "card_id" : tempId,
      "page_num" : tempPageNum
    })
    APIPost(url, data).then(function(response){
      if(response.status){

        if(response.data.length === 5){
          this.setState({showLoadMore: true});
        }
        else{
          this.setState({showLoadMore: false});
        }

        var tempArry = [];
        if(this.state.reviewPagenum != 1){
          tempArry = this.state.reviews;
          tempArry = $.merge(tempArry, response.data);
        }
        else{
          tempArry = response.data;
        }

        this.setState({reviews: tempArry});
      }
      else{
        this.setState({errorMessage: response.message});
      }
    }.bind(this))
  }

  loadMoreClick(){
    this.reviewsList(this.state.cardID, this.state.reviewPagenum + 1);
    this.setState({reviewPagenum: this.state.reviewPagenum + 1});
  }

  // newRequestAction(tempType){
  //   var url = devconfig.adminV2+"/products/process-request";
  //   var data = JSON.stringify({
  //     "card_request_id" : this.state.data.id,
  //     "approve" : tempType
  //   })
  //   APIPost(url, data).then(function(response){
  //     if(response.status){
  //       window.location.href = '/requests';
  //     }
  //     else{
  //       this.setState({errorMessage: response.message});
  //     }
  //   }.bind(this))
  // }

  // deleteRequestAction(tempType){
  //   var url = devconfig.adminV2+"/products/process-delete-request";
  //   var data = JSON.stringify({
  //     "delete_request_id" : this.state.data.id,
  //     "approve" : tempType
  //   })
  //   APIPost(url, data).then(function(response){
  //     if(response.status){
  //       window.location.href = '/requests?deleted=true';
  //     }
  //     else{
  //       this.setState({errorMessage: response.message});
  //     }
  //   }.bind(this))
  // }

  handleTab = (e) => {
    if(e == 'product'){
      this.setState({ tabIndex: e });
    }
    if(e == 'review'){
      this.setState({ tabIndex: e });
    }
    if(e == 'faq'){
      this.setState({ tabIndex: e });
    }
  }
  renderTab(){
    if (this.state.tabIndex == 'product'){
      return <ProductDetails/>
    }
    if (this.state.tabIndex == 'review'){
      return <Review/>
    }
      if (this.state.tabIndex == 'faq'){
      return <FaqPage/>
    }
  }
  openReview = () =>{
    this.setState({ tabIndex: 'review' });
    $( ".e-tabbtn-active" ).removeClass('e-tabbtn-active');
    $("#review-tab").addClass('e-tabbtn-active');
  }
  addFaq=()=> {
  var overlaylogin = '<div class="e-login-overlay" id="e-log-overlay"></div>';
  $("body").append(overlaylogin);
  $('.e-login').addClass('e-login-fly');

}



  render() {


      return (
        <div className="e-body">
          <div className="container-fluid px-0">
            <section className="e-marketDetails-header-sec">
              <div className="container">
                <div className="row">
                  <div className="col-lg-8">
                    <Links className="e-marketDetails-link"  activeClassName="" name="Products Requests" url="/products"/>
                    <img src={linkarrow} alt="arrow" className="mx-2"/>
                    <Links className="e-marketDetails-link-color"  activeClassName="" name="Smallcase" url="smallcase"/>
                  </div>
                  <div className="col-lg-4 text-right">
                     <div className="text-right mt-3" >
                        <span className="e-request-accept e-product mr-4">Accept</span>
                        <span className="e-request-reject e-product">Reject</span>

                      </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-8">
                    <h6 className="e-marketDetails-h5 pb-2">SMALLCASE</h6>
                    <h4 className="e-marketDetails-h4 pb-2">Invest in ideas with Smallcase</h4>
                    <img src={fourstars} alt="rating" className="mt-1"/>  <span className="ml-3 e-marketDetails-review" onClick={this.openReview}>20 Reviews<span className="e-marketDetails-review-img"></span><span className="e-box-span mr-2 ml-4">Traders</span> <span className="e-box-span ">Strategists</span></span>
                    <p className="mt-4 e-marketDetails-p">India's first trading products marketplace with advanced tools & apps to empower your every trade!</p>
                  </div>
                  <div className="col-lg-4 text-right mt-5">
                    <img src={IdeasWithSmallcase} alt="bg image" className="mt-4 e-smallcase-banner-img"/>
                  </div>
                </div>
              </div>
            </section>

        </div>
       <div className="container">
          <section>
            <div className="e-tab">
              <button className=" mr-3 e-tablinks e-tabbtn-active" id="pro-tab" onClick={() => this.handleTab('product')}>Product Details</button>
              <button className="mr-3  e-tablinks" id="review-tab" onClick={() => this.handleTab('review')}>Reviews</button>
              <button className="e-tablinks" id="faq-tab" onClick={() => this.handleTab('faq')}>FAQs & Support</button>
            </div>

              {this.renderTab()}


          </section>



        </div>




        <ConfirmationModal/>
        <AddFaq/>

        </div>
      );



  }
}

export default ProductRequestDetail;
