/*Package importing*/
import React, { Component } from 'react';

/*Import custom components and assets*/

class InputBox extends Component {
  constructor(props){
    super(props);
    this.state = {
    	value: ""
    }
  }

  // componentDidMount(){
  //   if(this.props.defaultValue){
  //     this.setState({value: this.props.defaultValue});
  //   }
  // }

  valueChange(e) {
    this.setState({value: e.target.value});
    this.props.valuechange(e.target.value);
  }

  render() {
    return (
      <dl className="ev-input-content-wrap e-m-bottom-20">
        <dd className="ev-input-contentbox ev-small-input-contentbox">
          {/*<input id={this.props.id} value={this.props.value} type={this.props.type} placeholder={this.props.placeholder} onChange={this.valueChange.bind(this)} required/>*/}
          
          <input
            value={this.props.value} 
            name={this.props.id}
            type={this.props.type} 
            className={this.props.uppercase ? this.props.className + "text-uppercase" : this.props.className}
            style={this.props.uppercase ? {textTransform: 'uppercase'}: {}}
            placeholder={this.props.placeholder}
            required
            maxLength={this.props.maxLength }
            readOnly={this.props.readOnly}
            {...this.props}
          />

          {/*<label htmlFor={this.props.id} className="label">{this.props.label}</label>*/}
        </dd>
      </dl>
    );
  }
}

export default InputBox;
