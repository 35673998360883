import React from 'react';

// import components
import SimpleSlider from '../../components/slider/SliderWithNav';
import SelectBox from '../../components/common/selectBox';


// import assets
import FreeSticker from '../../assets/images/marketplaceDetails/freesticker.svg';


export default class ProductDetails extends React.Component{

	constructor(props){
		super(props);
		this.state={
			selectPlan: this.props.plan,
		}
	}
	componentDidMount(){
		this.setState({selectPlan: this.props.details.subscription_plans[0].id});
		


	}

	handleSelect(tempSort){
		this.setState({ selectPlan: tempSort.value,});
	}
	render(){
		 const options = this.props.details && this.props.details.subscription_plans?

				this.props.details.subscription_plans.map((item, index) => {
				return(
				 { value: item.id, label: item.name }
				);
			})
		:
			null
	return(
		<React.Fragment>
		{
			this.props.details ?
				<section className="e-product-details">
					<div className="row">
						<div className="col-lg-7">
							<SimpleSlider media={this.props.details.media_data} video={this.props.details.video}/>
							<div className="e-productdetls-about-smallcase">
								<hr className="e-form-rule"/>
								<h5 className="e-prdtdtls-smallcase-h5 pt-3">About {this.props.details.product_name}</h5>
								<p className="mt-4 e-prdtdtls-p">
									{this.props.details.about}
								</p>
							</div>
							<div className="e-prdtdtls-usp">
								<h6 className="mt-4">USP</h6>
								<p className="pt-1">{this.props.details.usp}</p>
							</div>
							<div className="e-smallcase-bnft">
								<hr className="e-form-rule"/>
								<h5 className="e-prdtdtls-smallcase-h5 pt-3">{this.props.details.product_name} Benefits</h5>
								<ul className="pt-2">
									{
										this.props.details ?
						        this.props.details.benefits.map((item, index) => {
						          return(
						          	<li className="e-features-list mt-3" key={index}>{item }</li>
												);
											})
						        :
						        	null
						      }
					      </ul>
							</div>

						</div>

						<div className="col-lg-5">
							<div className="card border-0 e-prdtdtls-card ">
								<hr className="e-form-rule"/>
								<div className="row d-flex justify-content-between px-2">
								<h5 className="e-prdtdtls-smallcase-h5">Features</h5>
								{
									this.props.details.free?
										<span className="e-free-sticker"><img src={FreeSticker} alt="image" /></span>
									:
									null
								}
								</div>
								<ul>
								
								{
									this.props.details && this.props.details.subscription_plans ?
									this.props.details.subscription_plans.map((item, index) => {
										if(item.id === this.state.selectPlan)
										{
											return(
												item.features.map((item,key)=>{
													return(
														item != "" ?
												<li className="e-features-list mt-3" key={index}>{item } </li>
												:
												null
											);
												
												})
												)
											
										}
										
									})
									:
									<p>data</p>
								}
								</ul>
								<div className="e-product-wrap">
									<SelectBox className="d-inline-block e-plan-select mt-3 text-left" options={options} selectchange={(tempVal) => this.handleSelect(tempVal)} defaultValue={options[0]} />
								</div>
								{
									this.props.details && this.props.details.subscription_plans ?
									this.props.details.subscription_plans.map((item, index) => {
										return(
										this.state.selectPlan == item.id ?
										<div className="e-bg-product mt-3 p-3">
											<div className="pb-2">
												<span className="e-prdtdtls-bold">Regular Price: &#8377; {item.price_text} </span>
												<span className="e-card-p"> /{item.price_validity_text}</span>
											</div>
											<div>
												<span className="e-prdtdtls-bold">Trade Store Price:</span>
												<span className="e-grn-span">&#8377; {item.offer_text}</span>
												<span className="e-card-p"> /{item.offer_validity_text}</span>
											</div>
										</div>

										:null
										);
									})
									:
									null
								}

							</div>
							<div className="card mt-5 border-0 e-platform-card ">
								<hr className="e-form-rule"/>
								<h5 className="e-prdtdtls-smallcase-h5 pt-2">Platforms</h5>
								<p className="pt-3"><span className="e-prdtdtls-bold e-platform">Developed by: </span><span className="e-blue-p"> {this.props.details.vendor_name}</span></p>
								<p><span className="e-prdtdtls-bold">Type:</span> {this.props.details.application_types_data}</p>
								<p><span className="e-prdtdtls-bold">Released:</span>  {this.props.details.release_date}</p>
								<p><span className="e-prdtdtls-bold">Last update:</span> {this.props.details.last_update}</p>
								<p><span className="e-prdtdtls-bold">Compaitable on:</span> {
									this.props.details && this.props.details.compatible_on?
									this.props.details.compatible_on.map((item, index) => {
										return(
											item.name  +  ','
										);
									})
									:
									null
								}</p>
								<p><span className="mt-5 e-prdtdtls-bold">App Store:</span> {this.props.details.ios_info}</p>
								<p><span className="e-prdtdtls-bold">Play Store:</span> {this.props.details.android_info}</p>
							</div>
						</div>
						<div className="col-lg-12">
							<div className="row e-smallcase-bnft">
						{
							this.props.details.instructions_1 && this.props.details.instructions_1.length ?
								<div className="col-lg-6 e-ac-direction ">
									<hr className="e-form-rule"/>
									<h5 className="e-prdtdtls-smallcase-h5 pt-3">If you are an Aliceblue Client</h5>
									<ul>
										{
											this.props.details ?
							        this.props.details.instructions_1.map((item, index) => {
							          return(
							          	<li className="e-features-list mt-3" key={index}>{item}</li>
													);
												})
							        :
							        	null
							      }
									</ul>
								</div>
							:
								null
						}

						{
							this.props.details.instructions_2 && this.props.details.instructions_2.length ?
								<div className="col-lg-6 e-ac-direction ">
									<hr className="e-form-rule"/>
									<h5 className="e-prdtdtls-smallcase-h5 pt-3">If you are not an Aliceblue Client</h5>
									<ul>
										{
											this.props.details ?
							        this.props.details.instructions_2.map((item, index) => {
							          return(
							          	<li className="e-features-list mt-3" key={index}>{item}</li>
													);
												})
							        :
							        	null
							      }
									</ul>
								</div>
							:
								null
						}
					</div>
						</div>
					</div>


				</section>
				:
				null
			}
		</React.Fragment>
	)
}
}
