/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

import devconfig from '../../config/config.js';

/*Assets and Components*/
import logo from '../../assets/images/logo-orange.png';

class Login extends Component {
  constructor(props){
    super(props);
    this.state = {
      username: "",
      password: "",
      errorMessage: ""
    }
  }

  componentWillMount() {
    var query = this.getUrlVars();
    if(query.reset){
      this.setState({successMessage: "Password submission success, please login!"});
    }
  }

  loginClick(e){

    var tempEncodeData = this.state.username.concat(":").concat(this.state.password);
    var encodedData = btoa(tempEncodeData);

    $.ajax({
      type: "GET",
      url: devconfig.adminV1 + '/auth/login',
      dataType: 'json',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          "Authorization": "Basic " + encodedData,
      }
    }).then(function (response) {

         if(response.status == "ok"){
          localStorage.setItem("access_token", response.access_token);
          localStorage.setItem("firebase_token", response.firebase_token);
          localStorage.setItem("token", true);
          
          
          if(response.data.type == 2){
            localStorage.setItem("subadmin", true);
          }
          window.location.href = '/';
        }
        else{
          this.setState({errorMessage: response.message});
        }
    }.bind(this))
     .catch(function(response){ this.setState({errorMessage: response.responseJSON.message});
   }.bind(this))
  }

  userChange(e) {
    this.setState({username: e.target.value, errorMessage: ""});
  }
  passChange(e) {
    this.setState({password: e.target.value, errorMessage: ""});
  }

  getUrlVars(){
    var vars = [], hash;
    var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
    for(var i = 0; i < hashes.length; i++){
      hash = hashes[i].split('=');
      vars.push(hash[0]);
      vars[hash[0]] = hash[1];
    }
    return vars;
  }

  render() {
    return (
      <div className="container-fluid e-login-outer-wrap">
        <div className="row">
          <div className="offset-md-1 col-md-4 e-bg-white e-login-panel e-p-equal-30 e-border-5 e-m-top-100">
            <div>
              <img className="e-logo e-full-width e-m-right-10 e-login-logo" src={logo}/>
            </div>
            <div className="e-p-top-20">
              <h2 className="e-card-p-txt">Login <br/><span>to Trade Store Admin</span></h2>
            </div>
            <div className="e-p-top-50">
              <dl className="ev-input-content-wrap e-m-bottom-20">
                <dd className="ev-input-contentbox ev-small-input-contentbox">
                  <input id="u-email" className="u-email" type="text" onChange={this.userChange.bind(this)} value={this.state.username} placeholder="Email Address"required/>
                  <label htmlFor="u-email" className="label u-email"></label>
                </dd>
              </dl>
              <dl className="ev-input-content-wrap">
                <dd className="ev-input-contentbox ev-small-input-contentbox">
                  <input id="u-password" onChange={this.passChange.bind(this)} value={this.state.password} className="u-password" type="password" placeholder="Password" required/>
                  <label htmlFor="u-password"  className="label u-email"></label>
                </dd>
              </dl>
            </div>
            <div className="e-p-top-30">
              {
                this.state.password != "" && this.state.username != "" ?
                  <span className="e-p-btn e-login-btn" onClick={this.loginClick.bind(this)}>Login</span>
                :
                  <span className="e-p-btn e-login-btn e-btn-inactive">Login</span>
              }
              
            </div>
            
             {
              this.state.errorMessage.length?
              <div className="col-lg-12  e-p-left-0">
                <p className="e-f-weight-500 pt-4 e-c-red">{this.state.errorMessage}</p>
              </div>
            :
            null
             }
           
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
