/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Assets and Components*/
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import SelectBox from '../../components/common/selectBox';
import ProductCard from '../../components/card/productCard';
import ProductRequestCard from '../../components/card/productRequestCard';
import NoDataFound from '../../components/common/noData';
import ApiLoader from '../../components/Loaders/ApiLoader';
import OverlayLoader from '../../components/Loaders/OverlayLoader';
import AddProduct from '../../components/popups/addProduct';
import PlaceholderStyleOneLarge from '../../components/placeholders/placeholderCol1Large';
import LazyLoaderGif from '../../components/common/lazyLoaderGif';
import InputBox from '../../components/common/inputBox';
import NewRequestPending from './newRequest';
import DeletePending from './deleteRequest';
import APIPost from '../../components/apiCall/apiPost';
import APIGet from '../../components/apiCall/apiGet';
import GetURLVar from '../../services/apiCall/getParams/get-url.js';
import Toast from '../../components/popups/Toast';
import ManageLIst from './manageList';
/*Services*/
import devconfig from '../../config/config.js';


class cardList extends Component {
  notify = (e) => toast(e);
  constructor(props){
    super(props);
    this.state = {
      cardList:[],
      product: [],
      typeFilterOption: [],
      expFilterOption: [],
      typeFilterDefault: { value: null, label: 'All Products' },
      expFilterDefault: { value: null, label: 'All' },
      pageNum: 1,
      typeFilterValue: null,
      vendorFilterValue: null,
      lazyDataEnd: true,
      lazyLoadStatus: false,
      modalFilterTypeOptions: [],
      modalFilterLevelOptions: [],
      tabIndex: 1,
      message: true,
      modalClass: false,
      filterChange: false,
    }
  }

  componentDidMount(){
    this.getProduct(this.state.pageNum, this.state.vendorFilterValue, this.state.typeFilterValue);
    this.getProductRequest();
  }

  addProduct=()=> {
    // var overlaylogin = '<div class="e-login-overlay" id="e-log-overlay"></div>';
    // $("body").append(overlaylogin);
    // $('.e-login').addClass('e-login-fly');

    this.setState({modalClass: 'e-login-fly'});
  }

  getProduct=(tempPage, tempvendor, tempFilter)=>{

    this.setState({dataLoading: true});
    var url = devconfig.adminV3+"/product/list";
    var data = JSON.stringify({
      "page_num": parseInt(tempPage),
      "created_by" : parseInt(tempvendor),
      "filter_id" : parseInt(tempFilter),
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
          var temdta = [];

        if(this.state.product.length && !this.state.filterChange ){
          temdta = this.state.product;
          temdta = $.merge(temdta, response.data.products);
        }
        else{
          temdta = response.data. products;
        }


         this.setState({product: temdta, dataLoading: false, filterLoading: false, lazyLoadStatus: false});
          console.log(this.state.product)
         if(response.data.length <= 19){
          this.setState({lazyDataEnd: true});
        }
        else{
          this.setState({lazyDataEnd: false});
        }

         var tempArry = [];
        tempArry.push({ value: null, label: 'All Products' });
        response.data.filter_data.map(function(item, index){
          var item = {value: item.id, label:item.name};
          tempArry.push(item);
          // tempModalArry.push(item);
        });
        this.setState({category: tempArry});

         var tempvendor = [];
        tempvendor.push({ value: null, label: 'All Products' });
        response.data.product_creators.map(function(item, index){
          var item = {value: item.id, label:item.name};
          tempvendor.push(item);
          // tempModalArry.push(item);
        });
        this.setState({listVendors: tempvendor});
      }
      else{
        this.setState({dataLoading: false, filterLoading: false, lazyLoadStatus: false});
      }
    }.bind(this))
  }

getProductRequest=()=>{
    var url = devconfig.adminV2+"/products/requests";
    APIGet(url).then(function(response){
      console.log(response);
      if(response.status === "ok"){
         this.setState({productRequest: response.data});
      }
    }.bind(this))
  }

  searchChange(e){
    this.setState({searchKeyword: e.target.value});
    if(!e.target.value.length){
      this.getProduct(1, null, null);
    }
    if(e.target.value.length >= 2){
      this.searchAPI(e.target.value);
    }
  }

  searchAPI(tempText){
    this.setState({dataLoading: true, filterChange: true});
    var url = devconfig.adminV3+"/product/search";
    var data = JSON.stringify({
      "text": tempText,
    })
    APIPost(url, data).then(function (response) {
       var temdta = [];
       if(response.status){
        if(this.state.product.length && !this.state.filterChange ){
          temdta = this.state.product;
          temdta = $.merge(temdta, response.data.products);
        }
        else{
          temdta = response.data. products;
        }

        this.setState({ product: temdta, dataLoading: false});
      }
      else{
        this.setState({ dataLoading: false});
      }
    }.bind(this))
  }

  selectTypeChange(tempData){
    this.getProduct(this.state.pageNum, this.state.vendorFilterValue, tempData.value, );
    this.setState({ typeFilterValue: tempData.value, filterChange: true});
  }
  selectVendorChange(tempData){
    this.getProduct(this.state.pageNum, tempData.value, this.state.typeFilterValue );
    this.setState({ vendorFilterValue: tempData.value, filterChange: true});
  }
  selectExpChange(tempData){
    this.prodListAPI(this.state.pageNum, this.state.typeFilterValue, tempData.value);
    this.setState({ expFilterValue: tempData.value});
  }

  addModalClose(){
    this.setState({modalClass: false});
    this.getProduct(1, null, null);
  }

  loadMoreClick(){
    this.setState({ pageNum: this.state.pageNum + 1, lazyLoadStatus: true });
    this.getProduct(this.state.pageNum + 1, this.state.vendorFilterValue, this.state.typeFilterValue);
  }
   tabItemClick(tempItem){
    this.setState({tabIndex: tempItem});
  }
  sideMenuClick(tempItem){
    this.setState({activeMenu: tempItem});
  }

  vendorDetailClick(tempId){
    window.location.href = '/vendors?id='+tempId;
  }
  getUrlVars(){
    var vars = [], hash;
    var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
    for(var i = 0; i < hashes.length; i++){
      hash = hashes[i].split('=');
      vars.push(hash[0]);
      vars[hash[0]] = hash[1];
    }
    return vars;
  }

  triggerToast =(tempMsg) =>{
    this.setState({toast: tempMsg,  toastType:"success",filterChange: true})
    setTimeout( function() { this.setState({ toast: null}); }.bind(this), 4000);
    this.getProductRequest();
  }
  render() {
    const { mainCatOption } = this.state;

    const options = [
      { value: 'today', label: 'All Products' },
      { value: 'sevendays', label: 'Salalah, Oman' },
      { value: 'onemonth', label: 'Dubai, UAE' }
    ]

    const renderButton = ()=>{
      switch(this.state. tabIndex) {
        case 1:{
          return  <span className="e-add-product-btn e-p-btn" onClick={this.addProduct.bind(this)}>+ Add New Product</span>
        }
      }
    }

    return (
      <div className="container e-main-content e-dash-cardList-wrap pt-4">
        <div className="row">
          <div className="col-lg-8 e-Product-tab-link-wrap">
            {
              this.state.tabIndex === 1 ?
                <span className="e-tabs-item e-active pr-4">Products</span>
              :
                <span onClick={this.tabItemClick.bind(this, 1)} className="e-color-inactive pr-4">Products</span>
            }

            {
              this.state.tabIndex === 2 ?
                <span className="e-tabs-item e-active pr-4">Product Requests</span>
              :
                <span onClick={this.tabItemClick.bind(this, 2)} className="e-color-inactive pr-4">Product Requests</span>
            }
            {
              this.state.tabIndex === 3 ?
                <span className="e-tabs-item e-active pr-4">Manage Product Listing</span>
              :
                <span onClick={this.tabItemClick.bind(this, 3)} className="e-color-inactive">Manage Product Listing</span>
            }
          </div>

          <div className="col-lg-4">
            <div className="text-right mb-3">
              {renderButton()}
            </div>
          </div>
        </div>

        {
          this.state.tabIndex === 1 ?
            <div>
              <div className="row pt-3">
                <div className="col-lg-2">
                  <div className="e-filter-wrap pt-2">
                    <label>Category</label>
                    <SelectBox options={this.state.category}  placeholder="All Products" selectchange={ this.selectTypeChange.bind(this)}/>
                  </div>
                </div>

                <div className="col-lg-2">
                  <div className="e-filter-wrap pt-2">
                    <label>Vendor</label>
                    <SelectBox options={this.state.listVendors}  placeholder="All Vendors" selectchange={this.selectVendorChange.bind(this)}/>
                  </div>
                </div>
                <div className="col-lg-2 pt-4 mt-3">
                  <div className="">
                    <input value={this.state.searchKeyword} onChange={this.searchChange.bind(this)} className="e-dash-search" type="text" placeholder="Product search" required/>
                   </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  {
                    this.state.dataLoading && !this.state.lazyLoadStatus ?
                      <div className="row mt-4">
                        <div className="col-md-4 e-align-center mb-3">
                          <PlaceholderStyleOneLarge/>
                        </div>
                         <div className="col-md-4 e-align-center mb-3">
                          <PlaceholderStyleOneLarge/>
                        </div>
                         <div className="col-md-4 e-align-center mb-3">
                          <PlaceholderStyleOneLarge/>
                        </div>
                        <div className="col-md-4 e-align-center mb-3">
                          <PlaceholderStyleOneLarge/>
                        </div>
                         <div className="col-md-4 e-align-center mb-3">
                          <PlaceholderStyleOneLarge/>
                        </div>
                         <div className="col-md-4 e-align-center mb-3">
                          <PlaceholderStyleOneLarge/>
                        </div>
                      </div>
                    :
                      <span>

                            <div>
                              <div className="row e-p-top-20">
                                {
                                 this.state.product?
                                 this.state.product.map((item, index) =>{
                                  // linear-gradient( to top right, #ff5000,#ffb017)
                                  var tempBgColor = "linear-gradient( to top right, "+item.gradient_1+" , "+item.gradient_2+")"
                                    return(
                                      <div className="col-lg-4 col-md-6 col-sm-12 col-12  e-card-outer-wrap e-card-transition pb-3" key={index}>
                                        <ProductCard data={item} bgColor ={tempBgColor}/>
                                      </div>
                                    );
                                 })
                                :
                                 null
                                }
                              </div>
                              {
                                !this.state.lazyDataEnd && this.state.product && this.state.product.length  ?
                                  <div className="row">
                                    <div className="col-lg-12 e-load-more-wrap pb-5">
                                      <span className="e-s-btn" onClick={this.loadMoreClick.bind(this)}>Load More</span>
                                    </div>
                                  </div>
                                :
                                  null
                              }
                              {
                                this.state.lazyLoadStatus ?
                                  <div className="row">
                                    <div className="col-lg-12 e-load-more-wrap pb-5 text-center">
                                      <OverlayLoader/>
                                    </div>
                                  </div>
                                :
                                  null
                              }
                            </div>



                      </span>
                  }
                  {
                    !this.state.dataLoading && this.state.product && !this.state.product.length?
                     <div className="row">
                              <NoDataFound data="No data found."/>
                            </div>:
                            null
                  }
                </div>
              </div>
              <AddProduct modalClass={this.state.modalClass} modalClose={this.addModalClose.bind(this)} success={(e)=>{this.triggerToast(e)}}/>
            </div>
          :
            null
        }
        {
          this.state.tabIndex === 2 ?
            <div>
              <div className="row pt-4">
                <div className="col-lg-2 e-vendors-sideMenu-wrap">
                 {/*<div className="e-filter-wrap pt-2">
                                     <label>Status</label>
                                     <SelectBox options={this.state.expFilterOption} defaultValue={this.state.expFilterDefault} placeholder="Type" selectchange={this.selectTypeChange.bind(this)}/>
                                   </div>*/}
                </div>

              </div>
              <div className="row pt-3">
               {
                this.state.productRequest?
                this.state.productRequest.map((item, index) =>{
                  var tempBgColor = "linear-gradient( to top right, "+item.gradient_1+" , "+item.gradient_2+")"
                  return(
                    <div className="col-lg-4 col-md-6 col-sm-12 col-12  e-card-outer-wrap e-card-transition pb-3" key={index}>
                      <ProductRequestCard data={item} bgColor ={tempBgColor} confirmSuccess={(tempMsg)=>{this.triggerToast(tempMsg)}}/>
                    </div>
                  );
                })
                                :
                                 null
                                }

              </div>
                            {
                    !this.state.dataLoading && this.state.productRequest && !this.state.productRequest.length?
                     <div className="row ">
                      <div className="col-lg-12  text-center">
                              <NoDataFound data="No Requests found."/>
                      </div>
                            </div>:
                            null
                  }
              {
              this.state.toast ?
                <Toast data={this.state.toast} type={this.state.toastType}/>
              :
              null
             }
            </div>
          :
            null
        }
        {
          this.state.tabIndex === 3 ?
          <ManageLIst/>
          :
          null
        }


      </div>
    );
  }
}

export default cardList;
