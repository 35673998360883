         import React, { Component } from 'react';
import $ from 'jquery';

/*Assets and Components*/
import devconfig from '../../config/config.js';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {DateRangePicker, isInclusivelyBeforeDay} from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';

import LazyLoaderGif from '../../components/common/lazyLoaderGif';
import SelectBox from '../../components/common/selectBox';

import noResultImg from '../../assets/images/no-result.png';
import PlaceholderStyleOneLarge from '../../components/placeholders/placeholderCol1Large.js';


class Leads extends Component {
  notify = (e) => toast(e);
  constructor(props){
    super(props);
    this.state = {
      loading: true,
      leadStatusData: {},
      productData: {},
      partnerData: {},
      initalDate: moment().format(),
      startDate: moment().startOf('month'),
      endDate: moment(),
      tabIndex:1
    }
  }


                 componentDidMount(){
    this.getAggregates(true);
  }
  getAggregates = async (first) => {
    if(first){
      var data = {
        start_date: undefined,
        end_date: undefined,
      }
    }else{
      var data = {
        start_date: this.state.startDate.format("YYYY-MM-DD"),
        end_date: this.state.endDate.format("YYYY-MM-DD"),
      }
    }
    $.ajax({
        type: "POST",
        url: devconfig.adminV2 +'/data/aggregates',
        dataType: 'json',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Token': this.state.accessToken
        },
        data: JSON.stringify(data)
    }).then(function (response) {
        if (response.status) {
          this.setState({
            leadStatusData: response.lead_status_data,
            productData: response.product_data,
            partnerData: response.partner_data,
          })
        }else{
          this.notify(response.message)
        }
    }.bind(this))
  }

  onDatesChange = async ({ startDate, endDate }) => {
    if(startDate && endDate){
      var fromDate = startDate.format("YYYY-MM-DD");
      var toDate = endDate.format("YYYY-MM-DD");

      await this.setState({
        startDate,
        endDate,
        fromDate: fromDate,
        toDate: toDate,
        data: [],
        pageNum: 1,
        loading: true
      });

      await this.getAggregates();
    }
  }


  render(){
    const leadOptions = {
      0: "New",
      1: "Contacted",
      2: "Postponed",
      3: "Subscribed",
      4: "Lost",
      5: "Other",
    }
    return(
               <div className="container pt-4 mt-1">
                <div className="e-filter-wrap pt-2">
                  <div>
                    <label>Date</label>
                  </div>
                  <DateRangePicker
                    startDate={this.state.startDate}
                    endDateId="e-endDate"
                    startDateId="e-startDate"
                    endDate={this.state.endDate}
                    onDatesChange={this.onDatesChange}
                    focusedInput={this.state.focusedInput}
                    onFocusChange={focusedInput => this.setState({ focusedInput })}
                    startDatePlaceholderText={"Start date"}
                    endDatePlaceholderText={"End date"}
                    disableScroll={false}
                    hideKeyboardShortcutsPanel={true}
                    regular={false}
                    small
                    numberOfMonths={1}
                    isOutsideRange={day => !isInclusivelyBeforeDay(day, moment())}
                  />
                </div>
                <h6 className="pt-4 mt-2 pb-2">Leads by Status</h6>
                <div className="row mt-3">
                  {
                    Object.keys(this.state.leadStatusData).map((key, index)=>{
                      var status = leadOptions[key];

                      return(
                        <div className="col-lg-2">
                          <div className="e-dash-item-wrap">
                            <div className="text-uppercase"><b className={status.value=="LOST" ? "color-red": "color-black"}>{status}</b></div>
                            <div className="pt-5 text-right e-lead-status">{this.state.leadStatusData[key]}</div>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
                <h6 className="mt-5 pb-2">Leads by Products</h6>
                <div className="row mt-3">
                  {
                    Object.keys(this.state.productData).map((key, index)=>{
                      return(
                        <div className="col-lg-2">
                          <div className="e-dash-item-wrap">
                            <div><b className="text-uppercase">{key}</b></div>
                            <div className="pt-5 text-right e-lead-status">{this.state.productData[key]}</div>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
                <h6 className="mt-5 pb-2">Leads by Partner</h6>
                <div className="row mt-3">
                  {
                    Object.keys(this.state.partnerData).map((key, index)=>{
                      return(
                        <div className="col-lg-2">
                          <div className="e-dash-item-wrap">
                            <div><b className="text-uppercase">{key}</b></div>
                            <div className="pt-5 text-right e-lead-status">{this.state.partnerData[key]}</div>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            );
            }

    }

export default Leads;