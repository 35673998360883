import React from 'react';
import $ from 'jquery';
// import services
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';
import devconfig from '../../config/config.js';
import GetURLVar from '../../services/apiCall/getParams/get-url.js'; 

// import components
import Acordion from '../../components/globel/Acordion';
import EditFaq from '../../components/popups/editFaq';
import ApiLoader from '../../components/Loaders/ApiLoader';
import NothingFound from '../../components/common/noData';
import Toast from '../../components/popups/Toast';
import FqaDelete from '../../components/popups/faqDelete';
// import asset
import acopen from '../../assets/images/marketplaceDetails/acopenhelp.svg';
import faqbg1 from '../../assets/images/marketplaceDetails/faqbg1.svg';
import faqbg2 from '../../assets/images/marketplaceDetails/faqbg2.svg';
import faqbg3 from '../../assets/images/marketplaceDetails/faqbg3.svg';
import contactimg from '../../assets/images/contact/contactimg.svg';
import cardarrow from '../../assets/images/home/cardarrow.svg';

export default class FaqPage extends React.Component{

	constructor(props){
		super(props);
		this.state={
			data: null,
			id: null,
			response: null,
		}
	}
	

	componentWillMount() {
    GetURLVar().then(function(query){ this.getData(query.id) }.bind(this));
  }
	getData(tempId){
		this.setState({id: tempId});
		this.setState({data: null, dataLoading: true});
		var url = devconfig.adminV2+"/faq/list";
    var data = JSON.stringify({
      "id": parseInt(tempId),
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        this.setState({data:response.data, dataLoading: false, response: response.message});
        console.log(this.state.data);
      }
      else{
        this.setState({ dataLoading: false});
      }
    }.bind(this))
	}


	triggerToast=(temp)=>{
		 this.setState({toast: temp , toastType:"success"})
    setTimeout( function() { this.setState({ toast: null}); }.bind(this), 4000);
    this.getData(this.state.id);
	}

	deleteFaq=(e)=>{
		$('#'+e).modal('toggle');
	}
	 editFaq (e){
	
  var overlaylogin = '<div class="e-login-overlay" id="e-log-overlay"></div>';
  $("body").append(overlaylogin);
  $('#'+e).addClass('e-login-fly');


}
removeFaq=(e)=>{
		this.setState({data: null, dataLoading: true});
		var url = devconfig.adminV2+"/faq/delete";
    var data = JSON.stringify({
      "id": parseInt(e),
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
      	this.triggerToast(response.message)
        this.setState({ dataLoading: false});
      }
      else{
        this.setState({ dataLoading: false});
      }
    }.bind(this))
}

	render(){
		var data = 1
		return(
		<div className="container pb-5">
			
				<React.Fragment>
					{
						this.state.dataLoading ?
						<ApiLoader/>
						:
						null

					}
				</React.Fragment>
				<div className="row mt-5">
					{

				this.state.data ?
				this.state.data.map((item,key) => {
					data++
					const modalid="e-modal"+key;
					const id = "id"+data;
					return(
					<div className="col-lg-6 col-md-12 col-sm-12 e-col" key={item.key}>
						
							<Acordion
								buttonName={item.question}
								accordionId={id}
								content={item.answer}
								faqId={item.id}
								edit={()=>{this.editFaq(key)}}
								delet={()=>{this.deleteFaq(modalid)}}
							/>
						<EditFaq id={key} itemid={item.id} content={item.answer} question={item.question} confirm={(temp)=>{this.triggerToast(temp)}}/>
						<FqaDelete id={modalid} modalTitle="Confirm Delete" modalContent="Do you want to remove?" confirmSuccess={()=>{this.removeFaq(item.id)}}/>
					</div>
					);
					})
				: 
					null	
				}
				{
					!this.state.dataLoading && !this.state.data ?
					<NothingFound data="No Questions Found!"/>
					:
					null
				}
				</div>
			
				{
          this.state.toast ?
            <Toast data={this.state.toast} type={this.state.toastType}/>
         :
            null
        }
		</div>
	
	)
	}
	
}
