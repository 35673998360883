/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {SingleDatePicker, isInclusivelyBeforeDay} from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';

/*Import custom components and assets*/
import InputBox from '../../components/common/inputBox';
import Textarea from '../../components/common/textarea';
import APIPost from '../../components/apiCall/apiPost';
import devconfig from '../../config/config.js';

class CommentReplay extends Component {
  notify = (e) => toast(e);
  constructor(props){
    super(props);
    this.state = {
      errorMessage: "",
      fBody: "",
      btnClicked: false,
      remarks: "",
      assignedto: ""
    }
  }

  async clearStates(){
    await this.setState({
      errorMessage: "",
      fBody: "",
      remarks: "",
      date: undefined,
      btnClicked: false
    });
  }

  componentDidMount(){
    $('#e-edit-lead-data').on('shown.bs.modal', () => {
      var data = this.props.data;
      console.log(data)
      this.setState({
        remarks: data.remarks ? data.remarks : "",
        assignedto: data.assignedto ? data.assignedto : "",
        id: data.id, 
        date: data.nextfollowupdate!=="N/A" ? moment(data.nextfollowupdate): undefined
      })
    })
  }

  async AddClick(){
    $.ajax({
        type: "POST",
        url: devconfig.adminV2 +'/data/update-lead-data',
        dataType: 'json',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Token': this.state.accessToken
        },
        data: JSON.stringify({
          'id': this.state.id,
          'assignedto': this.state.assignedto,
          'remarks': this.state.remarks,
          'postponed_to': this.state.date
        })
    }).then(async function (response) {
        if (response.status) {
          await this.clearStates();
          $('#e-edit-lead-data').modal('toggle');
        }
        this.notify(response.message);
    }.bind(this)).fail((err, errTxt, errCode)=>{
      this.notify(errTxt);
    })

  }

  render() {
    return(
      <div className="modal fade e-modal-wrap e-edit-lead-modal" id="e-edit-lead-data" tabIndex="-1" role="dialog" aria-labelledby="e-replayAddLabel" aria-hidden="true">
        <div className="modal-dialog e-modal-editVendor" role="document">
          <div className="modal-content">
            <div className="text-right">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body pt-2">
              <div className="row mb-3">
                <div className="col-lg-12">
                  <h5 className="pb-3">Edit Data</h5>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <InputBox 
                    label="Assigned to" 
                    id="f-body" 
                    type="text" 
                    value={this.state.assignedto} 
                    valuechange={(tempValue) => this.setState({assignedto: tempValue, errorMessage: ""})}/>
                </div>
                <div className="col-lg-12">
                  <Textarea 
                    label="Remarks" 
                    id="f-body" 
                    type="text" 
                    value={this.state.remarks} 
                    valuechange={(tempValue) => this.setState({remarks: tempValue, errorMessage: ""})}/>
                </div>
                <div className="col-lg-12 mb-2">
                  <label>Postponed to</label><br/>
                  <SingleDatePicker
                    date={this.state.date} // momentPropTypes.momentObj or null
                    onDateChange={date => this.setState({ date })} // PropTypes.func.isRequired
                    focused={this.state.focused} // PropTypes.bool
                    onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
                    id="your_unique_id" // PropTypes.string.isRequired,
                    disableScroll={false}
                    hideKeyboardShortcutsPanel={true}
                    regular={false}
                    small
                    placeholder="Date"
                    showClearDates
                    numberOfMonths={2}
                    isOutsideRange={day => isInclusivelyBeforeDay(day, moment())}
                  />
                </div>
                <div className="col-lg-4 mt-4">
                  {
                    this.state.remarks.length && this.state.assignedto.length && !this.state.btnClicked ?
                      <span className="e-p-btn" onClick={this.AddClick.bind(this)}>Update</span>
                    :
                      <span className="e-p-btn e-inActive">Update</span>
                  }
                </div>
                {
                  this.state.errorMessage.length ?
                    <div className="col-lg-12 mt-1">
                      <div className="mt-4 text-danger">
                        {this.state.errorMessage}
                      </div>
                    </div>
                  :
                    null
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default CommentReplay;
