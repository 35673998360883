/*Import Packages*/
import React, { Component } from 'react';
import {BrowserRouter, Route} from 'react-router-dom';
import firebase from 'firebase';

/*Import Service*/
import FirebaseConfig from './config/firebaseConfig.js';

/*Import pages*/
import Header from './components/navigations/header.js';
import VendorMain from './components/vendor/main.js';
import Login from './components/login/login.js';
import Onboard from './components/login/onboard.js';

// V2 Changes
import cardList from './container/dashboard/main.js';
import ProductDetail from './container/detail/main.js';
import ProductRequestDetail from './container/detail/productRequestMain.js';

import VendorsMain from './container/vendors/main.js';
import VendorProfile from './container/vendors/vendorProfile';
import SubAdminsMain from './container/subadmins/main.js';
import ChatMain from './container/chat/main.js';
import FaqMain from './container/faq/main.js';
import RequestsMain from './container/requests/main.js';
import DataMain from './container/data/main.js';
import Subscriptions from './container/data/subscription';
import Dashboard from './container/data/dashboard.js';
import Resources from './container/resource/main.js';
import ResourceVideoDetails from './container/resource/ResourceVideoDetails';
import BlogDetails from './container/resource/BlogDetails';
import WebinarDetails from './container/resource/WebinarDetails';
import Notifications from './container/Nofications/Notifications';
import AllUsers from './container/allusers/allUsers';
import Ledger from './container/vendors/ledger';
import AddReferal from './container/referal/index';
import PartnerList from './container/partners/list';
import PartnerDetail from './container/partners/detail';

firebase.initializeApp(FirebaseConfig);

class App extends Component {
  componentDidMount() {

  }

  componentDidMount() {
    var tempToken = localStorage.getItem("firebase_token");
    var tempValidate = sessionStorage.getItem("firebaseValidate");

    if(tempToken && !tempValidate){
      this.getFireBaseUID(tempToken);
    }

  }

  getFireBaseUID(tempToken){
    sessionStorage.setItem("firebaseValidate", true);

    firebase.auth().signInWithCustomToken(tempToken).catch(function(error) {
      if(error.code == "auth/invalid-custom-token"){
        this.logout();
      }
      var errorCode = error.code;
      var errorMessage = error.message;
    }.bind(this));
  }

  logout(){
    localStorage.clear();
    window.location.href = '/';
  }

  render() {
    if(!localStorage.getItem("token")){
      return (
        <BrowserRouter>
          <div>
            <Route path="/" exact component={Login}/>
            <Route path="/signup" component={Onboard}/>

            <Route path="/login" exact component={Login}/>
            <Route path="/vendor" exact component={Login}/>
            <Route path="/product-details" exact component={Login}/>
             <Route path="/product-request-details" exact component={Login}/>
            <Route path="/vendors" exact component={Login}/>
            <Route path="/subscriptions" exact component={Login}/>
            <Route path="/leads" exact component={Login}/>
            <Route path="/support-chats" exact component={Login}/>
            <Route path="/resources" exact component={Login}/>
            <Route path="/notifications" exact component={Login}/>
            <Route path="/faq" exact component={Login}/>
            <Route path="/requests" exact component={Login}/>
            <Route path="/sub-admins" exact component={Login}/>
            <Route path="/all-userd" exact component={Login}/>
          </div>
        </BrowserRouter>
      );
    }
    else{
      return (
        <BrowserRouter>
          {
            localStorage.getItem("subadmin") ?
              <div>
                <Header/>
                <Route path="/" exact component={DataMain}/>
              </div>
            :
              <div>
                <Header/>
                <Route path="/" exact component={cardList}/>
                <Route path="/products" exact component={cardList}/>
                <Route path="/product-details" exact component={ProductDetail}/>
                <Route path="/vendors" exact component={VendorsMain}/>
                <Route path="/subscriptions" exact component={Subscriptions}/>
                <Route path="/leads" exact component={Dashboard}/>
                <Route path="/support-chats" exact component={ChatMain}/>
                <Route path="/faq" exact component={FaqMain}/>
                <Route path="/requests" exact component={RequestsMain}/>
                <Route path="/sub-admins" exact component={SubAdminsMain}/>
                <Route path="/resources" exact component={Resources}/>
                <Route path="/resourceVideo" exact component={ResourceVideoDetails}/>
                <Route path="/blogDetails" exact component={BlogDetails}/>
                <Route path="/webinarDetails" exact component={WebinarDetails} />
                <Route path="/product-request-details" exact component={ProductRequestDetail}/>
                <Route path="/vendor-profile" exact component={VendorProfile}/>
                <Route path="/notifications" exact component={Notifications}/>
                <Route path="/all-users" exact component={AllUsers}/>
                <Route path="/ledger" exact component={Ledger}/>
                <Route path="/add-content" exact component={AddReferal}/>
                <Route path="/partners" exact component={PartnerList}/>
                <Route path="/partner-detail" exact component={PartnerDetail}/>
              </div>
          }

        </BrowserRouter>
      );
    }
  }
}

export default App;
