/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Import custom components and assets*/

class ConfirmationModal extends Component {
  constructor(props){
    super(props);
    this.state = {


    }
  }

  proceedClick(){
    this.props.confirmSuccess();
    $('#e-confirmModal').modal('toggle');
  }

  render() {

    return(
      <div className="modal fade e-modal-wrap" id="e-confirmModal" tabIndex="-1" role="dialog" aria-labelledby="e-confirmModalLabel" aria-hidden="true">
        <div className="modal-dialog  e-modal-confirm" role="document">
          <div className="modal-content">
            <div className="text-right">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body pt-2">
              <div className="row mb-3">
                <div className="col-lg-12">
                  <h5 className="pb-3">{this.props.modalTitle}</h5>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <p>{this.props.modalContent}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 text-right">
                  <span className="e-s-btn mr-4" data-dismiss="modal">Cancel</span>
                  <span className="e-p-btn" onClick={this.proceedClick.bind(this)}>Proceed</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ConfirmationModal;
