import React from 'react';

function uploadApiModal(props){
	return(
	<div className="modal fade e-api-details-modal" id="uploadapi" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Upload API Details</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        {props.children}
      </div>
    </div>
  </div>
</div>
	);
}
export default uploadApiModal;