/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Assets and Components*/
import InputBox from '../../components/common/inputBox';
import EmailValidation from '../../components/validation/email';
import PhoneValidation from '../../components/validation/phone';
import APIPost from '../../components/apiCall/apiPost';
import APIGet from '../../components/apiCall/apiGet';
import devconfig from '../../config/config.js';

class AddSubAdmin extends Component {
  constructor(props){
    super(props);
    this.state = {
      vName: "",
      vEmail: "",
      vPhone: "",
      errorMessage: "",
      successMessage: "",
      btnClicked: false,
      resetInput: true,
      dataList: []
    }
  }

  componentWillMount() {
    this.listInvitation();
  }

  listInvitation(){
    var url = devconfig.adminV2+"/vendor/signup-pending";

    APIGet(url).then(function(response){
      if(response.status){
        this.setState({dataList: response.data});
      }
    }.bind(this))
  }

  async inviteSubAdmin(){
    var validationSuccess = true;

    await EmailValidation(this.state.vEmail).then(function(response){
      if(!response){
        this.setState({errorMessage: "Invalid email address, please check and retry."});
        validationSuccess = false;
      }
    }.bind(this))

    await PhoneValidation(this.state.vPhone).then(function(response){
      if(!response){
        this.setState({errorMessage: "Invalid phone number, please check and retry."});
        validationSuccess = false;
      }
    }.bind(this))

    if(validationSuccess){
      this.setState({btnClicked: true});
      var url = devconfig.adminV1+"/auth/invite";
      var data = JSON.stringify({
        "name": this.state.vName,
        "email": this.state.vEmail,
        "mobile": parseInt(this.state.vPhone),
        "type": 2
      })

      APIPost(url, data).then(function(response){
        if(response.status_code == 200){
          this.setState({resetInput: false, successMessage: "Invitation has been sent"});
          this.clearStates();
          // this.listInvitation();

          setTimeout( function() {
              this.setState({successMessage: ""});
            }.bind(this), 2000
          );
        }
        else{
          this.setState({errorMessage: response.message, btnClicked: false});

          setTimeout( function() {
              this.setState({errorMessage: ""});
            }.bind(this), 2000
          );
        }
      }.bind(this))
    }
  }

  clearStates(){
    this.setState({
      vName: "",
      vEmail: "",
      vPhone: "",
      errorMessage: "",
      btnClicked: false,
      resetInput: true
    });
  }

  resendClick(tempId,tempSelector, e){

    $(tempSelector).html("Sending")
    $(tempSelector).addClass("e-sending")

    var url = devconfig.adminV2+"/vendor/resent-invite";
    var data = JSON.stringify({
      "vendor_id" : tempId
    })

    APIPost(url, data).then(function(response){
      if(response.status){
        $(tempSelector).html("Success")
        $(tempSelector).addClass("e-success")
      }
      else{
        $(tempSelector).html("Failed")
        $(tempSelector).addClass("e-failed")
      }
    }.bind(this))
  }

  render() {
    return (
      <div className="col-lg-9">
        <div className="e-add-sub-admin mb-5">
          <h2 className="mb-4">Add New Sub Admin</h2>
          <div className="row">
            <div className="col-lg-6">
              {
                this.state.resetInput ?
                  <InputBox placeholder="Name" id="v-name" type="text" value={this.state.vName} onChange={(e) => this.setState({vName: e.target.value, errorMessage: ""})} />
                :
                  null
              }
            </div>
            <div className="col-lg-6">
              {
                this.state.resetInput ?
                  <InputBox placeholder="Email Address" id="v-email" type="text" value={this.state.vEmail} onChange={(e) => this.setState({vEmail: e.target.value, errorMessage: ""})}/>
                :
                  null
              }
            </div>
            <div className="col-lg-6 mb-2">
              {
                this.state.resetInput ?
                  <InputBox placeholder="Phone Number" id="v-phone" type="text" value={this.state.vPhone} onChange={(e) => this.setState({vPhone: e.target.value, errorMessage: ""})}/>
                :
                  null
              }
            </div>
            {
              this.state.successMessage.length ?
                <div className="col-lg-12">
                  <div className="mt-1 mb-3  text-success">
                    {this.state.successMessage}
                  </div>
                </div>
              :
                null
            }
            {
              this.state.errorMessage.length ?
                <div className="col-lg-12">
                  <div className="mt-1 mb-3  text-danger">
                    {this.state.errorMessage}
                  </div>
                </div>
              :
                null
            }
          </div>
          {
            this.state.vName.length && this.state.vEmail.length && this.state.vPhone.length && !this.state.btnClicked ?
              <span className="e-btn-sub-admin e-p-btn" onClick={this.inviteSubAdmin.bind(this)}>Invite as Sub Admin</span>
            :
              <span className="e-btn-sub-admin e-p-btn e-inActive">Invite as Sub Admin</span>
          }
        </div>

        {/*
          this.state.dataList.length ?
            <div className="e-invite-vendor-wrap">
              <h2 className="mb-4">Invitation Acceptance Pending</h2>
              <div className="row">
                <div className="col-lg-12">
                  <table id="example" className="table table-striped">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        this.state.dataList.map(function(item, index){
                          var spanClass = "e-s-btn e-resend-"+item.id;
                          var spanSelector = ".e-resend-"+item.id;
                          return(
                            <tr key={index}>
                              <td>{item.name}</td>
                              <td>{item.email}</td>
                              <td>{item.mobile}</td>
                              <td>
                                <span className={spanClass} onClick={this.resendClick.bind(this, item.id, spanSelector)}>Resend</span>
                              </td>
                            </tr>
                          )
                        }.bind(this))
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          :
            null
        */}
      </div>
    );
  }
}

export default AddSubAdmin;
