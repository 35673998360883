

import React from 'react';
import $ from 'jquery';

// import service
import APIPost from '../../components/apiCall/apiPost';
import APIGet from '../../components/apiCall/apiGet';
import devconfig from '../../config/config.js';
// import components
import InputBox from '../../components/common/inputBox';
import Textarea from '../../components/common/textarea';
import PrimaryBtn from '../../components/buttons/primaryBlueButton';

// import assets
import Close from '../../assets/images/close-btn.svg';


export default class vendorProfileForm extends React.Component{
	constructor(props){
		super(props);
		this.state={
			name: this.props.data.name,
			phone: this.props.data.mobile,
			vendor: this.props.id,
		}
	}

	 hideLogin=()=> {
  $('.e-edit-profile').removeClass('e-login-fly');
  $('#e-log-overlay').remove();
  }
  updateProfile =() =>{
  	var url = devconfig.adminV2+"/vendor/edit-profile";
    var data = JSON.stringify({
      "vendor_id" : parseInt(this.state.vendor),
 			"name" : this.state.name,
 			"mobile" : this.state.phone
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        this.props.confirmSuccess(response.message)

			}
      else{
        this.setState({ dataLoading: false});
      }
    }.bind(this))
    this.hideLogin();
	}

	render(){
		return(
			<div className="e-edit-profile container">
				<div className="col-lg-12">
				<form >
					<div className="row p-3">
						<button className="e-bank-closebtn mt-5">
              <img src={Close} alt="close button" className="e-toggle" onClick={this.hideLogin.bind(this)}/>
           	</button>
           </div>
           <div className="col-lg-12 p-3">
					<h2 className=" mt-5 e-bank-form-h">Update Vendor Details</h2>
					{/*<p className="e-bank-p">Ensure you are entering the correct details</p>*/}
					<InputBox placeholder="Name" value={this.state.name} 
					onChange={(e)=>{this.setState({name: e.target.value})}}
					/>
					<InputBox placeholder="Mobile number" value={this.state.phone} 
					onChange={(e)=>{this.setState({phone: e.target.value})}}
					/>
					{
						this.state.name && 
						this.state.phone ?
						<PrimaryBtn name="Update Profile" handleClick={this.updateProfile}/>
						:
						<PrimaryBtn name="Update Profile" className="e-block" disabled={true}/>
					}
					
					</div>
				</form>
				</div>
			</div>
			)
	}
}

