/*Package importing*/
import React, { Component } from 'react';

/*Assets and Components*/
import noResultImg from '../../assets/images/empty.svg';

class NoDataFound extends Component {
  constructor(props){
    super(props);
    this.state = {

    }
  }

  render() {

    return (
      <div className="col-lg-12 mt-4 text-center mt-5">
        <img className="e-noData-img" src={noResultImg} alt="No data found"/>
        <div className="e-noData-txt">{this.props.data}</div>
      </div>
    );
  }
}

export default NoDataFound;
