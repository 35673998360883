/*Package importing*/
import React, { Component } from 'react';

/*Assets and Components*/
import APIGet from '../../components/apiCall/apiGet';
import devconfig from '../../config/config.js';
import ProductCard from '../../components/card/productCard';
import VendorProfileEditModal from '../../components/popups/vendorProfileEdit';
import ConfirmationModal from '../../components/popups/confirmationModal';
import NoDataFound from '../../components/common/noData';
import PlaceholderStyleTwo from '../../components/placeholders/placeholderCol2';

class NewRequestPending extends Component {
  constructor(props){
    super(props);
    this.state = {
      dataProduct: [],
      dataLoading: true
    }
  }

  componentWillMount() {
    this.listData();
  }


  listData(tempID){
    var url = devconfig.adminV2+"/products/requests";

    APIGet(url).then(function(response){
      if(response.status){
        this.setState({dataProduct: response.data, dataLoading: false});
      }
      else{
        this.setState({errorMessage: response.message, dataLoading: false});
      }
    }.bind(this))
  }

  render() {
    if(!this.state.dataLoading){
      return (
        <div className="col-lg-9">
          {
            this.state.dataProduct.length ?
              <div className="e-partnered-products">
                <div className="row">
                  {
                    this.state.dataProduct.map(function(item, index){
                      var tempBgColor = "linear-gradient( to top right, "+item.gradient1+" , "+item.gradient2+")"
                      return(
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 e-m-bottom-30 e-card-outer-wrap e-card-transition" key={index}>
                          <ProductCard data={item} bgColor={tempBgColor} request="new"/>
                        </div>
                      )
                    }.bind(this))
                  }
                </div>
              </div>
            :
              <div className="row">
                <NoDataFound data="No new product requests found."/>
              </div>
          }
        </div>
      );
    }
    else{
      return (
        <div className="col-lg-9">
          <PlaceholderStyleTwo/>
        </div>
      );
    }
    
  }
}

export default NewRequestPending;
