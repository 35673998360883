import React from 'react';


// import components
import Main from './main';
import TradeStore from './tradeStoreTab';
import Leads from './Leads';
export default class subscription extends React.Component{
	constructor(props){
		super(props);
		this.state={
			tabIndex: 1
		}
	}
	renderTab =()=>{
		switch(this.state.tabIndex){
			case 1: return <TradeStore/>;
			break;
			case 2: return <Main/>
			break;
			case 3: return <Leads/>
			break;
		}
	}

	render(){
		return(
				<React.Fragment>
				<div className="container pt-4">
				<div className="row">
					<div className="col-lg-8">
						{/*<span className={this.state.tabIndex ==1 ? "e-tabs-item e-active pr-5" : "e-color-inactive pr-5"} onClick={()=>{this.setState({tabIndex: 1})}}>Tradestore</span>
						<span className={this.state.tabIndex ==2 ? "e-tabs-item e-active pr-5" : "e-color-inactive pr-5"} onClick={()=>{this.setState({tabIndex: 2})}}>External</span>
												<span className={this.state.tabIndex ==3 ? "e-tabs-item e-active pr-5" : "e-color-inactive pr-5"} onClick={()=>{this.setState({tabIndex: 3})}}>External Leads</span>*/}
					</div>
				</div>
				</div>
					{/*{this.renderTab()}*/}
					<TradeStore/>
				</React.Fragment>

		);
	}
}