 /*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

/*Assets and Components*/
import profile from '../../assets/images/profile-01.png';
import logo from '../../assets/images/logo.svg';
import SelectBox from '../../components/common/selectBox';
import Links from '../../components/navigations/Links';


import devconfig from '../../config/config.js';

class Header extends Component {
  constructor(props){
    super(props);
    this.state = {
      logoutdDown: false,
      activeItem: ""
    }
  }

  componentWillMount(){
    this.findActiveItem();
  }

  dropDownClick(){
    this.setState({ logoutdDown: !this.state.logoutdDown });
  }

  logoutClick(e){
    localStorage.clear();
    window.location.href = '/';
  }
  subAdminClick(e){
    window.location.href = '/sub-admins';
  }

  findActiveItem(){
    var tempUrl = [];
    tempUrl = window.location.href.split("/");
    var newUrl = tempUrl[tempUrl.length-1].split("?");

    this.setState({activeItem: newUrl[0]});
  }


  render() {
//     const options= [
//   { label : JM} ,
//   {value: 'profile', label : <Links className="e-usr-nav-link" name="Profile" url="profile"/>} ,
//   {value: 'purchaseHist', label : <Links className="e-usr-nav-link" name="Purchase History" url="purchaseHist"/>} ,
//   {value: 'paymentHist', label : <Links className="e-usr-nav-link" name="Payment History" url="paymentHist"/>} ,
//   {value: 'fav', label : <Links className="e-usr-nav-link" name="Favourites" url="fav"/>} ,
//   {value: 'logout', label : <Links className="e-usr-nav-link" name="Logout" url="logout"/>} ,

// ];
    return (

      <div>
        <header className="e-header e-header-nav py-2">
          <div className="container">

            <div className="row">
              <div className="col-lg-2">
                <a className="" href="/">
                  <img className="e-logo e-full-width" src={logo} alt="Alice blue trade store admin"/>
                </a>
              </div>
              {
                localStorage.getItem("subadmin") ?
                  <div className="col-lg-10 text-right e-nav-item-wrap">
                    {
                      this.state.activeItem === "" ?
                        <a className="e-nav-item e-text-underline e-active" href="/">Subscriptions</a>
                      :
                        <a className="e-nav-item e-text-underline" href="/">Subscriptions</a>
                    }

                    <a className="e-nav-user-wrap e-card-transition" onClick={this.dropDownClick.bind(this)}>
                      <span className="e-nav-user"></span>
                      Sub Admin
                    </a>

                    {
                      this.state.logoutdDown ?
                        <span>
                          <span className="e-logout-dDown-wrap" onClick={this.logoutClick.bind(this)}>logout</span>

                          <span className="e-logout-dDown-overlay" onClick={this.dropDownClick.bind(this)}></span>
                        </span>
                      :
                        null
                    }
                  </div>
                :
                  <div className="col-lg-10  e-nav-item-wrap">
                    {
                      this.state.activeItem === "" ?
                        <a className="e-nav-item e-text-underline e-active" href="/">Products</a>
                      :
                        <a className="e-nav-item e-text-underline" href="/">Products</a>
                    }
                    {
                      this.state.activeItem === "subscriptions" ?
                        <a className="e-nav-item e-text-underline e-active" href="/subscriptions">Subscriptions</a>
                      :
                        <a className="e-nav-item e-text-underline" href="/subscriptions">Subscriptions</a>
                    }
                    {
                      this.state.activeItem === "vendors" ?
                        <a className="e-nav-item e-text-underline e-active" href="/vendors">Vendors</a>
                      :
                        <a className="e-nav-item e-text-underline" href="/vendors">Vendors</a>
                    }


                    {
                      this.state.activeItem === "leads" ?
                        <a className="e-nav-item e-text-underline e-active" href="/leads">Referrals </a>
                      :
                        <a className="e-nav-item e-text-underline" href="/leads">Referrals </a>
                    }




                    {
                      this.state.activeItem === "support-chats" ?
                        <a className="e-nav-item e-text-underline e-active" href="/support-chats">Chat & Support</a>
                      :
                        <a className="e-nav-item e-text-underline" href="/support-chats">Chat & Support</a>
                    }
                    {
                      this.state.activeItem === "all-users" ?
                        <a className="e-nav-item e-text-underline e-active" href="/all-users">All Users</a>
                      :
                        <a className="e-nav-item e-text-underline" href="/all-users">All Users</a>
                    }
                    {/* {
                      this.state.activeItem === "resources" ?
                        <span className="dropdown">
                          <a className="e-nav-item e-text-underline e-active dropbtn">Resources &#9662;</a>
                          <div className="dropdown-content">
                            <Link className="hi" to="/resources?tab=1" onClick={()=>{window.location.href="/resources?tab=1"}}>
                                <span onClick={()=>{this.setState({activeItem: "resources"})}}>Videos</span>
                            </Link>
                            <Link className="hi" to="/resources?tab=2" onClick={()=>{window.location.href="/resources?tab=2"}}>
                              <span onClick={()=>{this.setState({activeItem: "resources"})}}>Webinars</span>
                            </Link>
                            <Link className="hi" to="/resources?tab=3" onClick={()=>{window.location.href="/resources?tab=3"}}>
                              <span onClick={()=>{this.setState({activeItem: "resources"})}}>Blogs</span>
                            </Link>
                            <Link className="hi" to="/resources?tab=4" onClick={()=>{window.location.href="/resources?tab=4"}}>
                              <span onClick={()=>{this.setState({activeItem: "resources"})}}>Reports</span>
                            </Link>



                          </div>
                        </span>
                      :
                        <span className="dropdown">
                          <a className="e-nav-item e-text-underline  dropbtn">Resources &#9662;</a>
                          <div className="dropdown-content">
                            <Link className="hi" to="/resources?tab=1" onClick={()=>{window.location.href="/resources?tab=1"}}>
                                <span onClick={()=>{this.setState({activeItem: "resources"})}}>Videos</span>
                            </Link>
                            <Link className="hi" to="/resources?tab=2" onClick={()=>{window.location.href="/resources?tab=2"}}>
                              <span onClick={()=>{this.setState({activeItem: "resources"})}}>Webinars</span>
                            </Link>
                            <Link className="hi" to="/resources?tab=3" onClick={()=>{window.location.href="/resources?tab=3"}}>
                              <span onClick={()=>{this.setState({activeItem: "resources"})}}>Blogs</span>
                            </Link>
                            <Link className="hi" to="/resources?tab=4" onClick={()=>{window.location.href="/resources?tab=4"}}>
                              <span onClick={()=>{this.setState({activeItem: "resources"})}}>Reports</span>
                            </Link>

                          </div>
                        </span>
                    } */}


                    <span className="text-right
                    ">
                    <a className="e-nav-user-wrap e-card-transition" onClick={this.dropDownClick.bind(this)}>
                      <span className="e-nav-user"></span>
                      Admin &#9662;
                    </a>
                    </span>


                    {
                      this.state.logoutdDown ?
                        <span className="dropdown" >
                          <div className="e-logout-div">
                          <span className="" onClick={()=>window.location.href="/add-content"}>Add Content</span>
                          <span className="" onClick={()=>window.location.href="/partners"}>Partners</span>
                          <span className="" onClick={this.subAdminClick.bind(this)}>Sub Admins</span>
                          <span className="" onClick={()=>{window.location.href = '/notifications';}}>Notifications</span>
                          <span className="" onClick={this.logoutClick.bind(this)}>logout</span>
                          <span className="" onClick={this.dropDownClick.bind(this)}></span>
                          </div>
                        </span>
                      :
                        null
                    }

                  </div>
              }
            </div>
          </div>
        </header>
      </div>
    );
  }
}

export default Header;
