/*Package importing*/
import React, { Component } from 'react';
import { useHistory  } from 'react-router-dom';

/*Assets and Components*/
import fourstar from '../../assets/images/4-star.svg';

/*Services*/
import devconfig from '../../config/config.js';
import GetURLVar from '../../services/apiCall/getParams/get-url.js'; 

// import components
import APIPost from '../../components/apiCall/apiPost';
import APIGet from '../../components/apiCall/apiGet';
import PrimaryBlueButton from '../../components/buttons/primaryBlueButton';
import PrimaryOutlineButton from '../../components/buttons/primaryOutlineButton';

// import Links from '../../components/navigaitons/Links';
// import ProductDetail from '../../container/detail/main.js';

// import assets
import sensigroup from '../../assets/images/marketplaceDetails/sensigrop.svg';
import Accept from '../../assets/images/accept.svg';
import Reject from '../../assets/images/reject.svg';
import alicebluegrp from '../../assets/images/marketplaceDetails/alicebluegrp.svg';
import optimumgrp from '../../assets/images/marketplaceDetails/optimumgrp.svg';
import smallcasegrp from '../../assets/images/marketplaceDetails/smallcasegrp.svg';

export default class ProductRequestCard extends React.Component{

  constructor(props){
    super(props);
    this.state={

    }
  }
  Approve(e){
    var url = devconfig.adminV2+"/products/process-request";
    var data = JSON.stringify({
     "card_request_id" : parseInt(e),
     "approve" : true
    })
    APIPost(url, data).then(function (response) {
      if(response.status ==="ok"){
        this.props.confirmSuccess(response.message);
      }
      else{
        // this.setState({ dataLoading: false});/
      }
    }.bind(this))
  }

   Delete(e){
    var url = devconfig.adminV2+"/products/process-request";
    var data = JSON.stringify({
     "card_request_id" : parseInt(e),
     "approve" : false
    })
    APIPost(url, data).then(function (response) {
      if(response.status ==="ok"){
        this.props.confirmSuccess(response.message);
      }
      else{
        // this.setState({ dataLoading: false});/
      }
    }.bind(this))
  }

  productRequestDetails(e){
    window.location.href = '/product-details?id='+e;
  }
  render(){
    return (

      <div className="e-marketplace-allcard-sec">
        <div className="card e-marketDetails-card border-0">
          <div className="e-card-head e-abcard-hed p-3" style={{backgroundImage: this.props.bgColor}}>
            <img className="card-logo" src={this.props.data.logo} alt="Card image cap"/>
            <img className="card-illustration" src={this.props.data.illustration} alt="Card image cap"/>
          </div>
          <div className="e-card-rating">
          {/*<span className="e-fill-star"></span>
          <span className="e-half-fill-star"></span>
          <span className="e-no-fill-star"></span>*/}
          {
            this.props.data.review_count ?
              <React.Fragment>
                <span>({this.props.data.review_count} Reviews)</span>
              </React.Fragment>
            :
              null

          }
          
        </div>
          <div className="card-body">
            <h5 className="e-mrktplc-card-title pb-3">{this.props.data.title}</h5>
            <p className="e-mrktplc-card-text">{this.props.data.description}</p>
            <div className="row pl-3">
               {
                this.props.data.filters.map((item, index) => {
                  return(
                    <span className="e-card-type-tag" key={index}>{item.name}</span>
                  );
                })
                }
            </div>
              {
          this.props.data.offer ?
            <p>
              <span className="e-mrktplc-card-title">Offer Price: </span> 
              <span className="e-mrktplc-card-title e-prdt-free-grn">
                {
                  this.props.data.offer_text && this.props.data.offer_text != "Free" && this.props.data.offer_text != "Free Trail" ?
                    <React.Fragment>&#8377; </React.Fragment>
                  :
                    null
                }
                {this.props.data.offer_text ? this.props.data.offer_text : this.props.data.offer_price}
              </span> 
              <span className="e-mrktplc-product-plan">
                {this.props.data.offer_validity_text.length ? " / per "+this.props.data.offer_validity_text : null}
              </span>
            </p>
          :
            null
        }
            <div className="row">
              <div className="col-lg-6">
                <span className="e-p-btn e-product d-inline-block" onClick={()=>{this.productRequestDetails(this.props.data.id)}}>View More Details</span>
              </div>
              <div className="col-lg-6">
              <div className="text-right">
                  <span className="e-req-accept" onClick={()=>{this.Approve(this.props.data.id)}}><img src={Accept} className="pr-5 pt-2" alt="Offer Logo"/></span>
                  <span className="e-req-reject" onClick={()=>{this.Delete(this.props.data.id)}}><img src={Reject} className="pt-2" alt="Offer Logo"/></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



    );
  }
}

