/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Import custom components and assets*/
import SelectBox from '../../components/common/selectBox';
import InputBox from '../../components/common/inputBox';
import Textarea from '../../components/common/textarea';
import APIPost from '../../components/apiCall/apiPost';
import APIGet from '../../components/apiCall/apiGet';
import  ProductDetail from '../../container/detail/main';
import devconfig from '../../config/config.js';



/*Assets*/
// import AddLogo from '../../assets/images/add-logo.svg';
import AddIllustration from '../../assets/images/add-illustartion.svg';
import AddVideo from '../../assets/images/video.svg';
import Close from '../../assets/images/close-btn.svg';
import Img from '../../assets/images/image.svg';
import Delete from '../../assets/images/delete-black.svg';



class AddVideos extends Component {
  constructor(props){
    super(props);
    this.state = {
      products: null,
      selectedProduct: [],
      trimmed: [],
      videoData: [{title: null, youtube_url: null }, {title: null, youtube_url: null },{title: null, youtube_url: null }],
      toast: null,
      toastType: 'success',
    }
  }

  componentDidMount(){
   this.getProduct();
  }

  addVideo=()=>{


     var tempArry = [];
     this.state.videoData.map((item, index) => {
      if(item.title !==null && item.youtube_url !==null){
        tempArry.push(item);
      }
    })
     console.log(tempArry)
    var url = devconfig.adminV3+"/resources/add-video";
    var data = JSON.stringify({
      "product_ids": this.state.selectedProduct,
      "video_data": tempArry
    })
    APIPost(url, data).then(function(response){
      if(response.status === "ok"){
        this.hideLogin();
        this.props.triggerToast(response.message);
        this.setState({
          selectedProduct: [],
          videoData: [{title: null, youtube_url: null }, {title: null, youtube_url: null },{title: null, youtube_url: null }],
        })
      }
      else{
        this.setState({error: response.message});
      }
    }.bind(this))
  }

  getProduct=()=>{
    var url = devconfig.adminV3+"/products/product-names";
    APIGet(url).then(function(response){
      console.log(response);
      if(response.status === "ok"){
         this.setState({products: response.data});
         // this.setState({menuOpen: true});
      }
    }.bind(this))
  }

  handleSelect(tempVal){
    this.setState({ selectedProduct: Array.isArray(tempVal)  ? tempVal.map(x => x.value) : []});
  }


  hideLogin=()=> {
  $('.e-login').removeClass('e-login-fly');
  $('#e-log-overlay').remove();
  }


  render() {
     const options = this.state.products ?


        this.state.products.map((item, index) => {

        return(
         { value: item.id, label: item.name }
        );
      })

    :
      null

    return(

      <div className="container text-left e-login e-add-product-modal">
        <div className="row">
          <div className="col-lg-12 pl-5 pr-5">
            <div className="row mb-3 e-modal-border">
              <div>
                <button className="e-login-closebtn">
                  <img src={Close} alt="close button" className="e-toggle" onClick={this.hideLogin.bind(this)}/>
                </button>
              </div>
              <div className="col-lg-4 pt-5 mt-3">
                <h5 className="">Add Tutorial Videos</h5>
              </div>
              <div className="col-lg-7 pt-5 mt-3 ml-5">
              {
                this.state.selectedProduct[0] &&
                this.state.videoData[0].title && this.state.videoData[0].youtube_url ?
                <span className="e-save e-p-btn float-right" onClick={this.addVideo} data-toggle="modal" data-target="#e-addProduct">Save & Add Tutorials</span>
                :
                <span className="e-save e-p-btn float-right e-btn-inactive" >Save & Add Tutorials</span>
              }


              </div>

            </div>

            {
              this.state.error ?

              <div className="row pt-4">
                <div className="col-lg-6">
                  <div className="e-error mb-3">{this.state.error}</div>
                </div>
              </div>

              :
                null

            }
            <div className="row pt-3">
              <div className="col-lg-6 e-select-product">
                <label>Product</label>
                <SelectBox placeholder="Select the product" options={options}  selectType={true} selectchange={(selectedValue) => this.handleSelect(selectedValue)} />
              </div>
            </div>
            <div className="row pt-3">
              <div className="col-lg-12">
                <label>Video Links</label>
              </div>



                   {
                    this.state.videoData.map((item, index)=>{
                      return(
                        <React.Fragment key={index}>
                        <div className="col-lg-6" >

                         <InputBox placeholder={"Video"+ " " + (index+1) + " " +  "Title"}
                         value={this.state.videoData[index].title}
                      onChange={(e) => {

                          var tempArry = this.state.videoData;
                          tempArry[index].title=e.target.value;
                          this.setState({videoData: tempArry})
                        }} />
                    </div>
                    <div className="col-lg-6 ">

                      <InputBox placeholder="Youtube URL"
                      value={this.state.videoData[index].youtube_url}
                      onChange={(e) => {
                          var tempArry = this.state.videoData;
                          tempArry[index].youtube_url=e.target.value;
                          this.setState({videoData: tempArry})
                        }} />
                    </div>
                    </React.Fragment>
                      )
                    })
                   }



            </div>

            <div className="row pt-3"> <div className="col-lg-12"> <span
              className="e-add-product-link" onClick={(e) => {
               var tempArray = this.state.videoData;
              tempArray.push({title: null, youtube_url: null });
              this.setState({videoData: tempArray})
              }}> <a className="e-link">+ Add another Video</a> </span> </div> </div>
          </div>

      </div>
         </div>

    )
  }
}

export default AddVideos;
